.privacy-policy, .terms-conditions{
  .privacy-policy__background, .terms-conditions__background{
    position: relative;
    margin-top: 102px;
    height: 300px;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;
    }
  }
  .privacy-policy__container, .terms-conditions__container {
    background: white;
    margin-top: -180px;
    margin-bottom: 60px;
    padding: 40px 15px;
    position: relative;
    text-align: center;
    z-index: 2;
    box-shadow: 0px 5px 19px rgba(0, 0, 0, 0.05);
    -webkit-box-shadow: 0px 5px 19px rgba(0, 0, 0, 0.05);
    -moz-box-shadow: 0px 5px 19px rgba(0, 0, 0, 0.05);
    @media only screen and (min-width: 768px) {
      padding: 60px 30px;
    }
    @media only screen and (max-width: 767px) {
      margin: -180px 15px 30px 15px;
    }
    .privacy-policy__content, .terms-conditions__content{
      max-width: 800px;
      margin: 0 auto;
      text-align: center;
      @media only screen and (max-width: 767px) {
        text-align: left;
      }
      .bold{
        font-weight: 600;
      }
    }
  }
}
