@import './var';

.theme-btn {
  background-color: $btn-bg;
  font-family: $font-family;
  padding: 10px 0px;
  font-weight: 500;
  font-size: 18px;
  border-radius: 30px;
  transition: 0.3s;
  color: $white;
  cursor: pointer;
  padding: $spacing-unit ($spacing-unit * 4);
  -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome and Opera */
                                  &:focus {
                                    outline: none;
                                  }
                                  &:hover {
                                    background-color: #da4607;
                                    transition: 0.3s;
                                    box-shadow: 2px 3px 15px rgba(0, 0, 0, 0.19);
                                  }
  .login & {
    margin: 0;
    width: 100%;
    max-width: 350px;
  }
  .news-feed__grid-left & {
      margin-top: 30px;
  }

  .donation__left & {
    margin-top: 8px;
  }

  .donation-wall__right & {
    background-color: $white;
    color: $btn-bg;
    border: 2px solid $btn-bg;
    margin: 0 auto;
    margin-top: 40px;
    display: block;

    &:hover {
      background-color: $btn-bg;
      color: white;
      transition: 0.3s;
    }
  }
  .amount-submit-container & {
    margin-left: 15%;
    @media all and (max-width: 479px)
    {
      margin-left: 10%;
      margin-top: 0;
    }
  }
}

.hollow-btn {
  transition: 0.3s;
  background-color: transparent;
  font-family: $font-family;
  padding: 10px 0px;
  font-weight: 500;
  font-size: 18px;
  border-radius: 30px;
  border: solid 2px $white;
  color: $white;
  cursor: pointer;
  padding: $spacing-unit ($spacing-unit * 4);
  transition: 0.3s;
  -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently

                           supported by Chrome and Opera */

  &:focus {
    outline: none;
  }
  &:hover {
    background-color: $white;
    transition: 0.3s;
  }
  &:hover{
    color: $theme-blue;
    transition: 0.3s;
  }
  .login__box-right-content & {
      padding: 10px 60px;
      color: $white;
        background: transparent;
      margin-top: 30px;

      &:hover {
       
        background: white;
        color: $theme-blue;
      }
  }
  .drop-us-outer & {
    color: $btn-bg;
    font-size:14px;
    border: solid $btn-bg;
    padding: 6px 17px;

    &:hover {
      background-color: $btn-bg;
      color: $white;
    }
  }
}

button{
  background: transparent;
  border: none;
  margin: 20px 0;
  padding: 0;

  .home-banner-content__right-content & {
    margin-bottom: 50px;
    margin-top: 10px;
  }
}
.read-more__btn {
  color: $btn-bg;
  font-family: $font-family;
  font-size: 14px;
  font-weight: 600;
  cursor: pointer;
  &:hover {
    color: #dc4402;
    transition: 0.3s;
  }
}

.btnload{
  display: flex;
  justify-content: center;
}