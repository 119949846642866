
.progress-bar__widget{
  position: relative;
  background: #caf0fb;
  width: 100px;
  height: 70px;
  margin: 0 auto;
  border: 10px solid #46b6db;
  box-shadow: 2px 41px 22px -41px rgba(1, 1, 1, 0.53);

  .mask {
    position: absolute;
    height: 129%;
    top: -10px;
    width: 98%;
    background: $theme-blue;
    box-sizing: border-box;
  }

  .left-mask {
    left: -71px;
    transform: skewX(10deg);
    border-right: 6px solid #46b6db;
  }

  .right-mask {
      right: -71px;
      transform: skewX(-10deg);
      border-left: 6px solid #46b6db;
      box-shadow: inset 86px -71px 44px -104px $black
  }
}


.progress-bar__filter {
  background: #3892b0;
  width: 100%;
  position: absolute;
  bottom: 0;
  transition: height .01s ease-in;
}
