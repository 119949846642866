.drink-donate__top-banner-wrap {
  background-image: url('../images/drinkdonatebanner.jpg');
  padding: 250px 15px 100px 15px;
  text-align: center;
  color: white;
  background-size: 100%;
  background-size: cover;
@media screen and (max-width:480px) and (min-width:320px){
background-image:url('../images/drinkand donatemob.png');
padding:  100px 15px 50px 15px !important;background-size: cover;
}
  .heading-top-line{

    @media all and (max-width:450px){
      width:235px;
      text-align: center;
    }
    @media all and (max-width:400px){
      width:205px;
    }
    @media all and (max-width:541px) and (min-width:451px){
      width:275px;
      text-align: center;
    }

  }
  @media only screen and (max-width: 767px) {
    padding: 155px 15px 40px 15px;
  }
  p{
    @media only screen and (max-width: 768px) {
    display: none;
    }
  }
  a{
    @media only screen and (max-width: 768px) {
    display: block;
    }
  }
}
.drink-donate__news-feed {
  display: grid;
  grid-template-columns: 5fr 0fr 5.6fr;
  grid-template-rows: auto 1fr auto;
  padding:0 0 20px 0;
  @media only screen and (max-width: 990px) {
    display: block;
    padding:0;
  }
  .news-feed__grid-left{
    @media only screen and (max-width: 991px) {
        padding-top: 15px;
    }
  }
  .join-community__right{
    background-color: #FFF;
    .join-community__right-content{
      @media only screen and (max-width: 620px){
        padding: 0 10px;
      }
      .mockIphone{
        img{
          margin-top: 0;
        }
      }
    }
  }
}
.drink-donate__slider{
  padding: 70px 0 0 0;
  @media only screen and (max-width: 991px) {
    padding: 50px 0 0 0;
  }
  p{
    margin-bottom: 35px;
    @media only screen and (max-width: 768px) {
    
      margin-bottom: 20px;
    }
  }
  .drink-slider__wrapper{
    width: 100%;
    padding: 115px 0;
    overflow: hidden;
    @media only screen and (max-width: 991px) {
      padding: 103px 0 35px 0;
      margin: 23px 0;
    }
    @media only screen and (max-width:768px) {
      
      padding:42px 0px 35px 0px ;
    }
    .slick-next, .slick-prev {
      width: 80px;
      height: 80px;
      border-radius: 50%;
      z-index: 1000;
      -webkit-box-shadow:0px 5px 35px rgba(1, 1, 1, 0.09); /* Safari 3-4, iOS 4.0.2 - 4.2, Android 2.3+ */
      -moz-box-shadow:   0px 5px 35px rgba(1, 1, 1, 0.09);  /* Firefox 3.5 - 3.6 */
      box-shadow: 0px 5px 35px rgba(1, 1, 1, 0.09);
      padding: 20px;
      background-size: 45px;
      background-position: center;
      background-repeat: no-repeat;
      background-color: $white;
    }
    .slick-prev {
      background-image: url('../images/long-arrow-pointing-to-left.svg');

      @media only screen and (min-width: 1025px) {
      left: 10%;
      }
      @media only screen and (max-width: 768px) {
        display: none!important;
      }
    }
    .slick-next {
      background-image: url('../images/long-arrow-pointing-to-the-right.svg');
      @media only screen and (min-width: 1025px) {
      right: 10%;
      }
      @media only screen and (max-width: 768px) {
        display: none!important;
      }
    }
    .slick-prev::before,.slick-next::before {
      content: " ";
    }
    .slick-list {
      overflow: inherit;
      &:after {
        right: -2px;
        background: linear-gradient(to right, rgba(255,255,255,0) 0%,rgba(255,255,255,1) 100%);
      }
      .drink-slider {
        opacity: .8;
        transition: all .3s ease;
        .drink-slider__container{
          margin: 0 10px;
      
          position: relative;
          @media only screen and (min-width: 769px) {
              transform: scale(0.8);
          }
          @media only screen and (max-width: 990px) {
            margin: 0 5px;
          }
          @media only screen and (max-width: 768px) {
            margin: 0 7px;
          }
          @media only screen and (max-width: 550px) {
            margin: 0 5px;
            // margin: 0 auto;
          // width:90%;
          }

          .drink-slider__banner-image{
            width: 100%;
            height: 400px;
            object-fit: cover;
            object-position: center bottom;
            -webkit-filter: brightness(50%);
            filter: brightness(30%);
            position: relative;
            z-index: -1;
          }
          .drink-slider__content-image{
            position: absolute;
            top: -40px;
            left: 80px;
            max-width: 100%;
      
            width: 80px;
            height: 80px;
            border-radius: 50%;
            object-fit: cover;
            object-position: center;
            @media only screen and (min-width: 991px) {
              width: 100px;
              height: 100px;
              top: -50px;
            }
          }
          .drink-slider__donate-content{
            position: absolute;
            z-index: 100;
            text-align: left;
            top: 50px;
           max-height: 300px;
           overflow: hidden;
            // transform: translateY(-50%);
            padding: 20px 30px 0;
            @media only screen and (max-width: 468px) {
               padding: 0 15px;
            }
            @media only screen and (min-width: 991px) {
                padding: 0 80px;
            }
            @media only screen and (max-width: 360px) {
              // top: 50%;
            }
          }
        }
      }
      .slick-center{
      .drink-slider{
        opacity: 1;
        .drink-slider__container{
            @media only screen and (min-width: 769px) {
              transform: scale(1.21);
            }
          }
        }
      }
    }
  }


/*single image condition starts here*/

.drink-slider__wrapper1{
  width: 100%;
 // padding: 115px 0;
  overflow: hidden;
  @media only screen and (max-width: 991px) {
    padding: 103px 0 35px 0;
    margin: 23px 0;
  }
   @media only screen and (max-width:768px) {
      
      padding:42px 0px 35px 0px ;
    }
    .drink-slider {
      opacity: .8;
      transition: all .3s ease;
      .drink-slider__container{
        margin: 0 10px;
    
        position: relative;
        @media only screen and (min-width: 769px) {
            transform: scale(0.8);
        }
        @media only screen and (max-width: 990px) {
          margin: 0 5px;
        }
        @media only screen and (max-width: 768px) {
          margin: 0 7px;
        }
        @media only screen and (max-width: 550px) {
          margin: 0 auto;
          width:90%;
        }

        .drink-slider__banner-image{
          width: 100%;
          height: 600px;
          object-fit: cover;
          object-position: center bottom;
          -webkit-filter: brightness(50%);
          filter: brightness(30%);
          position: relative;
          z-index: -1;
        }
        .drink-slider__content-image{
          position: absolute;
          top: -40px;
          left: 80px;
          max-width: 100%;
          width: 80px;
          height: 80px;
          border-radius: 50%;
          object-fit: cover;
          object-position: center;
          @media only screen and (min-width: 991px) {
            width: 100px;
            height: 100px;
            top: -50px;
          }
        }
        .drink-slider__donate-content{
          position: absolute;
          z-index: 100;
          text-align: left;
          top: 50px;
           max-height: 450px;
           overflow: hidden;
         
         // transform: translateY(-50%);
          padding: 20px 30px 0;
          h1{
            font-size: 32px;
            color:white!important;
          }
          p{
            color:white!important;
            font-size: 18px!important;

            p{
              span{

                font-size: 18px!important;
                color:white!important;
              }
              @media only screen and (max-width:478px) {
              margin:0 auto;
                max-width:270px!important;
                font-size: 14px;

                span{
                font-size: 14px;
                }
            }
            }
          }
          @media only screen and (max-width: 468px) {
            top: 35px!important;
             padding: 0 15px;
           
          }
          @media only screen and (min-width: 991px) {
              padding: 0 80px;
          }
          @media only screen and (max-width: 360px) {
            top: 50%;
          }
        }
      }
    }

  }
}

/*single image condition ends here*/



