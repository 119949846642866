@charset "UTF-8";
@import url("https://fonts.googleapis.com/css?family=Roboto:400,400i,500,700");
div.top-nav__menu > ul > li:last-child {
  padding: 0;
  height: 210px; }
  .colorHeader div.top-nav__menu > ul > li:last-child {
    height: auto; }

.colorHeader {
  background: #08abe6; }

.borderlogin1 {
  border: 2px solid white !important;
  padding: 5px 20px !important;
  min-width: 120px;
  text-align: center;
  border-radius: 25px; }
  .borderlogin1:hover {
    background: white; }
  @media screen and (max-width: 825px) {
    .borderlogin1 {
      padding: auto 20px !important;
      border: none !important; } }

.top-nav__login-btn {
  border: 2px solid white !important;
  padding: 5px 30px !important;
  min-width: 120px;
  border-radius: 25px; }
  .top-nav__login-btn:hover::after, .top-nav__login-btn:focus::after {
    content: none; }
  .top-nav__login-btn .active {
    background: white; }
    .top-nav__login-btn .active::after {
      content: none; }
  .top-nav__login-btn:hover {
    background: white;
    color: #08abe6 !important; }

@media only screen and (max-width: 825px) {
  .top-nav__menu-list .select-box--box .select-box--options {
    left: -30px !important;
    right: unset !important;
    text-align: left; } }

.top-nav {
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  padding: 10px 0;
  box-sizing: border-box; }

.top-nav__logo {
  display: inline-block;
  float: left;
  z-index: 1001;
  position: relative; }

.top-nav__logo-text {
  color: #000;
  font-size: 32px;
  margin: 23px 0; }
  .top-nav__logo-text span {
    color: #fff; }

.top-nav__menu {
  display: inline-block;
  float: right;
  position: relative;
  z-index: 30; }
  @media all and (min-width: 768px) {
    .colorHeader .top-nav__menu {
      position: absolute;
      right: 0; } }

.top-nav__menu-list {
  margin: 20px 0;
  margin-right: 0; }
  @media only screen and (max-width: 825px) {
    .top-nav__menu-list {
      margin: 0; } }
  .top-nav__menu-list li {
    display: inline-block;
    float: left;
    padding: 8px 0;
    margin-bottom: 0;
    margin: 0 20px;
    font-family: "Roboto", sans-serif;
    font-weight: 300;
    color: #fff;
    letter-spacing: 0;
    box-sizing: border-box; }
    .top-nav__menu-list li.borderlogin .top-nav__login-btn::before, .top-nav__menu-list li.borderlogin .top-nav__login-btn::after, .top-nav__menu-list li.borderlogin .top-nav__login-btn.active::after, .top-nav__menu-list li.borderlogin .top-nav__login-btn.active:hover::after {
      content: none !important; }
    .top-nav__menu-list li.borderlogin .top-nav__login-btn.active {
      background: white;
      color: #08abe6; }
    .top-nav__menu-list li .active {
      color: #fff; }
      .top-nav__menu-list li .active:after {
        position: absolute;
        width: 100%;
        height: 2px;
        background: #fff;
        content: "";
        transform: none;
        opacity: 1;
        bottom: 2px; }
      .top-nav__menu-list li .active:hover:before, .top-nav__menu-list li .active:hover:after, .top-nav__menu-list li .active:focus:before, .top-nav__menu-list li .active:focus:after {
        position: absolute;
        width: 100%;
        height: 2px;
        background: #fff;
        content: "";
        transform: none;
        opacity: 1;
        bottom: 2px; }
      .top-nav__menu-list li .active:hover:before, .top-nav__menu-list li .active:focus:before {
        display: none; }
      .top-nav__menu-list li .active:hover:after, .top-nav__menu-list li .active:focus:after {
        position: absolute;
        width: 100%;
        height: 2px;
        background: white;
        content: "";
        transform: none;
        opacity: 1;
        bottom: 2px; }
      .top-nav__menu-list li .active:hover:nth-child(5)::after, .top-nav__menu-list li .active:focus:nth-child(5)::after {
        height: 0px; }
    @media only screen and (max-width: 991px) {
      .top-nav__menu-list li {
        padding: 8px 0;
        margin: 0 10px; } }
    .top-nav__menu-list li a {
      font-family: "Roboto", sans-serif;
      font-weight: 300;
      color: #fff;
      font-size: 17px;
      position: relative;
      letter-spacing: 0;
      padding: 0px 4px 8px;
      box-sizing: border-box; }
      .top-nav__menu-list li a:before, .top-nav__menu-list li a:after {
        content: '';
        position: absolute;
        bottom: 2px;
        left: 0;
        right: 0;
        height: 2px;
        background-color: #fff; }
      .top-nav__menu-list li a:before {
        opacity: 0;
        transform: translateY(-8px);
        transition: transform 0s cubic-bezier(0.175, 0.885, 0.32, 1.275), opacity 0s; }
      .top-nav__menu-list li a:after {
        opacity: 0;
        transform: translateY(4px);
        transition: transform 0.2s cubic-bezier(0.175, 0.885, 0.32, 1.275), opacity 0.2s; }
      .top-nav__menu-list li a:hover:before, .top-nav__menu-list li a:hover:after, .top-nav__menu-list li a:focus:before, .top-nav__menu-list li a:focus:after {
        opacity: 1;
        transform: translateY(0); }
      .top-nav__menu-list li a:hover:before, .top-nav__menu-list li a:focus:before {
        transition: transform 0.2s cubic-bezier(0.175, 0.885, 0.32, 1.275), opacity 0.2s; }
      .top-nav__menu-list li a:hover:after, .top-nav__menu-list li a:focus:after {
        transition: transform 0s 0.2s cubic-bezier(0.175, 0.885, 0.32, 1.275), opacity 0s 0.2s; }
    .top-nav__menu-list li.top-nav__login-btn {
      border: solid 2px #fff !important;
      border-radius: 20px;
      padding: 8px 40px !important;
      transition: 0.3s;
      margin-right: 0px; }
      @media only screen and (max-width: 825px) {
        .top-nav__menu-list li.top-nav__login-btn {
          text-align: center;
          display: block;
          margin: 20px auto !important;
          font-weight: 600 !important; } }
      .top-nav__menu-list li.top-nav__login-btn a {
        font-size: 15px !important;
        transition: 0.3s; }
      .top-nav__menu-list li.top-nav__login-btn:hover {
        background: #fff;
        transition: 0.3s;
        color: #08abe6;
        transition: 0.3s; }

@media screen and (max-width: 825px) {
  .top-nav__login-btn {
    font-size: 20px;
    border-radius: 60px;
    display: flex;
    justify-content: center;
    max-width: 200px;
    margin: 0 auto !important;
    border: solid 2px #fff; }
    .top-nav__login-btn p {
      text-align: center; }
    .top-nav__login-btn:hover {
      border: none; }
  .nav-open {
    position: absolute;
    right: 20px;
    top: 25px;
    display: block;
    width: 48px;
    height: 48px;
    cursor: pointer;
    z-index: 9999;
    border-radius: 50%; }
  .nav-open i {
    display: block;
    width: 32px;
    height: 3px;
    background: #fff;
    border-radius: 2px;
    margin-left: 14px; }
  .nav-open i:nth-child(1) {
    margin-top: 16px; }
  .nav-open i:nth-child(2) {
    margin-top: 4px;
    opacity: 1; }
  .nav-open i:nth-child(3) {
    margin-top: 4px; }
  .top-nav__menu ul li:nth-child(1) a {
    transition-delay: 0.2s; }
  .top-nav__menu ul li:nth-child(2) a {
    transition-delay: 0.3s; }
  .top-nav__menu ul li:nth-child(3) a {
    transition-delay: 0.4s; }
  .top-nav__menu ul li:nth-child(4) a {
    transition-delay: 0.5s; }
  .top-nav__menu ul a:nth-child(5) {
    transition-delay: 0.6s; }
  /*syntax cleared till here*/
  .top-nav__menu {
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: -1;
    background: #08abe6;
    opacity: 0;
    transition: all 0.2s ease; }
    .top-nav__menu ul {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      width: 100%;
      overflow: hidden; }
      .top-nav__menu ul li {
        display: block;
        width: 100%;
        text-align: center;
        margin-bottom: 10px; }
        .top-nav__menu ul li a {
          padding: 10px 15px;
          opacity: 0;
          color: #fff;
          font-size: 24px;
          font-weight: 600;
          transform: translateY(-20px);
          transition: all 0.2s ease; } }

/* syntax okay in down */
#nav:checked + .nav-open {
  transform: rotate(45deg);
  position: fixed;
  z-index: 100000; }

#nav:checked + .nav-open i {
  background: #fff;
  transition: transform 0.2s ease; }

#nav:checked + .nav-open i:nth-child(1) {
  transform: translateY(6px) rotate(180deg); }

#nav:checked + .nav-open i:nth-child(2) {
  opacity: 0; }

#nav:checked + .nav-open i:nth-child(3) {
  transform: translateY(-8px) rotate(90deg); }

#nav:checked ~ .top-nav__menu {
  z-index: 10000;
  opacity: 1; }

#nav:checked ~ .top-nav__menu ul li a {
  opacity: 1;
  transform: translateY(0); }

.hidden {
  display: none; }

.subscribe__form-wrapper {
  display: grid;
  grid-template-columns: 7fr 2fr;
  grid-gap: 10px; }
  .subscribe__form-wrapper input:focus, .subscribe__form-wrapper button:focus {
    outline: none; }
  .subscribe__form-wrapper button {
    background: #c4c4c4;
    color: white;
    margin: 0;
    height: 100%;
    font-family: "Roboto", sans-serif;
    font-weight: 500;
    font-size: 15px;
    border-radius: 5px;
    min-width: 100px;
    cursor: pointer; }

.footer-outer {
  background-color: #eeeeef; }
  .footer-outer .footer-content-outer {
    display: grid;
    grid-template-columns: 5fr 1fr;
    font-family: "Roboto", sans-serif;
    padding-top: 30px;
    padding-bottom: 30px; }
    @media all and (max-width: 767px) {
      .footer-outer .footer-content-outer {
        padding-top: 30px; } }
    .footer-outer .footer-content-outer .footer-links-outer {
      padding-bottom: 20px;
      background-image: url("../images/footerlogo.png");
      background-repeat: no-repeat;
      background-position: 87% 1%; }
      @media all and (max-width: 991px) {
        .footer-outer .footer-content-outer .footer-links-outer {
          background-size: 60%; } }
      @media all and (max-width: 800px) {
        .footer-outer .footer-content-outer .footer-links-outer {
          background-size: 50%; } }
      @media all and (max-width: 767px) {
        .footer-outer .footer-content-outer .footer-links-outer {
          background-position: 97% 10%; } }
      @media all and (max-width: 479px) {
        .footer-outer .footer-content-outer .footer-links-outer {
          font-size: 12px; } }
      .footer-outer .footer-content-outer .footer-links-outer a {
        color: #08abe6; }
    .footer-outer .footer-content-outer .subscribe-outer h2 {
      font-size: 16px;
      letter-spacing: 3px;
      color: #08abe6; }
    .footer-outer .footer-content-outer .subscribe-outer p {
      color: #989898;
      font-size: 14px;
      margin-bottom: 15px; }
    .footer-outer .footer-content-outer .subscribe-outer input {
      border: none;
      padding: 10px 20px;
      background: #c4c4c4;
      border-radius: 5px;
      width: 300px;
      height: 30px;
      font-size: 16px;
      font-weight: 500;
      background-image: url("../images/envelope.png");
      background-repeat: no-repeat;
      background-position: 95%;
      background-size: 22px; }
      .footer-outer .footer-content-outer .subscribe-outer input::placeholder {
        color: white; }
      @media all and (max-width: 767px) {
        .footer-outer .footer-content-outer .subscribe-outer input {
          max-width: 100%;
          width: 100%;
          box-sizing: border-box;
          height: 50px; } }
    .footer-outer .footer-content-outer .footer-bottom-links {
      display: grid;
      grid-template-columns: .9fr 1.2fr 5fr;
      margin-top: 10px;
      grid-gap: 1%; }
      .footer-outer .footer-content-outer .footer-bottom-links a {
        font-size: 14px;
        color: #989898; }
        @media all and (max-width: 479px) {
          .footer-outer .footer-content-outer .footer-bottom-links a {
            margin-right: 15px; } }
      @media all and (max-width: 767px) {
        .footer-outer .footer-content-outer .footer-bottom-links {
          display: block;
          float: left; } }
      @media all and (max-width: 479px) {
        .footer-outer .footer-content-outer .footer-bottom-links {
          display: inline-block; } }
      @media all and (max-width: 479px) {
        .footer-outer .footer-content-outer .footer-bottom-links div {
          display: inline-block; } }
      @media all and (max-width: 479px) {
        .footer-outer .footer-content-outer .footer-bottom-links div:first-child {
          margin: 15px 10px 15px 0px; } }
    .footer-outer .footer-content-outer .all-rights-reserved {
      color: #989898;
      text-align: right;
      font-size: 12px; }
    .footer-outer .footer-content-outer .social-icons, .footer-outer .footer-content-outer .payment-icons {
      display: grid;
      padding-top: 20px;
      grid-column-gap: 10px; }
    .footer-outer .footer-content-outer .social-icons {
      width: 100px; }
      @media all and (max-width: 767px) {
        .footer-outer .footer-content-outer .social-icons {
          clear: both;
          float: left; } }
      @media all and (max-width: 479px) {
        .footer-outer .footer-content-outer .social-icons {
          display: inline-block;
          width: auto; } }
      @media all and (max-width: 479px) {
        .footer-outer .footer-content-outer .social-icons .social-share {
          grid-gap: 12px; } }
      @media all and (max-width: 479px) {
        .footer-outer .footer-content-outer .social-icons div {
          float: left; } }
    .footer-outer .footer-content-outer .payment-icons {
      grid-template-columns: 1fr;
      text-align: right; }
      @media all and (max-width: 767px) {
        .footer-outer .footer-content-outer .payment-icons {
          grid-template-columns: 1fr;
          float: right; } }
      @media all and (max-width: 479px) {
        .footer-outer .footer-content-outer .payment-icons {
          right: 10px;
          position: absolute;
          bottom: 42px; } }
      .footer-outer .footer-content-outer .payment-icons img {
        max-width: 60px;
        margin-top: 8px; }

li {
  margin-bottom: 10px;
  font-weight: 500;
  letter-spacing: 2px;
  color: #01799C; }

.login__box-forget-password {
  color: #a2a2a2;
  font-size: 15px;
  font-family: "Roboto", sans-serif; }

label {
  font-family: "Roboto", sans-serif;
  text-align: left;
  color: #a2a2a2;
  font-size: 16px;
  font-weight: 300; }

h1 {
  font-family: "Roboto", sans-serif;
  color: #fff;
  font-size: 30px; }
  .functionality-heading h1 {
    margin: 0;
    color: #fff;
    font-size: 30px;
    line-height: 36px;
    font-weight: 500; }
  .page-404 h1 {
    font-size: 45px;
    font-weight: 600;
    color: #000;
    margin-bottom: 12px; }
  .drink-slider__donate-content h1 {
    color: white;
    text-transform: lowercase; }
    @media only screen and (min-width: 991px) {
      .drink-slider__donate-content h1 {
        font-size: 30px; } }
    @media only screen and (max-width: 767px) {
      .drink-slider__donate-content h1 {
        font-size: 26px; } }
  .login__box-right-content h1 {
    color: #fff;
    font-weight: 100;
    font-size: 36px;
    margin: 0;
    margin-bottom: 15px; }
    @media only screen and (max-width: 450px) {
      .login__box-right-content h1 {
        font-size: 31px; } }
  .thank-you__content h1 {
    color: #fff;
    margin: 0;
    font-size: 36px;
    font-weight: 600; }
    .thank-you__content h1 span {
      font-weight: 300; }
    @media only screen and (max-width: 767px) {
      .thank-you__content h1 {
        font-size: 32px; } }
  .payment h1 span {
    color: #08abe6; }

h2 {
  font-family: "Roboto", sans-serif;
  color: #fff;
  font-size: 27px; }
  .file-download-top__details h2 {
    font-size: 17px;
    font-weight: 300; }
    .file-download-top__details h2 span {
      font-weight: 600; }
  .file-download-top__right h2 {
    font-weight: 300;
    margin-bottom: 0; }
    .file-download-top__right h2 span {
      font-weight: 600; }
  .functionality-heading h2 {
    margin: 0px 0 30px;
    font-size: 21px;
    line-height: 30px;
    font-weight: 300; }
  .inner-page__all-functions h2 {
    color: #000;
    text-align: center;
    font-weight: 300;
    margin: 0;
    padding-bottom: 2px; }
  .page-404 h2 {
    color: #000;
    font-size: 21px;
    margin: 0;
    font-weight: 300; }
  .login__box-right-content h2 {
    font-size: 25px;
    font-weight: 300;
    margin: 0; }
    @media only screen and (max-width: 450px) {
      .login__box-right-content h2 {
        font-size: 20px; } }

h3 {
  font-family: "Roboto", sans-serif;
  color: #000;
  font-size: 27px; }
  .news-search__result-block h3 {
    font-weight: 300; }
    .news-search__result-block h3 span {
      font-weight: 600; }
  .payment__Left h3 {
    margin-top: 0; }
  .faq-block__right h3, .faq-content h3 {
    font-weight: 500;
    -webkit-margin-before: 0;
    -webkit-margin-after: 0;
    width: 90%; }
    @media only screen and (max-width: 468px) {
      .faq-block__right h3, .faq-content h3 {
        font-size: 24px; } }
  .faq-block__right h3 {
    color: #868585; }
  .faq-content h3 {
    color: #08abe6; }
  .RRT__tab--selected h3 {
    color: #000;
    font-weight: 500; }
  .top-stories h3 {
    font-size: 18px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    max-height: 45px;
    margin: 0;
    font-weight: normal; }
  .editors-picks_content h3 {
    color: #fff;
    margin: 0;
    font-size: 20px;
    font-weight: 500;
    max-width: 400px; }
  .news__listing h3 {
    font-size: 18px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    max-height: 45px;
    margin: 0;
    font-weight: normal; }
  .all-functions__category-col h3 {
    color: #08abe6;
    font-size: 17px;
    font-weight: 500;
    margin: 0;
    margin-bottom: 25px; }
  .drink-donate__top-banner-wrap h3 {
    color: white; }
    .drink-donate__top-banner-wrap h3 .heading-topline-bold {
      color: white;
      text-shadow: 1px 0px #ffffff;
      font-weight: 900; }
    .drink-donate__top-banner-wrap h3::after {
      content: none; }
  .payment h3 span {
    color: #08abe6; }
  .payment h3.payment__Left {
    padding-top: 40px; }
  .questions_container h3 {
    margin-bottom: 10px; }
  .donation-search h3 {
    font-weight: 300;
    margin: 0;
    margin-bottom: 45px; }
    .donation-search h3 span {
      color: #08abe6;
      font-weight: 600; }
  .all-rights-reserved h3 {
    color: #989898;
    text-align: right;
    font-size: 12px; }
    @media all and (max-width: 479px) {
      .all-rights-reserved h3 {
        position: absolute;
        bottom: 10px;
        margin: 0 auto; } }
  .login__box h3 {
    padding-top: 4px;
    font-weight: normal;
    display: inline-block;
    position: relative;
    margin: 0;
    margin-bottom: 35px; }
    .login__box h3::after {
      content: '';
      width: 100%;
      height: 5px;
      background: #08abe6;
      position: absolute;
      top: -2px;
      left: 0;
      border-radius: 10px; }

.heading-top-line {
  font-family: "Roboto", sans-serif;
  position: relative;
  display: inline-block;
  margin: 0;
  color: #000;
  font-size: 28px !important;
  font-weight: normal;
  padding: 18px;
  padding-left: 0;
  text-align: left;
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  -khtml-user-select: none;
  /* Konqueror HTML */
  -moz-user-select: none;
  /* Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently
                                  supported by Chrome and Opera */ }
  .heading-top-line::after {
    content: '';
    width: 60%;
    height: 8px;
    background: #08abe6;
    position: absolute;
    top: 0px;
    border-radius: 10px;
    left: 0; }
    @media only screen and (max-width: 450px) {
      .heading-top-line::after {
        height: 6px;
        top: 5px; } }
    .home-banner-content__right-content .heading-top-line::after {
      left: 15%; }
  @media only screen and (max-width: 450px) {
    .heading-top-line {
      font-size: 23px !important; } }
  .news_category-landing .heading-top-line {
    color: white;
    font-weight: 600; }
    .news_category-landing .heading-top-line::after {
      width: 88%;
      background: #fff; }
  .news-category-Search-page .heading-top-line {
    color: white;
    font-weight: 600; }
    .news-category-Search-page .heading-top-line::after {
      width: 88%;
      background: #fff; }
  .news__banner-content .heading-top-line {
    color: #fff; }
    .news__banner-content .heading-top-line span {
      color: #fff; }
    .news__banner-content .heading-top-line::after {
      width: 30%;
      background: #fff; }
  .news-feed__grid-left .heading-top-line {
    padding-left: 0;
    font-size: 28px; }
    .news-feed__grid-left .heading-top-line::after {
      left: 0; }
  .drink-donate__top-banner-wrap .heading-top-line {
    padding-left: 18px; }
  .privacy-policy__container .heading-top-line::after {
    width: 92%; }
  .terms-conditions__container .heading-top-line::after {
    left: 7%;
    width: 80%; }
  .homePage-bottomGrid__grid-5 .heading-top-line {
    color: #fff;
    display: block;
    padding-left: 0;
    font-size: 27px; }
    .homePage-bottomGrid__grid-5 .heading-top-line .heading-topline-span {
      color: #fff; }
      @media screen and (max-width: 360px) {
        .homePage-bottomGrid__grid-5 .heading-top-line .heading-topline-span {
          display: flex; } }
    .homePage-bottomGrid__grid-5 .heading-top-line::after {
      width: 32%;
      left: 0;
      background: #fff; }
  .homePage-bottomGrid__grid-1 .heading-top-line {
    text-align: left;
    padding-left: 0;
    font-size: 30px; }
    .homePage-bottomGrid__grid-1 .heading-top-line::after {
      left: 0;
      width: 36%; }
  .counter-data .heading-top-line {
    color: #004163;
    font-size: 30px;
    text-align: left;
    padding-left: 0;
    margin-bottom: 30px; }
    .counter-data .heading-top-line::after {
      width: 55%;
      left: 0;
      background: #fff; }
    .counter-data .heading-top-line .heading-topline-span {
      color: #004163;
      text-transform: uppercase; }
  .donation__left .heading-top-line {
    padding-left: 0;
    text-align: left;
    line-height: 30px; }
    .donation__left .heading-top-line::after {
      width: 45%;
      left: 0; }
  .join-community__left-content .heading-top-line {
    text-align: left;
    color: #fff;
    padding: 18px 0;
    display: block; }
    .join-community__left-content .heading-top-line::after {
      width: 40%;
      left: 0;
      background: #fff; }
    .join-community__left-content .heading-top-line .heading-topline-span {
      color: #fff; }
  .home-banner-content__right-content .heading-top-line {
    padding-left: 0;
    color: #fff; }
    .home-banner-content__right-content .heading-top-line::after {
      width: 75%drink-slider__donate-content;
      left: 0;
      background: #fff; }
  @media only screen and (max-width: 991px) {
    .home-banner-content__left-content .heading-top-line .heading-topline-span {
      color: #fff; } }
  .home-banner-content__left-content .heading-top-line::after {
    right: 20px; }
  .heading-top-line .heading-topline-span {
    color: #08abe6;
    font-weight: 600; }
    .home-banner-content__right-content .heading-top-line .heading-topline-span {
      color: #fff; }
  @media all and (max-width: 767px) {
    .seamless-exp-outer .heading-top-line {
      text-align: center;
      padding-right: 0; } }
  .seamless-exp-outer .heading-top-line .heading-topline-span {
    color: #fff; }
  .seamless-exp-outer .heading-top-line::after {
    background: #ffffff; }
    @media all and (max-width: 767px) {
      .seamless-exp-outer .heading-top-line::after {
        left: 22%; } }

h6 {
  font-family: "Roboto", sans-serif;
  margin: 0;
  color: #000;
  font-size: 14px;
  font-weight: normal; }
  @media all and (max-width: 620px) {
    .home-banner-content__left-grid h6 {
      font-size: 17px; } }
  .news-detail__category-wrapper h6 {
    color: #000;
    font-size: 16px;
    background: #efefef;
    text-align: center;
    padding: 6px 5px 7px;
    border-radius: 3px; }
  .news-category-single__wrapper h6 {
    font-size: 30px !important;
    max-width: 80%;
    margin: 0 auto;
    color: #fff; }
  .news-single__content h6 {
    font-size: 18px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    max-height: 45px; }

.accordion-item button {
  color: #000;
  font-family: "Roboto", sans-serif;
  font-size: 18px;
  font-weight: lighter; }
  @media only screen and (max-width: 468px) {
    .accordion-item button {
      font-size: 14px; } }

p {
  color: #fff;
  font-family: "Roboto", sans-serif;
  margin: 0;
  font-size: 16px;
  font-weight: normal; }
  .news-detail__wrapper p {
    color: #000;
    font-size: 20px;
    margin-bottom: 35px; }
  .faq-block__right p, .faq-content p {
    color: #868585; }
  .faq-block__right p {
    width: 90%; }
  .faq-content p {
    font-size: 22px;
    padding: 5px 0; }
    @media only screen and (max-width: 468px) {
      .faq-content p {
        font-size: 16px; } }
  .questions_container p {
    font-size: 14px;
    width: 90%; }
    @media only screen and (max-width: 468px) {
      .questions_container p {
        font-size: 12px; } }
  .accordion-item p {
    font-size: 16px;
    width: 90%; }
    @media only screen and (max-width: 468px) {
      .accordion-item p {
        font-size: 14px; } }
  .RRT__tab--selected p {
    color: #000;
    font-weight: 400;
    width: 90%; }
    @media only screen and (max-width: 468px) {
      .RRT__tab--selected p {
        font-size: 14px; } }
  .text-content-outer p {
    margin: 20px 0; }
  .drink-donate__top-banner-wrap p {
    padding-bottom: 5px; }
  .drink-donate__slider p {
    color: #000;
    line-height: 20px;
    font-size: 14px; }
    @media only screen and (min-width: 1025px) {
      .drink-donate__slider p {
        font-size: 16px; } }
  .new-stories p {
    margin: 10px 0;
    font-size: 13px;
    color: #aaa;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    max-height: 47px; }
  .new-stories-featured__single p {
    margin: 10px 0;
    font-size: 13px;
    color: #aaa;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 9;
    -webkit-box-orient: vertical;
    max-height: 135px; }
  .inner-page__all-functions p {
    font-size: 20px;
    color: #a5a3a3;
    font-weight: 300;
    text-align: center; }
  .drink-slider__donate-content p {
    color: white;
    text-align: left; }
  .progress-bar p {
    font-weight: 600;
    margin-top: 20px;
    text-align: center; }
  .donation-search__result-block--single p {
    font-size: 17px;
    padding: 25px 15px;
    line-height: 22px;
    color: #000; }
  .thank-you__content p {
    margin: 20px 0 5px;
    font-size: 25px; }
    @media only screen and (max-width: 450px) {
      .thank-you__content p {
        font-size: 18px; } }
  .thank-you__share p {
    font-size: 25px;
    color: #000; }
    @media only screen and (max-width: 450px) {
      .thank-you__share p {
        font-size: 18px; } }
    .thank-you__share p span {
      color: #08abe6;
      font-weight: 600; }
  .donation__right-img-details p {
    text-align: center;
    position: absolute;
    bottom: 20px;
    font-size: 16px !important;
    width: 100%;
    color: #fff !important;
    margin: 0 !important;
    -webkit-touch-callout: none;
    /* iOS Safari */
    -webkit-user-select: none;
    /* Safari */
    -khtml-user-select: none;
    /* Konqueror HTML */
    -moz-user-select: none;
    /* Firefox */
    -ms-user-select: none;
    /* Internet Explorer/Edge */
    user-select: none;
    /* Non-prefixed version, currently
                                    supported by Chrome and Opera */ }
    .donation__right-img-details p span {
      font-weight: 600; }
  .payment p {
    color: #444;
    font-size: 18px; }
  .reciept-msg p {
    font-size: 12px; }
  .news-feed__grid-right p {
    margin: 10px 0;
    font-size: 13px;
    color: #aaa;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    max-height: 47px; }
  .privacy-policy__container p, .terms-conditions__container p {
    color: #000;
    padding: 10px 0; }
    @media only screen and (max-width: 767px) {
      .privacy-policy__container p, .terms-conditions__container p {
        font-size: 14px; } }
  .news-feed__grid-left p {
    color: #000;
    margin-top: 10px;
    margin-bottom: 20px;
    padding-right: 30px; }
  .news-feed__grid-dropdown p {
    display: inline-block;
    float: left;
    color: #000;
    padding: 12px 0;
    font-size: 17px;
    margin: 0;
    margin-right: 15px; }
  .homePage-bottomGrid__grid-4 p {
    color: #000;
    padding-left: 60px;
    padding-top: 90px;
    padding-right: 80px;
    font-size: 18px;
    line-height: 25px; }
    @media only screen and (max-width: 1200px) {
      .homePage-bottomGrid__grid-4 p {
        padding-top: 50px;
        padding-left: 40px; } }
  .counter-data__single-col p {
    color: #004163;
    font-size: 18px;
    max-width: 140.5px;
    font-weight: 500; }
  .donation__left p {
    margin-top: 4px;
    margin-left: 13px;
    color: #afafaf;
    font-size: 14px; }
  .home-banner-content__right-content p {
    margin-bottom: 20px; }
  .join-community__right-content p {
    color: #000; }

.sub-heading {
  color: #000;
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  font-weight: normal; }
  .sub-heading a {
    color: #08abe6;
    font-weight: 600; }
  .home-banner-content__left-content .sub-heading {
    margin: 20px 0 0; }
    .home-banner-content__left-content .sub-heading span {
      text-decoration: underline;
      padding: 0 2px; }
  .download_chrome .sub-heading {
    margin: 3px 10px;
    font-size: 15px; }

h4 {
  margin: 0;
  color: #000;
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  font-weight: normal; }
  .donation__left h4 {
    padding: 3px 0;
    padding-left: 10px;
    max-width: 270px;
    border-left: solid 3px black; }

h5 {
  font-family: "Roboto", sans-serif;
  color: #fff;
  font-size: 30px;
  margin: 0px 0 12px; }

.heading-bold {
  font-weight: 300;
  font-size: 35px; }
  .BottomBanner-outer .heading-bold {
    color: #fff; }

.select-box--box {
  min-width: 280px;
  display: inline-block;
  background: white;
  border-radius: 30px;
  border: solid 1px;
  position: relative;
  box-sizing: border-box;
  cursor: pointer;
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  -khtml-user-select: none;
  /* Konqueror HTML */
  -moz-user-select: none;
  /* Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently
                                  supported by Chrome and Opera */ }
  .top-nav__menu-list .select-box--box {
    background: transparent;
    min-width: auto;
    border: none; }
  .payment .select-box--box {
    border-radius: 3px;
    color: #afa9a9; }
    @media all and (max-width: 1060px) {
      .payment .select-box--box.your-info-container {
        min-width: auto; } }
    @media all and (max-width: 479px) {
      .payment .select-box--box {
        min-width: auto;
        width: 100%; } }
  .State-zip-code .select-box--box .select-box--container {
    display: flex; }
  .label-selectbox-pair .select-box--box {
    width: 200px; }
    @media all and (max-width: 479px) {
      .label-selectbox-pair .select-box--box {
        width: 100%;
        min-width: auto; } }
  .State-zip-code .select-box--box {
    height: 40px;
    min-width: auto;
    margin-left: 4%; }
    @media all and (max-width: 479px) {
      .State-zip-code .select-box--box {
        width: 100%;
        margin-left: 0; } }
  .select-box--box .select-box--options div {
    font-family: "Roboto", sans-serif;
    color: #3d3d3d;
    font-weight: 300;
    font-size: 15px;
    padding: 10px 5px;
    padding-left: 30px; }
  .select-box--box .select-box--options--lang div {
    font-family: "Roboto", sans-serif;
    color: #3d3d3d;
    font-weight: 300;
    font-size: 15px;
    padding: 10px 5px;
    padding-left: 30px; }
  .select-box--box .select-box--options--lang {
    position: absolute;
    background: white;
    top: 50px;
    -webkit-box-shadow: 0px 5px 20px 3px rgba(0, 0, 0, 0.05);
    -moz-box-shadow: 0px 5px 20px 3px rgba(0, 0, 0, 0.05);
    box-shadow: 0px 5px 20px 3px rgba(0, 0, 0, 0.05);
    left: 0;
    width: 100%;
    border-radius: 3px;
    padding: 10px;
    box-sizing: border-box;
    z-index: 10; }
    .top-nav__menu-list .select-box--box .select-box--options--lang {
      left: unset;
      right: 0;
      min-width: 170px; }
    .select-box--box .select-box--options--lang div.selected {
      background-size: 20px;
      background-position: 0px 8px;
      background-repeat: no-repeat;
      font-weight: 600; }
    .select-box--box .select-box--options--lang div:first-of-type.selected {
      background-image: url("../images/uk.png"); }
    .select-box--box .select-box--options--lang div:nth-child(2).selected {
      background-image: url("../images/france.png"); }
    .select-box--box .select-box--options--lang div:nth-child(3).selected {
      background-image: url("../images/german.png"); }
  .select-box--box .select-box--options {
    position: absolute;
    background: white;
    top: 50px;
    -webkit-box-shadow: 0px 5px 20px 3px rgba(0, 0, 0, 0.05);
    -moz-box-shadow: 0px 5px 20px 3px rgba(0, 0, 0, 0.05);
    box-shadow: 0px 5px 20px 3px rgba(0, 0, 0, 0.05);
    left: 0;
    width: 100%;
    border-radius: 3px;
    padding: 10px;
    box-sizing: border-box;
    z-index: 10; }
    .top-nav__menu-list .select-box--box .select-box--options {
      left: unset;
      right: 0;
      min-width: 170px; }
    .select-box--box .select-box--options div.selected {
      background-size: 20px;
      background-position: 0px 8px;
      background-repeat: no-repeat;
      font-weight: 600; }
  .select-box--box .select-box--selected-item {
    display: inline-block;
    float: left;
    padding: 12px 15px 12px 18px;
    font-family: "Roboto", sans-serif;
    color: #08abe6;
    font-weight: 600;
    font-size: 16px; }
    .top-nav__menu-list .select-box--box .select-box--selected-item {
      color: #fff; }
    .payment .select-box--box .select-box--selected-item {
      color: #828282;
      font-weight: 100; }
  .select-box--box .select-box--arrow {
    width: 30px;
    height: 30px;
    margin: 0;
    padding: 0;
    display: inline-block;
    background: transparent;
    position: relative;
    float: right; }
  .select-box--box .select-box--arrow-down {
    position: absolute;
    top: 18px;
    left: 0px;
    width: 0;
    height: 0;
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    border-top: 9px solid #aeaeae; }
    .top-nav__menu-list .select-box--box .select-box--arrow-down {
      border-top: 9px solid #fff; }
  .select-box--box .select-box--arrow-up {
    position: absolute;
    top: 16px;
    left: 0px;
    width: 0;
    height: 0;
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    border-bottom: 9px solid #aeaeae; }
    .top-nav__menu-list .select-box--box .select-box--arrow-up {
      border-bottom: 9px solid #fff; }

@media all and (max-width: 479px) {
  .input-text-outer {
    margin-bottom: 5px; } }

.input-text-outer input[type="text"] {
  padding: 12px 15px 12px 18px;
  box-sizing: border-box;
  border-radius: 3px;
  border: solid 1px #afa9a9; }
  .select-content-outer .input-text-outer input[type="text"] {
    min-width: 280px; }
    @media all and (max-width: 479px) {
      .select-content-outer .input-text-outer input[type="text"] {
        min-width: auto;
        width: 100%; } }
  .label-input-pair-row .input-text-outer input[type="text"] {
    width: 90%; }
    @media all and (max-width: 479px) {
      .label-input-pair-row .input-text-outer input[type="text"] {
        width: 100%; } }
  .label-input-pair-row.column-2 .input-text-outer input[type="text"] {
    width: 95%; }
    @media all and (max-width: 479px) {
      .label-input-pair-row.column-2 .input-text-outer input[type="text"] {
        width: 100%; } }
    .label-input-pair-row.column-2 .input-text-outer input[type="text"].short-input {
      width: 40%;
      max-width: 255px; }
      @media all and (max-width: 479px) {
        .label-input-pair-row.column-2 .input-text-outer input[type="text"].short-input {
          width: 100%;
          max-width: 100%; } }
  .column-4 .input-text-outer input[type="text"] {
    width: 100%; }
  .column-4 .input-text-outer input[type="text"]::placeholder {
    text-align: center; }
  .drop-us-outer .input-text-outer input[type="text"] {
    width: 100%;
    border: none;
    border-bottom: 1px solid #e2e2e2;
    padding-left: 0;
    outline: none; }
    @media all and (max-width: 479px) {
      .drop-us-outer .input-text-outer input[type="text"] {
        margin-bottom: 10px; } }

.column-4 .input-text-outer {
  width: 80px; }

.select-content-outer .input-text-outer {
  margin-top: 10px; }

.State-zip-code .input-text-outer {
  margin-left: 10px; }
  @media all and (max-width: 479px) {
    .State-zip-code .input-text-outer {
      width: 100% !important;
      margin: 0;
      margin-top: 5px; } }

.birthday-outer .input-text-outer {
  float: left; }

input:required {
  box-shadow: none; }

.checkbox {
  display: grid;
  grid-template-columns: 1fr 2fr 2fr;
  margin-top: 15px; }
  @media all and (max-width: 530px) {
    .checkbox {
      display: block; } }
  .checkbox.make-donation-outer {
    grid-template-columns: 0.78fr 1.1fr 2fr; }
    @media all and (max-width: 530px) {
      .checkbox.make-donation-outer {
        width: 100%;
        overflow: auto; } }
  @media all and (max-width: 530px) {
    .checkbox .checkbox_outer {
      float: left; } }

.checkbox input {
  padding: 0;
  height: initial;
  width: initial;
  margin-bottom: 0;
  display: none;
  cursor: pointer; }

.checkbox label {
  position: relative;
  cursor: pointer;
  font-size: 12px;
  color: black; }

.checkbox label:before {
  content: '';
  -webkit-appearance: none;
  background-color: transparent;
  border: 1px solid #afa9a9;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05), inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05);
  padding: 7px;
  display: inline-block;
  position: relative;
  vertical-align: middle;
  cursor: pointer;
  margin-right: 5px;
  border-radius: 3px;
  margin-top: -3px; }

.checkbox input:checked + label:after {
  content: '';
  display: block;
  position: absolute;
  top: -5px;
  left: 6px;
  width: 6px;
  height: 14px;
  border: solid #0079bf;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg); }

.tool-tip {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  text-align: center;
  background: #08abe6;
  color: #fff;
  line-height: 21px;
  font-weight: 600; }
  .tool-tip .tooltiptext {
    visibility: hidden;
    width: 120px;
    font-size: 13px;
    background: white;
    color: #333;
    border: solid 1px;
    text-align: center;
    border-radius: 6px;
    padding: 5px 0;
    /* Position the tooltip */
    position: absolute;
    z-index: 1;
    margin-top: 31px; }
    @media all and (max-width: 479px) {
      .StripeCvv .tool-tip .tooltiptext {
        right: 9%; } }
  .tool-tip:hover .tooltiptext {
    visibility: visible; }
  .StripeCvv .tool-tip {
    margin-top: 10px;
    margin-left: 10px; }
    @media all and (max-width: 479px) {
      .StripeCvv .tool-tip {
        float: left;
        width: 20px;
        height: 20px;
        margin-left: 5px; } }
  @media all and (max-width: 530px) {
    .make-donation-outer .tool-tip {
      float: left;
      margin-left: 5px; } }

.new-stories-featured__single {
  transition: box-shadow .2s linear; }
  .new-stories-featured__single:hover {
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.18); }

.new-stories, .editors-picks_card {
  transition: box-shadow .2s linear; }
  .new-stories:hover, .editors-picks_card:hover {
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.18); }

.news-single__content p {
  margin: 10px 0;
  font-size: 13px;
  color: #aaa;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  max-height: 45px; }

.news__banner .donation-wall__search-bar {
  position: static; }

.news-category__slider-single-details h6 {
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  -webkit-line-clamp: unset;
  max-height: unset; }

.news-detail__wrapper {
  padding: 50px 0; }

.news-category-single__wrapper .news-single__content {
  display: grid;
  padding: 0 10px;
  grid-template-rows: 100px auto 100px;
  height: 400px;
  overflow: hidden; }
  @media all and (max-width: 991px) {
    .news-category-single__wrapper .news-single__content {
      height: 400px; } }
  .news-category-single__wrapper .news-single__content .img-wrapper {
    grid-row: 1 / 4;
    grid-column: 1 / 3; }
    .news-category-single__wrapper .news-single__content .img-wrapper img {
      width: 100%;
      max-height: 400px;
      height: 100%;
      object-fit: cover;
      object-position: center;
      filter: brightness(50%);
      position: relative;
      z-index: -1; }
  .news-category-single__wrapper .news-single__content .news-single__details-wrapper {
    grid-row: 2 / 3;
    grid-column: 1 / 3;
    text-align: center; }

.news-search__result-block-container .img-wrapper img {
  width: 100%;
  height: 160px;
  object-fit: cover;
  object-position: center; }

.news-detail {
  margin-top: 104px; }
  .news-detail img {
    position: relative;
    z-index: 1;
    width: 100%;
    height: 400px;
    object-fit: cover;
    object-position: center; }

.news-detail__head {
  padding: 20px 0;
  box-shadow: 1px 4px 10px rgba(0, 0, 0, 0.28);
  z-index: 2;
  position: relative; }

.news-detail__category-wrapper {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(165px, 200px));
  grid-gap: 0px; }

.social-share {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-gap: 15px; }
  .social-share .SocialMediaShareButton:focus {
    outline: none; }
  .thank-you__share-icons .social-share {
    grid-auto-rows: 60px;
    grid-gap: 0; }
  .social-share div {
    cursor: pointer; }

.news-detail__share-wrapper {
  width: 200px;
  margin-top: 35px; }
  .news-detail__share-wrapper img {
    height: 45px;
    width: 45px; }

.news-detail__head-wrapper {
  display: grid;
  grid-template-columns: 70% 30%;
  align-items: center; }
  @media only screen and (max-width: 1024px) {
    .news-detail__head-wrapper {
      grid-template-columns: 100%; } }
  .news-detail__head-wrapper p {
    font-size: 15px;
    margin: 10px 0;
    color: #ca1515; }
  .news-detail__head-wrapper h1 {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    max-height: 28px;
    margin: 0;
    color: #9a9a9a;
    font-weight: 500;
    font-size: 25px; }
  .news-detail__head-wrapper a {
    text-align: right; }
  @media only screen and (max-width: 1024px) {
    .news-detail__head-wrapper .donate__drop {
      display: none; } }

.news__banner {
  background-image: url("../images/news-banner.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position-y: 99%;
  padding: 160px 20px 40px; }

.news__banner-content {
  max-width: 600px;
  padding: 50px 20px 0;
  text-align: center;
  margin: 0 auto; }

.newspage-single-news {
  position: relative;
  box-shadow: 0px 5px 19px rgba(0, 0, 0, 0.05); }
  .newspage-single-news .img-wrapper {
    height: 150px;
    position: relative; }
  .newspage-single-news .img-loader--error {
    background-size: 300px;
    background-image: url("../images/news-image.jpg"); }
  .newspage-single-news .details {
    padding: 15px;
    min-height: 130px; }
    .newspage-single-news .details h6 {
      font-size: 18px;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      max-height: 45px; }
    .newspage-single-news .details p {
      margin: 10px 0;
      font-size: 13px;
      color: #aaa;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      max-height: 47px; }

.news-search__result-block-container {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 20px;
  margin: 20px 0px; }
  @media all and (max-width: 991px) {
    .news-search__result-block-container {
      grid-template-columns: repeat(3, 1fr); } }
  @media all and (max-width: 767px) {
    .news-search__result-block-container {
      grid-template-columns: repeat(2, 1fr); } }
  @media all and (max-width: 550px) {
    .news-search__result-block-container {
      grid-template-columns: repeat(1, 1fr); } }

.news-search__result-block h3 span {
  color: #08abe6; }

.news__view-more {
  color: #ef4800;
  font-family: "Roboto", sans-serif;
  font-size: 14px;
  font-weight: 600;
  margin: 0;
  position: absolute;
  right: 0;
  top: 4px;
  cursor: pointer; }

.new-stories-featured__image img {
  height: 200px !important; }

.news__landing-block {
  position: relative;
  margin: 40px 0px 30px; }
  .news__landing-block h1 {
    color: #08abe6;
    font-size: 20px;
    font-weight: 500; }
  .news__landing-block img {
    width: 100%;
    height: 160px;
    object-fit: cover;
    object-position: center; }

.new-stories-featured__single {
  display: grid;
  grid-template-columns: 1fr 1fr;
  -webkit-box-shadow: 0px 5px 19px rgba(0, 0, 0, 0.05);
  -moz-box-shadow: 0px 5px 19px rgba(0, 0, 0, 0.05);
  box-shadow: 0px 5px 19px rgba(0, 0, 0, 0.05);
  background-color: #fff; }

.new-stories-featured__content {
  padding: 15px; }

.new-stories {
  -webkit-box-shadow: 0px 5px 19px rgba(0, 0, 0, 0.05);
  -moz-box-shadow: 0px 5px 19px rgba(0, 0, 0, 0.05);
  box-shadow: 0px 5px 19px rgba(0, 0, 0, 0.05);
  background-color: #fff; }

.new-stories__content {
  padding: 15px; }

.news__listing {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 20px;
  background-image: url("../images/new_stories.png");
  padding-bottom: 80px;
  background-size: 70%;
  background-repeat: no-repeat;
  background-position: 50% 100%; }
  .news__listing .new-stories-featured__single:nth-of-type(1) {
    grid-column: 1 / 3; }
  .news__listing .new-stories-featured__single:nth-of-type(2) {
    grid-column: 3 / 5; }
  @media all and (max-width: 991px) {
    .news__listing {
      display: none; } }

.news__listing--mob {
  display: none;
  background-size: 70%;
  background-repeat: no-repeat;
  padding-bottom: 40px;
  background-position: 50% 100%;
  background-image: url("../images/new_stories.png"); }
  @media all and (max-width: 991px) {
    .news__listing--mob {
      display: block; } }
  @media all and (max-width: 478px) {
    .news__listing--mob {
      padding-bottom: 20px;
      background-size: 90%; } }

.editors-picks {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 20px;
  grid-auto-rows: 300px;
  background-image: url("../images/editors_picks.png");
  padding-bottom: 100px;
  background-size: 70%;
  background-repeat: no-repeat;
  background-position: 50% 100%; }
  @media all and (max-width: 991px) {
    .editors-picks {
      display: none; } }
  .editors-picks img {
    height: 300px; }

.editors-picks--mob {
  display: none;
  background-image: url("../images/editors_picks.png");
  padding-bottom: 70px;
  background-size: 80%;
  background-repeat: no-repeat;
  background-position: 50% 100%; }
  @media all and (max-width: 991px) {
    .editors-picks--mob {
      display: block; } }
  @media all and (max-width: 478px) {
    .editors-picks--mob {
      padding-bottom: 35px;
      background-size: 90%; } }

.news .BottomBanner-outer {
  background-image: url("../images/new_landingbanner.jpg");
  text-align: center;
  margin-bottom: 50px;
  padding: 80px 20px; }

.news .BottomBanner-outer .heading-bold {
  margin: 0; }

.editors-picks_card {
  position: relative;
  -webkit-box-shadow: 0px 5px 19px rgba(0, 0, 0, 0.05);
  -moz-box-shadow: 0px 5px 19px rgba(0, 0, 0, 0.05);
  box-shadow: 0px 5px 19px rgba(0, 0, 0, 0.05); }

.editors-picks_content .read-more__btn {
  color: white; }
  .editors-picks_content .read-more__btn:hover {
    color: #9e9e9e; }

.editors-picks_content {
  position: absolute;
  bottom: 30px;
  left: 30px;
  z-index: 2; }

.editors-picks_overlay {
  z-index: 1;
  position: absolute;
  background: rgba(0, 0, 0, 0.78);
  background: -moz-linear-gradient(45deg, rgba(0, 0, 0, 0.78) 0%, rgba(28, 28, 28, 0.33) 41%, rgba(82, 82, 82, 0.18) 55%, rgba(255, 255, 255, 0.18) 100%);
  background: -webkit-gradient(left bottom, right top, color-stop(0%, rgba(0, 0, 0, 0.78)), color-stop(41%, rgba(28, 28, 28, 0.33)), color-stop(55%, rgba(82, 82, 82, 0.18)), color-stop(100%, rgba(255, 255, 255, 0.18)));
  background: -webkit-linear-gradient(45deg, rgba(0, 0, 0, 0.78) 0%, rgba(28, 28, 28, 0.33) 41%, rgba(82, 82, 82, 0.18) 55%, rgba(255, 255, 255, 0.18) 100%);
  background: -o-linear-gradient(45deg, rgba(0, 0, 0, 0.78) 0%, rgba(28, 28, 28, 0.33) 41%, rgba(82, 82, 82, 0.18) 55%, rgba(255, 255, 255, 0.18) 100%);
  background: -ms-linear-gradient(45deg, rgba(0, 0, 0, 0.78) 0%, rgba(28, 28, 28, 0.33) 41%, rgba(82, 82, 82, 0.18) 55%, rgba(255, 255, 255, 0.18) 100%);
  background: linear-gradient(45deg, rgba(0, 0, 0, 0.78) 0%, rgba(28, 28, 28, 0.33) 41%, rgba(82, 82, 82, 0.18) 55%, rgba(255, 255, 255, 0.18) 100%);
  top: 0;
  bottom: 0;
  left: 0;
  right: 0; }

.top-stories {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 20px;
  background-image: url("../images/top_stories.png");
  padding-bottom: 105px;
  background-size: 70%;
  background-repeat: no-repeat;
  background-position: 50% 100%; }
  @media all and (max-width: 767px) {
    .news .top-stories {
      display: none; } }
  @media all and (max-width: 991px) {
    .top-stories {
      grid-template-columns: repeat(3, 1fr); } }
  @media all and (max-width: 767px) {
    .top-stories {
      grid-template-columns: repeat(2, 1fr); } }
  @media all and (max-width: 550px) {
    .top-stories {
      grid-template-columns: repeat(1, 1fr); } }
  .news_category-landing .top-stories {
    background-image: none;
    padding-bottom: 60px; }

.top-stories--mob {
  display: none;
  background-image: url("../images/top_stories.png");
  padding-bottom: 55px;
  background-size: 70%;
  background-repeat: no-repeat;
  background-position: 50% 100%; }
  @media all and (max-width: 767px) {
    .top-stories--mob {
      display: block; } }
  @media all and (max-width: 482px) {
    .top-stories--mob {
      padding-bottom: 35px;
      background-size: 90%; } }

.news_category-landing .news__banner {
  text-align: center;
  background-image: none;
  padding: 150px 20px 60px;
  background-color: #08abe6;
  margin-bottom: 40px; }

.news-category-Search-page .news__banner {
  text-align: center;
  background-image: none;
  padding: 150px 20px 60px;
  background-color: #08abe6; }

.news_category-landing-modal {
  background-image: url("../images/news-popup-2.jpg") !important; }

.news__landing-block .slick-list {
  position: relative; }
  .news__landing-block .slick-list:after {
    content: "";
    position: absolute;
    top: 0;
    height: 100%;
    width: 40%;
    z-index: 100; }
  .news__landing-block .slick-list:after {
    right: 0px; }

.theme-btn {
  background-color: #ef4800;
  font-family: "Roboto", sans-serif;
  padding: 10px 0px;
  font-weight: 500;
  font-size: 18px;
  border-radius: 30px;
  transition: 0.3s;
  color: #fff;
  cursor: pointer;
  padding: 10px 40px;
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  -khtml-user-select: none;
  /* Konqueror HTML */
  -moz-user-select: none;
  /* Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently
                                  supported by Chrome and Opera */ }
  .theme-btn:focus {
    outline: none; }
  .theme-btn:hover {
    background-color: #da4607;
    transition: 0.3s;
    box-shadow: 2px 3px 15px rgba(0, 0, 0, 0.19); }
  .login .theme-btn {
    margin: 0;
    width: 100%;
    max-width: 350px; }
  .news-feed__grid-left .theme-btn {
    margin-top: 30px; }
  .donation__left .theme-btn {
    margin-top: 8px; }
  .donation-wall__right .theme-btn {
    background-color: #fff;
    color: #ef4800;
    border: 2px solid #ef4800;
    margin: 0 auto;
    margin-top: 40px;
    display: block; }
    .donation-wall__right .theme-btn:hover {
      background-color: #ef4800;
      color: white;
      transition: 0.3s; }
  .amount-submit-container .theme-btn {
    margin-left: 15%; }
    @media all and (max-width: 479px) {
      .amount-submit-container .theme-btn {
        margin-left: 10%;
        margin-top: 0; } }

.hollow-btn {
  transition: 0.3s;
  background-color: transparent;
  font-family: "Roboto", sans-serif;
  padding: 10px 0px;
  font-weight: 500;
  font-size: 18px;
  border-radius: 30px;
  border: solid 2px #fff;
  color: #fff;
  cursor: pointer;
  padding: 10px 40px;
  transition: 0.3s;
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  -khtml-user-select: none;
  /* Konqueror HTML */
  -moz-user-select: none;
  /* Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently

                           supported by Chrome and Opera */ }
  .hollow-btn:focus {
    outline: none; }
  .hollow-btn:hover {
    background-color: #fff;
    transition: 0.3s; }
  .hollow-btn:hover {
    color: #08abe6;
    transition: 0.3s; }
  .login__box-right-content .hollow-btn {
    padding: 10px 60px;
    color: #fff;
    background: transparent;
    margin-top: 30px; }
    .login__box-right-content .hollow-btn:hover {
      background: white;
      color: #08abe6; }
  .drop-us-outer .hollow-btn {
    color: #ef4800;
    font-size: 14px;
    border: solid #ef4800;
    padding: 6px 17px; }
    .drop-us-outer .hollow-btn:hover {
      background-color: #ef4800;
      color: #fff; }

button {
  background: transparent;
  border: none;
  margin: 20px 0;
  padding: 0; }
  .home-banner-content__right-content button {
    margin-bottom: 50px;
    margin-top: 10px; }

.read-more__btn {
  color: #ef4800;
  font-family: "Roboto", sans-serif;
  font-size: 14px;
  font-weight: 600;
  cursor: pointer; }
  .read-more__btn:hover {
    color: #dc4402;
    transition: 0.3s; }

.btnload {
  display: flex;
  justify-content: center; }

.questions_container .accordion {
  border: none; }

.inner-page__all-functions .accordion {
  border: none;
  display: none;
  margin-top: 50px; }
  @media all and (max-width: 991px) {
    .inner-page__all-functions .accordion {
      display: block; } }

.accordion .title {
  margin: 0;
  font-family: "Roboto", sans-serif;
  background: none !important; }
  .questions_container .accordion .title {
    padding-left: 0px !important;
    padding-right: 0px !important; }
  .inner-page__all-functions .accordion .title {
    color: #08abe6 !important;
    font-size: 18px;
    font-weight: 500; }

.accordion .panel {
  padding: 0px 20px; }
  .questions_container .accordion .panel {
    padding-left: 0px; }
  .inner-page__all-functions .accordion .panel ul li {
    font-family: "Roboto", sans-serif;
    color: #000 !important;
    font-size: 16px !important;
    font-weight: 300;
    letter-spacing: 0;
    margin-bottom: 16px; }

.inner-page__all-functions .accordion .accordion-item {
  border-bottom: 1px solid #ededee; }

.inner-page__all-functions .accordion .accordion-item ul {
  margin-top: 0; }

.inner-page__all-functions .accordion .accordion-item ul li {
  font-size: 12px;
  color: black; }

.validation-error {
  display: none;
  opacity: 0; }

.login, .sign-up {
  position: relative;
  z-index: 2; }

.validation__required {
  text-align: left;
  max-width: 350px;
  margin: 0 auto;
  display: grid;
  grid-template-columns: 1fr 6fr;
  align-items: center;
  background: #ff000012;
  padding: 10px;
  border-radius: 5px; }
  .validation__required ul {
    margin: 0;
    list-style: unset;
    margin-left: 20px;
    align-self: center; }
    .validation__required ul li {
      font-family: "Roboto", sans-serif;
      color: #ec0606;
      font-weight: normal;
      margin: 0;
      font-size: 16px;
      letter-spacing: 0;
      margin-bottom: 5px; }
  .sign-up .validation__required {
    align-items: unset; }
  .validation__required img {
    width: 25px;
    text-align: center;
    margin: 0 auto; }
  .validation__required p {
    color: #ec0606;
    font-weight: normal; }

.login__box {
  -webkit-box-shadow: 0px 1px 13px rgba(0, 0, 0, 0.09);
  -moz-box-shadow: 0px 1px 13px rgba(0, 0, 0, 0.09);
  box-shadow: 0px 1px 13px rgba(0, 0, 0, 0.09);
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin: 155px 0 50px; }
  @media only screen and (max-width: 991px) {
    .login__box {
      display: block;
      margin: 155px 25px 50px; } }
  @media only screen and (max-width: 450px) {
    .login__box {
      margin: 135px 0px 40px; } }

.login__box-right {
  background-image: url("../images/login.jpg");
  position: relative;
  background-size: cover;
  background-position: center; }

.login__box-right-content {
  display: inline-block;
  position: absolute;
  bottom: 100px;
  left: 50%;
  margin-left: -150px;
  text-align: center;
  max-width: 300px; }
  @media only screen and (max-width: 991px) {
    .login__box-right-content {
      position: static;
      margin: 0;
      display: block;
      max-width: 100%;
      padding: 50px 20px 30px; } }

.login__box-left {
  text-align: center;
  padding: 80px 30px; }
  @media only screen and (max-width: 450px) {
    .login__box-left {
      padding: 50px 10px; } }

.login__box-or-txt {
  font-family: "Roboto", sans-serif;
  color: #929292;
  font-size: 20px;
  margin: 35px 0 20px; }

.login__box-btn-set img {
  width: 25px;
  margin: -4px auto 0px; }
  @media only screen and (max-width: 450px) {
    .login__box-btn-set img {
      margin-right: 15px; } }

.login__box-btn-set a {
  padding: 12px;
  color: #fff;
  background: #3b5998;
  cursor: pointer;
  max-width: 320px;
  border-radius: 40px;
  margin: 0 auto;
  display: grid;
  grid-template-columns: 1fr 2fr;
  text-align: -webkit-center;
  transition: .2s; }
  @media only screen and (max-width: 450px) {
    .login__box-btn-set a {
      display: block; } }
  .login__box-btn-set a p {
    display: inline-block !important;
    text-align: left; }
  .login__box-btn-set a:hover {
    transition: .2s;
    box-shadow: 1px 5px 30px rgba(0, 0, 0, 0.14); }

.login__box-btn-set a + a {
  box-shadow: 0px 1px 13px rgba(0, 0, 0, 0.14);
  margin-top: 15px;
  background: #fff; }
  .login__box-btn-set a + a p {
    color: #000; }

.form-styles {
  /* LABEL ======================================= */
  /* active state */
  /* BOTTOM BARS ================================= */
  /* active state */
  /* active state */
  /* ANIMATIONS ================ */ }
  .form-styles input:-webkit-autofill,
  .form-styles input:-webkit-autofill:hover,
  .form-styles input:-webkit-autofill:focus
textarea:-webkit-autofill,
  .form-styles textarea:-webkit-autofill:hover
textarea:-webkit-autofill:focus,
  .form-styles select:-webkit-autofill,
  .form-styles select:-webkit-autofill:hover,
  .form-styles select:-webkit-autofill:focus {
    -webkit-text-fill-color: #000;
    -webkit-box-shadow: 0 0 0px 1000px #fff inset;
    transition: background-color 5000s ease-in-out 0s; }
  .form-styles .group {
    position: relative;
    max-width: 350px;
    margin: 0 auto;
    margin-bottom: 35px; }
    .contact-outer .form-styles .group {
      max-width: unset;
      width: 100%; }
  .form-styles .inputMaterial {
    font-size: 16px;
    color: #000;
    padding: 10px 10px 10px 5px;
    display: block;
    width: 100%;
    box-sizing: border-box;
    border: none;
    font-family: "Roboto", sans-serif;
    border-bottom: 1px solid #a2a2a2;
    border-radius: 0px; }
  .form-styles .inputMaterial:focus {
    outline: none; }
  .form-styles label {
    color: #a2a2a2;
    font-size: 14px;
    font-weight: normal;
    position: absolute;
    pointer-events: none;
    left: 5px;
    top: 10px;
    letter-spacing: .5px;
    font-family: "Roboto", sans-serif;
    transition: 0.2s ease all;
    -moz-transition: 0.2s ease all;
    -webkit-transition: 0.2s ease all; }
  .form-styles .inputMaterial:focus ~ label, .form-styles .inputMaterial:valid ~ label {
    top: -20px;
    font-size: 14px;
    color: #08abe6; }
  .form-styles .bar {
    position: relative;
    display: block;
    width: 100%; }
  .form-styles .bar:before, .form-styles .bar:after {
    content: '';
    height: 1px;
    width: 0;
    bottom: 1px;
    position: absolute;
    background: #08abe6;
    transition: 0.2s ease all;
    -moz-transition: 0.2s ease all;
    -webkit-transition: 0.2s ease all; }
  .form-styles .bar:before {
    left: 50%; }
  .form-styles .bar:after {
    right: 50%; }
  .form-styles .inputMaterial:focus ~ .bar:before, .form-styles .inputMaterial:focus ~ .bar:after {
    width: 50%; }
  .form-styles .inputMaterial:focus ~ .highlight {
    -webkit-animation: inputHighlighter 0.3s ease;
    -moz-animation: inputHighlighter 0.3s ease;
    animation: inputHighlighter 0.3s ease; }

@-webkit-keyframes inputHighlighter {
  from {
    background: #5264AE; }
  to {
    width: 0;
    background: transparent; } }

@-moz-keyframes inputHighlighter {
  from {
    background: #5264AE; }
  to {
    width: 0;
    background: transparent; } }

@keyframes inputHighlighter {
  from {
    background: #5264AE; }
  to {
    width: 0;
    background: transparent; } }
  .login .form-styles {
    margin-bottom: 20px;
    margin-top: 45px; }
  .sign-up .form-styles {
    margin-bottom: 20px;
    margin-top: 15px; }

.forget-password__wrapper {
  -webkit-box-shadow: 0px 1px 13px rgba(0, 0, 0, 0.09);
  -moz-box-shadow: 0px 1px 13px rgba(0, 0, 0, 0.09);
  box-shadow: 0px 1px 13px rgba(0, 0, 0, 0.09);
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin: 185px 0 50px; }
  @media only screen and (max-width: 991px) {
    .forget-password__wrapper {
      display: block; } }
  .forget-password--change .forget-password__wrapper {
    margin: 150px 0 50px; }
  .forget-password__wrapper .forget-password__left {
    text-align: center; }
    .forget-password__wrapper .forget-password__left h1 {
      padding-top: 4px;
      font-weight: normal;
      display: inline-block;
      position: relative;
      margin: 0;
      margin-bottom: 60px;
      color: #000; }
      .forget-password__wrapper .forget-password__left h1:after {
        content: '';
        width: 100%;
        height: 5px;
        background: #08abe6;
        position: absolute;
        top: -2px;
        left: 0;
        border-radius: 10px; }

.forget-password--change .form-styles .group {
  margin-bottom: 35px !important; }

.forget-password--change form p {
  margin-top: 30px; }

.forget-password--change .theme-btn {
  border: none; }

.forget-password .form-styles .group {
  margin-bottom: 15px; }

.forget-password .forget-password__left {
  padding: 80px 20px; }
  .forget-password .forget-password__left p {
    color: red; }

.thank-you {
  margin: 135px 0 70px;
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  -khtml-user-select: none;
  /* Konqueror HTML */
  -moz-user-select: none;
  /* Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently
                                  supported by Chrome and Opera */ }
  @media only screen and (max-width: 991px) {
    .thank-you {
      margin: 108px 0 80px; } }
  @media only screen and (max-width: 767px) {
    .thank-you {
      margin: 112px 0 80px; } }
  @media only screen and (max-width: 767px) {
    .thank-you .main-container {
      padding: 0; } }

.thank-you__content {
  background-image: url("../images/thankyou.jpg");
  position: relative;
  background-size: cover;
  background-position: center;
  padding: 180px 90px 90px;
  margin: 20px 0;
  box-shadow: 0px 3px 16px rgba(0, 0, 0, 0.43); }
  @media only screen and (max-width: 767px) {
    .thank-you__content {
      margin: 0;
      box-shadow: none;
      text-align: center; } }
  @media only screen and (max-width: 450px) {
    .thank-you__content {
      padding: 180px 20px 90px; } }

.thank-you__share {
  margin-top: 70px;
  text-align: center; }
  @media only screen and (max-width: 991px) {
    .thank-you__share {
      padding: 0 60px; } }
  @media only screen and (max-width: 620px) {
    .thank-you__share {
      margin: 80px 0 20px; } }
  @media only screen and (max-width: 450px) {
    .thank-you__share {
      padding: 0 20px; } }

.thank-you__share-icons {
  margin: 35px auto 0;
  max-width: 400px; }
  .thank-you__share-icons .SocialMediaShareButton > div {
    width: 55px !important;
    height: 55px !important;
    margin: 0 auto; }
  .thank-you__share-icons .social-share svg {
    width: 55px;
    height: 55px; }
  .thank-you__share-icons a {
    display: inline-block;
    margin: 0 25px; }
    @media only screen and (max-width: 767px) {
      .thank-you__share-icons a {
        margin: 0 20px; } }
    @media only screen and (max-width: 450px) {
      .thank-you__share-icons a {
        margin: 0 10px; } }
    .thank-you__share-icons a img {
      max-width: 60px; }
      @media only screen and (max-width: 450px) {
        .thank-you__share-icons a img {
          max-width: 35px; } }
      .thank-you__share-icons a img:hover {
        -webkit-filter: grayscale(100%);
        filter: grayscale(100%);
        transition: all .2s ease-in-out; }

.donation-wall {
  display: grid;
  grid-template-columns: 5fr 7.6fr;
  grid-template-rows: minmax(auto, 500px) 100px auto;
  /* width */
  /* Track */
  /* Handle */
  /* Handle on hover */ }
  @media only screen and (max-width: 991px) {
    .donation-wall {
      display: block; } }
  .donation-wall ::-webkit-scrollbar {
    width: 8px; }
  .donation-wall ::-webkit-scrollbar-track {
    background: rgba(0, 0, 0, 0.14); }
  .donation-wall ::-webkit-scrollbar-thumb {
    background: #ef4800;
    border-radius: 10px; }
  .donation-wall ::-webkit-scrollbar-thumb:hover {
    background: #555;
    background-color: #b33600; }

.donation-wall__left {
  grid-row: 2 / 4;
  grid-column: 1 / 2; }

.donation-search__result-block--img {
  position: relative; }

.donation-wall__right {
  grid-row: 3 / 4;
  grid-column: 2 /3; }
  @media only screen and (max-width: 991px) {
    .donation-wall__right {
      margin-top: -15px;
      padding-bottom: 100px; } }
  .donation-wall__right a {
    text-align: center;
    display: block;
    margin-top: 30px; }

.donation-wall__right .donation__right {
  margin-top: 0;
  grid-template-columns: repeat(auto-fit, minmax(165px, 1fr));
  grid-auto-rows: minmax(auto, 160px);
  max-height: 960px;
  overflow-y: scroll; }
  @media only screen and (max-width: 991px) {
    .donation-wall__right .donation__right {
      max-height: 600px; } }
  @media only screen and (max-width: 767px) {
    .donation-wall__right .donation__right {
      grid-auto-rows: minmax(auto, 160px) !important; } }
  @media only screen and (max-width: 449px) {
    .donation-wall__right .donation__right {
      max-height: 400px;
      grid-auto-rows: minmax(auto, 100px) !important; } }

.donation-wall__left .donation__left {
  padding-top: 0;
  grid-template-columns: auto minmax(auto, 450px); }
  .donation-wall__left .donation__left div + div {
    background: white;
    z-index: 1;
    padding: 50px;
    position: relative;
    box-shadow: 0px 1px 13px rgba(0, 0, 0, 0.09);
    padding-bottom: 30px; }
    @media only screen and (max-width: 991px) {
      .donation-wall__left .donation__left div + div {
        padding: 0;
        box-shadow: none;
        padding-bottom: 0; } }
  @media only screen and (max-width: 991px) {
    .donation-wall__left .donation__left {
      margin: 0 auto;
      margin-top: -50px;
      padding: 50px; } }
  @media only screen and (max-width: 620px) {
    .donation-wall__left .donation__left {
      margin: 0 20px;
      margin-top: -50px; } }
  @media only screen and (max-width: 449px) {
    .donation-wall__left .donation__left {
      padding: 40px 20px; } }

.donation-wall__banner {
  position: relative;
  grid-column: 1 / 3;
  grid-row: 1 / 3;
  overflow: hidden; }
  .donation-search .donation-wall__banner {
    height: 500px; }
  @media only screen and (max-width: 991px) {
    .donation-wall__banner {
      height: 272px; } }
  .donation-wall__banner img {
    vertical-align: middle;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center; }

.donation-wall__search-bar {
  position: absolute;
  max-width: 700px;
  height: 45px;
  overflow: auto;
  margin: auto;
  top: 50px;
  left: 0;
  bottom: 0;
  right: 0;
  border-radius: 30px; }
  .faq__top-banner .donation-wall__search-bar {
    position: static; }
  @media only screen and (max-width: 767px) {
    .donation-wall__search-bar {
      max-width: 600px; } }
  @media only screen and (max-width: 620px) {
    .donation-wall__search-bar {
      max-width: 100%;
      margin: auto 20px; } }
  .donation-wall__search-bar input {
    width: 100%;
    box-sizing: border-box;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.29);
    border: none;
    padding: 0px 45px;
    font-size: 17px;
    color: #fff;
    font-weight: 300; }
    .donation-wall__search-bar input:focus {
      outline: none; }
  .donation-wall__search-bar .search-btn {
    background-image: url("../images/search-icon.png");
    background-repeat: no-repeat;
    background-position: center;
    position: absolute;
    height: 45px;
    width: 45px;
    margin: 0;
    top: 0;
    right: 25px;
    cursor: pointer; }
    .donation-wall__search-bar .search-btn:focus {
      outline: none; }
    .donation-search .donation-wall__search-bar .search-btn {
      cursor: auto; }
  .donation-wall__search-bar ::-webkit-input-placeholder {
    /* Chrome/Opera/Safari */
    color: #fff;
    font-family: "Roboto", sans-serif;
    font-weight: 400; }
  .donation-wall__search-bar ::-moz-placeholder {
    /* Firefox 19+ */
    color: #fff;
    font-family: "Roboto", sans-serif;
    font-weight: 400; }
  .donation-wall__search-bar :-ms-input-placeholder {
    /* IE 10+ */
    color: #fff;
    font-family: "Roboto", sans-serif;
    font-weight: 400; }
  .donation-wall__search-bar :-moz-placeholder {
    /* Firefox 18- */
    color: #fff;
    font-family: "Roboto", sans-serif;
    font-weight: 400; }

.donation-wall__search-bar--content {
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden; }

.img-loader {
  background-image: url("../images/img-loader.gif");
  background-repeat: no-repeat;
  background-position: 50% 52%;
  background-size: 100px;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0; }
  .news-single__wrapper .img-loader {
    background-position: 50% 25%; }
  .donation-wall__right .img-loader {
    background-position: 50% 50%; }

.img-loader--error {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  background-image: url("../images/donation-wall.jpg"); }
  .news-single__wrapper .img-loader--error {
    background-image: url("../images/news-image.jpg");
    position: relative;
    height: 180px;
    width: 100%; }

.donation-search__result-block {
  padding: 50px 0; }

.donation-search__result-block-container {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-column-gap: 30px;
  grid-row-gap: 30px;
  margin-bottom: 20px; }
  @media only screen and (max-width: 991px) {
    .donation-search__result-block-container {
      grid-template-columns: 1fr 1fr; } }
  @media only screen and (max-width: 620px) {
    .donation-search__result-block-container {
      grid-template-columns: 1fr; } }

.donation-search__result-block--single {
  border-radius: 5px;
  overflow: hidden;
  -webkit-box-shadow: 0px 1px 13px rgba(0, 0, 0, 0.09);
  -moz-box-shadow: 0px 1px 13px rgba(0, 0, 0, 0.09);
  box-shadow: 0px 1px 13px rgba(0, 0, 0, 0.09);
  display: grid;
  grid-template-columns: 1fr 2fr;
  grid-auto-rows: 140px; }
  .donation-search__result-block--single:hover {
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.18); }
  .donation-search__result-block--single img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
    -webkit-filter: grayscale(100%);
    filter: grayscale(100%); }
  .donation-search__result-block--single .price, .donation-search__result-block--single .name {
    font-weight: 600; }
  .donation-search__result-block--single .date {
    color: #afafaf;
    font-size: 14px;
    font-size: 14px; }

.donation-search__not-found {
  margin-bottom: 70px;
  text-align: center; }
  .donation-search__not-found h1 {
    margin: 15px 0;
    font-size: 30px;
    color: #000; }
  .donation-search__not-found p {
    color: #000;
    max-width: 500px;
    margin: 0 auto;
    font-size: 17px; }
  .donation-search__not-found img {
    max-width: 80px; }

.login__box-right-content #bold {
  font-weight: 600; }

.sign-up .login__box-right-content {
  max-width: 400px;
  margin-left: -200px; }
  @media only screen and (max-width: 991px) {
    .sign-up .login__box-right-content {
      max-width: 100%;
      margin: 0;
      padding: 70px 20px; } }

.sign-up .login__box-or-txt {
  margin: 15px 0; }

.sign-up .login__box-right {
  background-image: url("../images/signup.jpg");
  background-position: 80% 20%; }
  @media only screen and (max-width: 991px) {
    .sign-up .login__box-right {
      background-position: 50% 10%; } }

.sign-up__img-upload {
  text-align: center;
  margin-bottom: 35px; }
  .sign-up__img-upload .icon {
    max-width: 110px;
    margin: 0 auto;
    margin-bottom: 10px; }
    .sign-up__img-upload .icon img {
      width: 100%;
      object-fit: cover;
      height: 110px;
      vertical-align: middle;
      object-position: center;
      border-radius: 80px; }
    .sign-up__img-upload .icon .icon_null {
      cursor: pointer; }
    .sign-up__img-upload .icon .img-preview {
      position: relative; }
      .sign-up__img-upload .icon .img-preview .icon_null {
        position: absolute !important;
        top: 0;
        left: 0;
        opacity: 0; }

.sign-up__img-upload .login__box-forget-password {
  font-size: 17px; }

.sign-up .login__box-left {
  padding: 60px 0 30px; }
  @media only screen and (max-width: 991px) {
    .sign-up .login__box-left {
      padding: 60px 10px 30px; } }

.icon_null {
  max-width: 110px; }
  .icon_null input {
    max-width: 110px; }

/*
	- Document: PDFFLY FAQ Theme
	- Author:   sangeetha@terrificminds.com
*/
/*FAQ Banner section*/
.faq__top-banner-wrap {
  background-image: url("../images/faq-banner.png");
  padding: 250px 15px 240px 15px;
  text-align: center;
  color: white;
  background-size: 100%;
  background-size: cover;
  background-repeat: no-repeat; }
  @media only screen and (max-width: 767px) {
    .faq__top-banner-wrap {
      padding: 155px 15px 40px 15px;
      background-position: -180px 0px;
      text-align: left !important; } }

.faq .donation-search__result-block--single {
  display: block !important;
  padding: 15px; }
  .faq .donation-search__result-block--single p {
    padding: 15px 0; }
    .faq .donation-search__result-block--single p.name {
      border-bottom: 1px solid #ddd; }

/* FAQ tab section */
.faq__tab-content {
  margin: 60px 0; }
  @media only screen and (min-width: 840px) {
    .faq__tab-content {
      margin: 30px 0; } }
  .faq__tab-content .RRT__container {
    margin: 15px 0; }
    @media only screen and (min-width: 840px) {
      .faq__tab-content .RRT__container {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-column-gap: 30px;
        margin: 40px 0; } }
    @media only screen and (min-width: 840px) {
      .faq__tab-content .RRT__container .RRT__tabs {
        display: grid;
        grid-template-columns: 1fr;
        grid-gap: 20px;
        grid-auto-rows: 150px; } }
    .faq__tab-content .RRT__container .RRT__tabs .RRT__tab {
      padding: 0;
      background: #fff;
      border: none;
      white-space: unset; }
    .faq__tab-content .RRT__container .RRT__tabs .RRT__tab--selected {
      border: none; }
      .faq__tab-content .RRT__container .RRT__tabs .RRT__tab--selected .faq-block__right {
        position: relative; }
        .faq__tab-content .RRT__container .RRT__tabs .RRT__tab--selected .faq-block__right:after {
          position: absolute;
          top: 25%;
          right: 0;
          content: "";
          display: block;
          background: url("../images/arrow.png") no-repeat;
          width: 35px;
          height: 35px; }
          @media only screen and (max-width: 839px) {
            .faq__tab-content .RRT__container .RRT__tabs .RRT__tab--selected .faq-block__right:after {
              width: 17px;
              height: 31px;
              -webkit-transform: rotate(90deg);
              -moz-transform: rotate(90deg);
              -o-transform: rotate(90deg);
              -ms-transform: rotate(90deg);
              transform: rotate(90deg); } }
    .faq__tab-content .RRT__container .RRT__tabs .faq-block {
      -webkit-box-shadow: 0px 10px 46px rgba(0, 0, 0, 0.05);
      -moz-box-shadow: 0px 10px 46px rgba(0, 0, 0, 0.05);
      box-shadow: 0px 10px 46px rgba(0, 0, 0, 0.05);
      display: grid;
      grid-template-columns: 1fr 6fr;
      padding: 15px;
      align-items: center; }
      @media only screen and (max-width: 767px) {
        .faq__tab-content .RRT__container .RRT__tabs .faq-block {
          padding: 5px; } }
      .faq__tab-content .RRT__container .RRT__tabs .faq-block:hover {
        box-shadow: 0 8px 16px rgba(0, 0, 0, 0.18); }
      .faq__tab-content .RRT__container .RRT__tabs .faq-block .faq-block__left, .faq__tab-content .RRT__container .RRT__tabs .faq-block .faq-block__right {
        margin: 10px;
        vertical-align: middle; }
        @media only screen and (max-width: 468px) {
          .faq__tab-content .RRT__container .RRT__tabs .faq-block .faq-block__left, .faq__tab-content .RRT__container .RRT__tabs .faq-block .faq-block__right {
            margin: 5px; } }
        .faq__tab-content .RRT__container .RRT__tabs .faq-block .faq-block__left img, .faq__tab-content .RRT__container .RRT__tabs .faq-block .faq-block__right img {
          max-width: 80px;
          transform: scale(1);
          transition: all .2s ease-in-out;
          vertical-align: middle; }
          @media only screen and (max-width: 468px) {
            .faq__tab-content .RRT__container .RRT__tabs .faq-block .faq-block__left img, .faq__tab-content .RRT__container .RRT__tabs .faq-block .faq-block__right img {
              max-width: 55px; } }
    .faq__tab-content .RRT__container .RRT__panel {
      -webkit-box-shadow: 0px 10px 46px rgba(0, 0, 0, 0.05);
      -moz-box-shadow: 0px 10px 46px rgba(0, 0, 0, 0.05);
      box-shadow: 0px 10px 46px rgba(0, 0, 0, 0.05);
      border: none;
      display: grid;
      grid-template-columns: 1fr;
      align-items: center;
      padding: 2em; }
      @media only screen and (max-width: 839px) {
        .faq__tab-content .RRT__container .RRT__panel {
          padding: 0 5px;
          background: #eeeeef5e; } }
      .faq__tab-content .RRT__container .RRT__panel:hover {
        box-shadow: 0 8px 16px rgba(0, 0, 0, 0.18); }
      @media only screen and (max-width: 839px) {
        .faq__tab-content .RRT__container .RRT__panel .faq-content .faq-display {
          display: none; } }
      .faq__tab-content .RRT__container .RRT__panel .faq-content .accordion {
        border: none;
        margin: 30px 0px; }
        @media only screen and (max-width: 468px) {
          .faq__tab-content .RRT__container .RRT__panel .faq-content .accordion {
            margin: 20px 0px; } }
        .faq__tab-content .RRT__container .RRT__panel .faq-content .accordion .accordion-item .title {
          padding: 18px 0;
          font-weight: 500;
          color: #868585;
          font-size: 18px;
          border-bottom: 1px solid #868585; }
          @media only screen and (max-width: 768px) {
            .faq__tab-content .RRT__container .RRT__panel .faq-content .accordion .accordion-item .title {
              font-size: 16px; } }
          @media only screen and (max-width: 468px) {
            .faq__tab-content .RRT__container .RRT__panel .faq-content .accordion .accordion-item .title {
              font-size: 14px; } }
          .faq__tab-content .RRT__container .RRT__panel .faq-content .accordion .accordion-item .title::after {
            font-size: 16px;
            border: 1px solid #777;
            border-radius: 50%;
            padding: 3px 5px; }
        .faq__tab-content .RRT__container .RRT__panel .faq-content .accordion .accordion-item:last-child .title {
          border-bottom: transparent; }
        .faq__tab-content .RRT__container .RRT__panel .faq-content .accordion .panel {
          padding: 0px; }

.inner-page__file-download,
.inner-page__file-upload,
.inner-page__progress-bar {
  z-index: 2;
  position: relative;
  margin-top: -180px; }

.inner-page__download-box {
  box-shadow: 0px 3px 7px rgba(0, 0, 0, 0.22);
  margin-bottom: 10px; }
  @media all and (max-width: 991px) {
    .inner-page__download-box .inner-page__file-download-wrapper {
      border: solid white; } }

.inner-page__file-upload {
  z-index: 2;
  position: relative;
  margin-top: -180px; }

.top-banner__ad {
  max-width: 700px;
  overflow: auto;
  margin: 50px auto;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0; }
  .top-banner__ad img {
    width: 100% !important;
    height: auto !important; }

.inner-page__top-banner {
  position: relative;
  margin-top: 102px;
  height: 400px; }
  .inner-page__top-banner img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center; }

.inner-page__all-functions {
  padding: 60px 0; }

.all-functions__category {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  margin-top: 60px; }
  @media all and (max-width: 991px) {
    .all-functions__category {
      display: none; } }
  .all-functions__category .all-functions__category-col a li {
    font-family: "Roboto", sans-serif;
    color: #000;
    font-size: 16px;
    font-weight: 300;
    letter-spacing: 0;
    margin-bottom: 16px; }

.inner-page__process {
  background: #08abe6;
  padding: 70px;
  border-radius: 5px;
  border: 5px solid #fff;
  box-shadow: 0px 4px 11px rgba(0, 0, 0, 0.1);
  margin-bottom: 10px;
  position: relative; }
  @media all and (max-width: 479px) {
    .inner-page__process {
      padding: 3%; } }

.file-upload__widget {
  margin: 0 auto;
  text-align: center; }
  .file-upload__widget label img {
    max-width: 50px;
    margin-bottom: 5px; }

.inner-page__process .file-upload__widget .filepond--wrapper {
  max-width: 500px;
  margin: 0 auto; }
  .inner-page__process .file-upload__widget .filepond--wrapper .filepond--root {
    margin: 1em;
    margin-top: 0; }
    @media all and (max-width: 479px) {
      .inner-page__process .file-upload__widget .filepond--wrapper .filepond--root {
        margin: 0; } }

.inner-page__process .filepond--drop-label label {
  font-family: "Roboto", sans-serif;
  color: #fff;
  font-size: 16px;
  line-height: 20px;
  cursor: pointer; }

.inner-page__process .filepond--drop-label .bold-txt {
  font-weight: 600 !important; }

.inner-page__process .filepond--drop-label .underline-txt {
  padding-bottom: 1px;
  border-bottom: 2px solid #fff; }

.inner-page__process .filepond--drip {
  opacity: 1;
  background: #08abe6; }

@media all and (max-width: 479px) {
  .inner-page__process .filepond--file {
    display: block;
    background: #379763; } }

.inner-page__process .filepond--file-info {
  font-family: "Roboto", sans-serif;
  letter-spacing: 1px; }

.inner-page__process .filepond--list.filepond--list {
  margin-top: 25px; }
  @media all and (max-width: 479px) {
    .inner-page__process .filepond--list.filepond--list {
      left: 0;
      right: 0;
      margin: 0; } }

.filepond--file-status .filepond--file-status-sub {
  max-width: 200px;
  text-overflow: ellipsis;
  overflow: hidden; }

.file-download__top-row {
  display: grid;
  grid-template-columns: 2fr 1.5fr; }
  @media all and (max-width: 991px) {
    .file-download__top-row {
      grid-template-columns: 1fr; } }

.file-download-top__details {
  padding: 40px 30px 20px 40px;
  background: #08abe6; }
  @media all and (max-width: 991px) {
    .file-download-top__details {
      text-align: center; } }

.file-download-top__right {
  text-align: center;
  background-image: url("../images/pdf-download.jpg");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  padding: 25px 0px; }
  @media all and (max-width: 991px) {
    .file-download-top__right {
      display: none; } }

.cloud-widget {
  max-width: 150px;
  text-align: center; }
  .inner-page__process .cloud-widget {
    width: 150px;
    position: absolute;
    bottom: 16px;
    right: 50px; }
    @media all and (max-width: 991px) {
      .inner-page__process .cloud-widget {
        position: static;
        margin: 0 auto; } }
    @media all and (max-width: 479px) {
      .inner-page__process .cloud-widget {
        margin-top: 20px; } }
  .file-download-top__details .cloud-widget {
    margin-top: 50px; }
  .cloud-widget p {
    font-size: 15px;
    font-weight: 500;
    margin-bottom: 8px; }
  .cloud-widget.desktop {
    display: block; }
    @media all and (max-width: 991px) {
      .cloud-widget.desktop {
        display: none; } }
  .cloud-widget.mobile {
    display: none; }
    @media all and (max-width: 991px) {
      .cloud-widget.mobile {
        display: block;
        max-width: 100%; } }
  .cloud-widget .mobile {
    display: none; }
    @media all and (max-width: 991px) {
      .cloud-widget .mobile {
        display: block; } }
  @media all and (max-width: 991px) {
    .cloud-widget .g-savetodrive__wrapper {
      background: gainsboro;
      border-radius: 50px;
      padding: 15px 50px;
      font-size: 14px;
      margin-top: 10px;
      padding-left: 10%;
      padding-right: 12%; } }
  @media all and (max-width: 991px) {
    .cloud-widget .g-savetodrive__wrapper #___savetodrive_1 {
      top: 0;
      height: 100% !important;
      position: absolute;
      left: 0;
      width: 100% !important; } }
  @media all and (max-width: 991px) {
    .cloud-widget .g-savetodrive__wrapper #___savetodrive_1 iframe {
      position: absolute !important;
      width: 100% !important;
      height: 100% !important;
      opacity: 0.01;
      padding-top: 4%; } }
  .cloud-widget .g-savetodrive__wrapper label.mobile {
    float: left;
    font-size: 14px;
    color: black;
    padding-top: 4px; }
  @media all and (max-width: 991px) {
    .cloud-widget .g-savetodrive__wrapper img {
      float: right; } }

.file-download__sucess-txt {
  font-size: 17px; }
  .file-download__sucess-txt span {
    font-weight: 600; }

.cloud-widget__wrap {
  display: grid;
  grid-template-columns: 1fr 1fr;
  background: #ddd;
  grid-gap: 1px;
  border-radius: 50px;
  overflow: hidden; }
  @media all and (max-width: 991px) {
    .inner-page__file-download .cloud-widget__wrap {
      background: none;
      border-radius: 0;
      grid-template-columns: 1fr; } }
  .cloud-widget__wrap a {
    padding: 10px;
    background: #fff; }
    @media all and (max-width: 991px) {
      .inner-page__file-download .cloud-widget__wrap a {
        background: gainsboro;
        border-radius: 50px;
        padding: 15px 50px;
        font-size: 14px;
        margin-top: 10px;
        padding-left: 10%;
        padding-right: 12%; } }
    @media all and (max-width: 991px) {
      .inner-page__file-download .cloud-widget__wrap a label {
        float: left;
        margin-top: 4px;
        font-size: 14px;
        color: black; } }
    @media all and (max-width: 991px) {
      .inner-page__file-download .cloud-widget__wrap a img {
        float: right;
        right: 40px; } }

.file-download__bottom-row {
  display: grid;
  grid-template-columns: 2fr 1.5fr; }
  @media all and (max-width: 991px) {
    .file-download__bottom-row {
      grid-template-columns: 1fr;
      background: #08abe6; } }

.file-download__bottom-btn {
  display: grid;
  grid-template-columns: .3fr 2fr 1fr;
  padding: 30px 25px;
  align-items: center; }
  @media all and (max-width: 991px) {
    .file-download__bottom-btn {
      grid-template-columns: 1fr;
      padding-top: 0; } }
  .file-download__bottom-btn img {
    max-width: 45px; }
    @media all and (max-width: 991px) {
      .file-download__bottom-btn img {
        margin: 0 auto; } }

.file-download__bottom-filename h2 {
  color: #000;
  margin: 0;
  font-size: 18px;
  font-weight: 600; }
  @media all and (max-width: 991px) {
    .file-download__bottom-filename h2 {
      text-align: center;
      color: white;
      padding-top: 10px;
      padding-bottom: 10px; } }

.file-download__bottom-filename p {
  color: #000;
  margin: 0;
  font-size: 14px; }
  @media all and (max-width: 991px) {
    .file-download__bottom-filename p {
      text-align: center;
      color: white;
      padding-top: 10px;
      padding-bottom: 10px; } }

.download-btn input[type=submit] {
  background-image: url("../images/download.png");
  width: 165px;
  text-align: left;
  border: none;
  display: block;
  color: black;
  background-repeat: no-repeat;
  background-position: 85% 48%;
  background-color: #dcdcdc;
  padding: 12px 20px;
  border-radius: 50px;
  margin: 0 auto;
  transition: .2s;
  font-family: "Roboto", sans-serif;
  cursor: pointer; }
  .download-btn input[type=submit]:hover {
    transition: .2s;
    box-shadow: 0px 0px 25px -8px rgba(0, 0, 0, 0.6); }
  .download-btn input[type=submit]:focus {
    outline: none; }
  @media all and (max-width: 991px) {
    .download-btn input[type=submit] {
      width: 100%;
      padding: 20px 10%;
      font-size: 14px; } }

.file-download__bottom-options {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  text-align: center; }
  @media all and (max-width: 991px) {
    .file-download__bottom-options {
      grid-template-columns: 1fr 1fr; } }
  .file-download__bottom-options a {
    border-left: 1px solid #dcdcdc; }
    @media all and (max-width: 991px) {
      .file-download__bottom-options a {
        border: none; } }
  @media all and (max-width: 991px) {
    .file-download__bottom-options .single-col {
      background: #08abe6; } }
  @media all and (max-width: 991px) {
    .file-download__bottom-options .single-col:hover {
      background: #05a4de; } }

.split-pdf-wrapper {
  background: #08abe6;
  border-radius: 5px;
  border: 5px solid #fff;
  box-shadow: 0 4px 11px rgba(0, 0, 0, 0.1);
  margin-bottom: 10px;
  display: grid;
  grid-template-columns: 2fr 1fr; }
  @media all and (max-width: 767px) {
    .split-pdf-wrapper {
      display: block; } }

.split-pdf__info {
  text-align: center;
  display: grid;
  align-items: center; }
  @media all and (max-width: 767px) {
    .split-pdf__info {
      padding: 30px; } }
  .split-pdf__info img {
    max-width: 60px; }
  .split-pdf__info h1 {
    margin: 0;
    color: white;
    max-width: 300px;
    margin: 8px auto;
    font-size: 20px;
    font-weight: 500; }

.split-pdf__opitions {
  padding: 30px;
  background: #008ec1; }
  .split-pdf__opitions .split-pdf__btn-set {
    text-align: center;
    margin-top: 20px; }
    .split-pdf__opitions .split-pdf__btn-set button {
      width: 115px;
      text-align: center;
      font-family: "Roboto", sans-serif;
      color: white;
      font-size: 16px;
      cursor: pointer;
      margin: 0 auto; }
      .split-pdf__opitions .split-pdf__btn-set button:focus {
        outline: none; }
      .split-pdf__opitions .split-pdf__btn-set button:hover {
        text-decoration: underline; }
  .split-pdf__opitions .middle {
    width: 100%;
    text-align: center;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 15px; }
    @media all and (max-width: 500px) {
      .split-pdf__opitions .middle {
        display: block; } }
    @media all and (max-width: 767px) {
      .split-pdf__opitions .middle label {
        text-align: center; } }
    @media all and (max-width: 500px) {
      .split-pdf__opitions .middle label .box {
        width: 100%;
        height: auto; } }
    @media all and (max-width: 500px) {
      .split-pdf__opitions .middle label .box.front-end {
        margin-bottom: 20px; } }
    @media all and (max-width: 500px) {
      .split-pdf__opitions .middle label .box .box__wrapper {
        position: static;
        transform: unset; } }
    .split-pdf__opitions .middle input[type="radio"] {
      display: none; }
      .split-pdf__opitions .middle input[type="radio"]:checked + .box {
        background-color: #08abe6;
        border-color: #08abe6; }
        .split-pdf__opitions .middle input[type="radio"]:checked + .box .box__wrapper {
          color: white;
          transform: translateY(25px); }
          @media all and (max-width: 767px) {
            .split-pdf__opitions .middle input[type="radio"]:checked + .box .box__wrapper {
              transform: unset; } }
    .split-pdf__opitions .middle .box {
      width: 200px;
      height: 200px;
      background-color: transparent;
      transition: all 250ms ease;
      will-change: transition;
      display: inline-block;
      text-align: center;
      cursor: pointer;
      position: relative;
      font-weight: 900;
      border: #d7d7d7 2px solid;
      border-radius: 5px;
      color: black;
      background-color: #1d8ec1;
      box-sizing: border-box; }
      .split-pdf__opitions .middle .box:active {
        transform: translateY(10px); }
      .split-pdf__opitions .middle .box .box__wrapper {
        position: absolute;
        transform: translate(0, 45px);
        left: 0;
        right: 0;
        transition: all 300ms ease;
        font-size: 1.5em;
        user-select: none;
        color: white;
        padding: 20px;
        font-family: "Roboto", sans-serif;
        font-weight: 200;
        font-size: 16px; }
        .split-pdf__opitions .middle .box .box__wrapper:before {
          font-size: 1.2em;
          font-family: FontAwesome;
          display: block;
          transform: translateY(-80px);
          opacity: 0;
          transition: all 300ms ease-in-out;
          font-weight: normal;
          color: white; }
        .split-pdf__opitions .middle .box .box__wrapper img {
          max-width: 110px;
          display: block;
          margin: 0 auto;
          margin-bottom: 15px; }
      .split-pdf__opitions .middle .box .back-end img {
        max-width: 130px; }

.split-pdf-container {
  margin-top: -180px;
  position: relative;
  text-align: center; }
  .split-pdf-container button {
    margin: 0 auto;
    height: 40px;
    width: 115px;
    background: #008ec1;
    color: white;
    border-radius: 3px;
    font-weight: 600;
    cursor: pointer;
    margin-top: 20px; }

.react-pdf__Document {
  background: white;
  border-radius: 5px; }
  .react-pdf__Document .instruction {
    background: #ffffff;
    text-align: center;
    padding: 18px;
    color: #5f5a5a;
    font-family: "Roboto", sans-serif; }
  .react-pdf__Document .page-preview-container {
    border-radius: 5px;
    padding: 1px 1px 30px 1px;
    border-bottom: none;
    height: 100%;
    overflow: hidden;
    margin-left: auto;
    margin-right: auto;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-line-pack: start;
    align-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    box-sizing: border-box;
    background: #f9f9f9;
    border: solid 1px #f5f5f5; }
  .react-pdf__Document .react-pdf__Page {
    user-select: none;
    position: relative;
    width: 189px;
    height: auto;
    margin: 20px;
    padding: 10px;
    transition-property: color, background;
    transition-duration: 0.2s;
    border: solid 1px #ededee;
    background: white;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.05); }
    .react-pdf__Document .react-pdf__Page.active {
      background: #26abe7; }
    .react-pdf__Document .react-pdf__Page .react-pdf__Page__canvas {
      width: 100% !important;
      height: auto !important; }

.loading-pdf-msg li {
  color: #a5a3a3;
  font-weight: 200; }

.react-pdf__message {
  padding: 10px;
  font-family: "Roboto", sans-serif; }

.merge-button {
  border-radius: 4px;
  line-height: 8px;
  background-color: #ef4800 !important;
  color: white !important; }

.progress-bar__widget {
  position: relative;
  background: #caf0fb;
  width: 100px;
  height: 70px;
  margin: 0 auto;
  border: 10px solid #46b6db;
  box-shadow: 2px 41px 22px -41px rgba(1, 1, 1, 0.53); }
  .progress-bar__widget .mask {
    position: absolute;
    height: 129%;
    top: -10px;
    width: 98%;
    background: #08abe6;
    box-sizing: border-box; }
  .progress-bar__widget .left-mask {
    left: -71px;
    transform: skewX(10deg);
    border-right: 6px solid #46b6db; }
  .progress-bar__widget .right-mask {
    right: -71px;
    transform: skewX(-10deg);
    border-left: 6px solid #46b6db;
    box-shadow: inset 86px -71px 44px -104px #000; }

.progress-bar__filter {
  background: #3892b0;
  width: 100%;
  position: absolute;
  bottom: 0;
  transition: height .01s ease-in; }

.cde-component-outer {
  background: #eeeeef; }
  @media all and (max-width: 479px) {
    .cde-component-outer .component-grid {
      margin-bottom: 30px; } }
  .cde-component-outer .component-grid h2, .cde-component-outer .component-grid p {
    color: #000; }
  .cde-component-outer .component-grid h2 {
    font-size: 17px;
    margin-top: 30px; }
    @media all and (max-width: 479px) {
      .cde-component-outer .component-grid h2 {
        font-size: 17px; } }
  .cde-component-outer .component-grid p {
    max-width: 250px;
    margin: 20px auto 0;
    font-size: 16px; }
    @media all and (max-width: 479px) {
      .cde-component-outer .component-grid p {
        font-size: 14px; } }
  @media all and (max-width: 767px) {
    .cde-component-outer .component-grid .img-outer img {
      width: 40px; } }

.DnD-outer {
  padding: 60px 0px; }
  .DnD-outer .DnD-left {
    padding-top: 80px;
    text-align: left;
    float: left;
    width: 50%; }
    @media all and (max-width: 767px) {
      .DnD-outer .DnD-left {
        width: 100%; } }
    .DnD-outer .DnD-left p {
      color: #000;
      margin-top: 20px; }
  .DnD-outer .DnD-right {
    float: right;
    width: 50%;
    text-align: right; }
    @media all and (max-width: 767px) {
      .DnD-outer .DnD-right {
        width: 100%;
        text-align: center; } }
    @media all and (max-width: 479px) {
      .DnD-outer .DnD-right img {
        width: 80%; } }

.BottomBanner-outer {
  background-image: url("../images/syria-refugee-bg.jpg");
  background-size: cover;
  background-position: center;
  max-height: 280px;
  padding: 45px 0; }
  @media only screen and (max-width: 991px) {
    .BottomBanner-outer {
      display: none; } }
  .contact-outer .BottomBanner-outer {
    background-image: url("../images/contact-bottombg.png");
    margin-top: 20px; }
  .news-detail .BottomBanner-outer {
    text-align: center;
    padding: 110px 20px 100px; }
    .news-detail .BottomBanner-outer .heading-bold {
      margin: 0; }
      .news-detail .BottomBanner-outer .heading-bold br {
        display: none; }
    .news-detail .BottomBanner-outer p br {
      display: none; }
    .news-detail .BottomBanner-outer .main-container {
      display: block; }

.home-banner-bg {
  display: grid;
  grid-template-columns: 5fr 7fr;
  z-index: 10;
  overflow: auto; }
  @media only screen and (max-width: 991px) {
    .home-banner-bg {
      display: none; } }

.home-banner-bg__left {
  background-color: #08abe6;
  min-width: 0;
  min-height: 0; }
  .home-banner-bg__left img {
    object-fit: cover;
    overflow: hidden;
    width: 100%;
    max-height: 820px; }

.home-banner__vid {
  position: absolute;
  right: 0;
  bottom: 0;
  height: 100%; }

.home-banner-bg__right {
  position: relative;
  overflow: hidden;
  min-height: 820px; }
  @media only screen and (max-width: 991px) {
    .home-banner-bg__right {
      display: none; } }
  .home-banner-bg__right .vid-overlay {
    background: rgba(8, 172, 231, 0.57);
    position: absolute;
    right: 0;
    bottom: 0;
    min-width: 100%;
    min-height: 100%;
    z-index: 2; }

.home-banner-container {
  position: relative;
  z-index: 0; }
  .home-banner-container a {
    text-decoration: none; }
  @media only screen and (max-width: 991px) {
    .home-banner-container {
      z-index: 1;
      position: relative; } }

.home-banner-content {
  display: grid;
  grid-template-columns: 7fr 5fr;
  margin-top: 105px;
  align-items: end; }
  @media only screen and (max-width: 991px) {
    .home-banner-content {
      display: block; } }

.home-banner-content-wrapper {
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 4; }
  @media only screen and (max-width: 991px) {
    .home-banner-content-wrapper {
      position: static;
      background: #08abe6;
      padding-bottom: 150px; } }

.home-banner-content__left-content {
  text-align: center;
  padding: 30px 40px;
  padding-bottom: 20px;
  background: white;
  box-shadow: 2px 3px 12px 0px rgba(1, 1, 1, 0.09);
  -webkit-box-shadow: 2px 3px 12px 0px rgba(1, 1, 1, 0.09);
  /* Safari 3-4, iOS 4.0.2 - 4.2, Android 2.3+ */
  -moz-box-shadow: 2px 3px 12px 0px rgba(1, 1, 1, 0.09);
  /* Firefox 3.5 - 3.6 */ }
  @media only screen and (max-width: 1030px) and (min-width: 992px) {
    .home-banner-content__left-content {
      padding: 30px 30px; } }
  @media only screen and (max-width: 991px) {
    .home-banner-content__left-content {
      padding: 0;
      background: #08abe6;
      box-shadow: none;
      -webkit-box-shadow: none;
      /* Safari 3-4, iOS 4.0.2 - 4.2, Android 2.3+ */
      -moz-box-shadow: none;
      /* Firefox 3.5 - 3.6 */ } }

@media only screen and (max-width: 449px) {
  .home-banner-content__left-content > div > a:last-child {
    grid-column: 1 / 3; } }

.single-col {
  background-color: #fff;
  padding: 20px 10px; }
  @media only screen and (max-width: 991px) {
    .single-col {
      padding: 35px 10px; } }
  .single-col img {
    max-width: 45px;
    margin-bottom: 8px;
    transform: scale(1);
    transition: all .2s ease-in-out; }
  .single-col:hover img {
    transform: scale(1.1); }
  .single-col:hover {
    background-color: #f9f9f9;
    transition: 0.3s; }

.home-banner-content__left-grid {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  background-color: #ebebeb;
  margin-top: 20px;
  box-shadow: 0px 0px 15px rgba(1, 1, 1, 0.09);
  grid-gap: 3px; }
  .seamless-exp-outer .home-banner-content__left-grid {
    text-align: center; }
  @media only screen and (max-width: 991px) {
    .home-banner-content__left-grid {
      background-color: #08abe6;
      box-shadow: none;
      grid-gap: 3px; } }
  @media only screen and (max-width: 620px) {
    .home-banner-content__left-grid {
      grid-template-columns: repeat(3, 1fr); } }
  @media only screen and (max-width: 449px) {
    .home-banner-content__left-grid {
      grid-template-columns: repeat(2, 1fr); } }
  .home-banner-content__left-grid a {
    background-color: #fff;
    transition: 0.3s; }
    .home-banner-content__left-grid a:hover img {
      transform: scale(1.1); }
    .home-banner-content__left-grid a:hover {
      background-color: #f9f9f9;
      transition: 0.3s; }
  @media only screen and (max-width: 1030px) and (min-width: 992px) {
    .home-banner-content__left-grid {
      grid-auto-rows: 130px; } }

.home-banner-content__right {
  min-width: 0;
  min-height: 0; }

.home-banner-content__right-content {
  padding-bottom: 5px;
  margin-left: 70px;
  overflow: hidden;
  margin-bottom: 65px; }
  @media only screen and (max-width: 991px) {
    .home-banner-content__right-content {
      text-align: center;
      max-width: 500px;
      margin: 0 auto;
      margin-top: 50px; } }

.home-banner-content__right-content .slick-dots li {
  margin: 0 1px; }

.home-banner-content__right-content .slick-dots {
  bottom: -10px;
  text-align: left; }
  @media only screen and (max-width: 991px) {
    .home-banner-content__right-content .slick-dots {
      bottom: 8px;
      text-align: unset; } }

.home-banner-content__right-content .slick-dots li button {
  font-size: 0px;
  line-height: 0;
  display: block;
  width: 20px;
  height: 20px;
  padding: 5px;
  cursor: pointer;
  color: transparent;
  border: 0;
  outline: 0;
  background: 0 0;
  margin: 0; }

.home-banner-content__right-content .slick-dots li button:before {
  font-size: 0px;
  line-height: 20px;
  position: absolute;
  top: 0;
  left: 0;
  width: 12px;
  height: 12px;
  content: '•';
  text-align: center;
  opacity: .5;
  color: #fff;
  background: white;
  border-radius: 71%; }

.home-banner-content__right-content .slick-dots li.slick-active button:before {
  opacity: 1; }

#nounderline {
  color: #000;
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  font-weight: normal; }
  @media all and (max-width: 991px) {
    #nounderline {
      display: none; } }
  #nounderline a {
    color: #08abe6;
    font-weight: 600; }
  .home-banner-content__left-content #nounderline {
    margin: 20px 0 0; }
    .home-banner-content__left-content #nounderline #underline {
      text-decoration: underline;
      padding: 0 2px; }

@media only screen and (max-width: 991px) {
  .home-banner-content__left-content .sub-heading {
    display: none; } }

.download_chrome {
  display: inline-block;
  margin-top: 30px;
  background: white;
  border-radius: 56px;
  padding: 6px 25px;
  padding-right: 40px;
  transition: 0.3s;
  cursor: pointer; }
  @media only screen and (max-width: 991px) {
    .download_chrome {
      display: none; } }
  .download_chrome a {
    display: flex; }
    .download_chrome a img {
      width: 25px;
      height: 25px; }
  .download_chrome:hover {
    background: #e7e7e7;
    transition: 0.3s; }

.home-page {
  position: relative;
  z-index: 2; }

.donation-container {
  display: grid;
  grid-template-columns: 5fr 7fr; }
  @media only screen and (max-width: 991px) {
    .donation-container {
      display: block; } }

.donation__left {
  display: grid;
  grid-template-columns: auto minmax(auto, 450px);
  background: white;
  padding: 80px 0 60px;
  padding-left: 20px; }
  @media only screen and (max-width: 991px) {
    .donation__left {
      display: block;
      max-width: 70%;
      margin: 0 auto;
      margin-top: -130px;
      z-index: 2;
      position: relative;
      padding: 40px 50px;
      -webkit-box-shadow: 0px 1px 13px rgba(0, 0, 0, 0.09);
      -moz-box-shadow: 0px 1px 13px rgba(0, 0, 0, 0.09);
      box-shadow: 0px 1px 13px rgba(0, 0, 0, 0.09); } }
  @media only screen and (max-width: 620px) {
    .donation__left {
      margin: 0 20px;
      max-width: unset;
      margin-top: -130px; } }
  @media only screen and (max-width: 449px) {
    .donation__left {
      margin: 0 10px;
      padding: 30px 25px;
      margin-top: -130px; } }
  @media only screen and (min-width: 1300px) {
    .donation__left {
      grid-template-columns: auto minmax(auto, 500px); } }
  .donation__left .price-tag {
    color: #ef4800;
    font-weight: 600; }
  .donation__left ul li {
    margin-bottom: 20px;
    letter-spacing: 0; }

.donation__right {
  min-width: 0;
  min-height: 0;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  margin-top: -100px;
  z-index: 20;
  grid-auto-rows: 270px; }
  @media only screen and (max-width: 991px) {
    .donation__right {
      margin: 0;
      z-index: 1;
      position: relative;
      margin-top: -50px;
      grid-template-columns: repeat(4, 1fr) !important;
      grid-auto-rows: 200px !important; } }
  @media only screen and (max-width: 620px) {
    .donation__right {
      margin-top: 20px;
      grid-template-columns: repeat(3, 1fr) !important; } }
  @media only screen and (max-width: 450px) {
    .donation__right {
      grid-auto-rows: 120px !important; } }
  .donation__right img {
    vertical-align: middle;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
    -webkit-filter: grayscale(100%);
    filter: grayscale(100%);
    transform: scale(1);
    transition: all .5s ease-in-out; }
  @media only screen and (max-width: 1371px) and (min-width: 992px) {
    .donation__right .donation_right--single-col:nth-of-type(12), .donation__right .donation_right--single-col:nth-of-type(11), .donation__right .donation_right--single-col:nth-of-type(10) {
      display: none; } }
  @media only screen and (max-width: 1099px) and (min-width: 992px) {
    .donation__right {
      grid-template-columns: repeat(auto-fit, minmax(190px, 1fr)) !important;
      grid-auto-rows: 255px !important;
      margin-top: -75px; } }
  @media only screen and (max-width: 1714px) {
    .donation__right {
      grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
      grid-auto-rows: 263px; } }
  .donation__right .donation__right-img {
    height: 100%;
    overflow: hidden; }

.donation_right--single-col {
  position: relative; }
  .donation_right--single-col .donation__right-img-details {
    position: absolute;
    right: 0;
    bottom: 0;
    min-width: 100%;
    min-height: 100%;
    background: rgba(8, 172, 231, 0.57);
    transition: opacity .4s ease-out;
    opacity: 0;
    height: 0;
    overflow: hidden; }
  .donation_right--single-col :hover .donation__right-img-details {
    opacity: 1;
    height: auto; }
  .donation_right--single-col:hover .donation__right-img img {
    transform: scale(1.1); }

.join-community {
  display: grid;
  grid-template-columns: 5fr 1fr 8.4fr;
  grid-template-rows: auto 1fr auto; }
  @media only screen and (max-width: 991px) {
    .join-community {
      display: block; } }

.join-community__left-content h3 {
  font-weight: 100; }
  @media only screen and (max-width: 991px) {
    .join-community__left-content h3 {
      text-align: center !important;
      max-width: 390px;
      margin: 0 auto; } }
  @media only screen and (max-width: 991px) {
    .join-community__left-content h3:after {
      width: 60%;
      margin-left: 30%; } }

.join-community__left {
  display: grid;
  grid-template-columns: auto minmax(auto, 450px);
  background-color: #08abe6;
  grid-column: 1 / span 2;
  grid-row: 1 / 2;
  padding: 150px 20px;
  padding-right: 0; }
  @media only screen and (max-width: 991px) {
    .join-community__left {
      display: block;
      text-align: center;
      padding: 60px 20px 115px; } }
  @media only screen and (min-width: 1300px) {
    .join-community__left {
      grid-template-columns: auto minmax(auto, 500px); } }

.join-community__right {
  display: grid;
  grid-template-columns: minmax(auto, 820px) auto;
  background-color: #F7F7F7;
  grid-column: 3 / 4;
  grid-row: 1 / 3; }
  @media only screen and (max-width: 991px) {
    .join-community__right {
      display: block; } }
  .payment__Right .join-community__right {
    grid-column: auto;
    grid-row: auto;
    background: white;
    height: auto;
    margin-top: 160px;
    padding-bottom: 40px; }
    @media only screen and (max-width: 991px) {
      .payment__Right .join-community__right {
        display: block; } }

.join-community__right-content {
  text-align: center;
  padding: 0 100px; }
  @media only screen and (max-width: 620px) {
    .join-community .join-community__right-content {
      padding: 0 10px; } }
  .payment__Right .join-community__right-content {
    padding: 0 30px; }
    @media only screen and (max-width: 620px) {
      .payment__Right .join-community__right-content {
        padding: 0 40px; } }
    @media only screen and (max-width: 449px) {
      .payment__Right .join-community__right-content {
        padding: 0 20px; } }

.counter-img {
  background-image: url("../images/Home.png");
  grid-column: 1 / 3;
  grid-row: 2 / 4;
  z-index: 1;
  background-size: cover;
  background-position: center; }
  @media only screen and (max-width: 991px) {
    .counter-img {
      width: 100%;
      height: 500px; } }

.counter-data {
  grid-column: 2 / 4;
  grid-row: 3 / 4;
  background: #08abe6;
  min-height: 32px;
  z-index: 2;
  display: grid;
  grid-template-columns: minmax(auto, 900px) auto;
  padding: 55px 55px 65px; }
  @media only screen and (max-width: 991px) {
    .counter-data {
      display: block;
      max-width: 80%;
      margin: 0 auto;
      margin-top: -200px; } }
  @media only screen and (max-width: 620px) {
    .counter-data {
      max-width: unset;
      margin: 0 20px;
      margin-top: -200px; } }

.mockIphone img {
  width: 320px;
  margin-top: -100px; }
  @media only screen and (max-width: 620px) {
    .mockIphone img {
      width: auto; } }
  .payment__Right .mockIphone img {
    width: 100%;
    max-width: 200px; }
    @media only screen and (max-width: 620px) {
      .payment__Right .mockIphone img {
        width: auto; } }

.store-icon {
  margin: 20px 20px 50px;
  display: inline-block; }
  @media only screen and (max-width: 450px) {
    .join-community .store-icon, .drink-donate__news-feed .store-icon {
      margin: 20px 10px 30px;
      max-width: 120px; } }
  .payment__Right .store-icon {
    width: 100%;
    max-width: 130px;
    margin: 0 auto;
    margin-top: 20px; }

.store-icons-container {
  display: grid;
  grid-template-columns: 1fr 1fr; }
  @media only screen and (max-width: 450px) {
    .payment__Right .store-icons-container {
      margin: 20px 0px 0; } }

.counter-data__container {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr; }
  @media only screen and (max-width: 991px) {
    .counter-data__container {
      grid-template-columns: 1fr 1fr;
      grid-row-gap: 40px; } }
  @media only screen and (max-width: 449px) {
    .counter-data__container {
      grid-template-columns: 1fr;
      grid-row-gap: 40px; } }

.homePage-bottomGrid__grid {
  display: grid;
  grid-template-columns: 6fr 1fr 7.4fr;
  grid-template-rows: auto auto 100px auto; }
  @media only screen and (max-width: 991px) {
    .homePage-bottomGrid__grid {
      display: block; } }
  .homePage-bottomGrid__grid img {
    vertical-align: middle; }

@media all and (max-width: 400px) {
  .heading-top-line {
    font-size: 21px !important; } }

.homePage-bottomGrid__grid-1 {
  grid-column: 1 / 2;
  display: grid;
  grid-template-columns: auto minmax(auto, 450px);
  background-color: #F7F7F7;
  align-items: center;
  padding-left: 20px; }
  @media only screen and (max-width: 991px) {
    .homePage-bottomGrid__grid-1 {
      display: block;
      background-color: #fff;
      max-width: 80%;
      margin: 0 auto;
      padding: 65px 0px 40px; } }
  @media only screen and (max-width: 620px) {
    .homePage-bottomGrid__grid-1 {
      max-width: unset;
      margin: 0 20px; } }
  .homePage-bottomGrid__grid-1 p {
    color: #000;
    font-size: 18px;
    line-height: 25px;
    display: none; }
    @media only screen and (max-width: 991px) {
      .homePage-bottomGrid__grid-1 p {
        display: block; } }
  @media only screen and (min-width: 1300px) {
    .homePage-bottomGrid__grid-1 {
      grid-template-columns: auto minmax(auto, 500px); } }

.homePage-bottomGrid__grid-2 {
  grid-column: 2 / 4; }
  .homePage-bottomGrid__grid-2 .homePage-bottomGrid__img-wrapper > div {
    overflow: hidden; }
    .homePage-bottomGrid__grid-2 .homePage-bottomGrid__img-wrapper > div img {
      -webkit-filter: grayscale(100%);
      filter: grayscale(100%);
      -webkit-transform: scale(1);
      transform: scale(1);
      -webkit-transition: all .3s ease-in-out;
      transition: all .3s ease-in-out; }
    .homePage-bottomGrid__grid-2 .homePage-bottomGrid__img-wrapper > div:hover img {
      -webkit-transform: scale(1.1);
      transform: scale(1.1); }
  @media only screen and (max-width: 991px) {
    .homePage-bottomGrid__grid-2 {
      margin-bottom: -50px;
      z-index: 1;
      position: relative; } }

.homePage-bottomGrid__grid-3 {
  grid-column: 1 / 2;
  display: grid;
  grid-template-columns: auto minmax(auto, 450px);
  background-color: #F7F7F7; }
  .homePage-bottomGrid__grid-3 .homePage-bottomGrid__img-wrapper > div {
    overflow: hidden; }
    .homePage-bottomGrid__grid-3 .homePage-bottomGrid__img-wrapper > div img {
      -webkit-filter: grayscale(100%);
      filter: grayscale(100%);
      -webkit-transform: scale(1);
      transform: scale(1);
      -webkit-transition: all .3s ease-in-out;
      transition: all .3s ease-in-out; }
    .homePage-bottomGrid__grid-3 .homePage-bottomGrid__img-wrapper > div:hover img {
      -webkit-transform: scale(1.1);
      transform: scale(1.1); }
  @media only screen and (max-width: 991px) {
    .homePage-bottomGrid__grid-3 {
      display: none; } }
  @media only screen and (min-width: 1300px) {
    .homePage-bottomGrid__grid-3 {
      grid-template-columns: auto minmax(auto, 500px); } }

.homePage-bottomGrid__grid-6 {
  overflow: hidden; }
  .homePage-bottomGrid__grid-6 img {
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-transition: all .3s ease-in-out;
    transition: all .3s ease-in-out; }
  .homePage-bottomGrid__grid-6:hover img {
    -webkit-transform: scale(1.05);
    transform: scale(1.05); }

.homePage-bottomGrid__gallery-wrapper {
  overflow: hidden; }
  .homePage-bottomGrid__gallery-wrapper img {
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-transition: all .3s ease-in-out;
    transition: all .3s ease-in-out; }
  .homePage-bottomGrid__gallery-wrapper:hover img {
    -webkit-transform: scale(1.05);
    transform: scale(1.05); }

.homePage-bottomGrid__grid-4 {
  background-color: #F7F7F7;
  grid-column: 2 / 4;
  grid-row: 2 / 4;
  display: grid;
  grid-template-columns: minmax(auto, 820px) auto; }
  @media only screen and (max-width: 991px) {
    .homePage-bottomGrid__grid-4 {
      display: none; } }

.homePage-bottomGrid__img-wrapper {
  display: grid; }
  .homePage-bottomGrid__img-wrapper img {
    width: 100%;
    object-fit: cover;
    object-position: center; }
    @media only screen and (max-width: 991px) {
      .homePage-bottomGrid__img-wrapper img {
        height: 250px; } }
  .homePage-bottomGrid__grid-3 .homePage-bottomGrid__img-wrapper {
    grid-template-columns: 1fr 1fr; }
  .homePage-bottomGrid__grid-2 .homePage-bottomGrid__img-wrapper {
    grid-template-columns: 1fr 1fr 1fr 1fr; }
    @media only screen and (max-width: 1300px) {
      .homePage-bottomGrid__grid-2 .homePage-bottomGrid__img-wrapper {
        grid-template-columns: 1fr 1fr 1fr; } }
    @media only screen and (max-width: 1300px) {
      .homePage-bottomGrid__grid-2 .homePage-bottomGrid__img-wrapper div:nth-child(4) {
        display: none; } }

.homePage-bottomGrid__grid-5 {
  grid-column: 1 / 3;
  background: #08abe6;
  display: grid;
  grid-template-columns: auto minmax(auto, 630px);
  grid-row: 3 / 5;
  align-items: center;
  padding-left: 20px; }
  @media only screen and (max-width: 991px) {
    .homePage-bottomGrid__grid-5 {
      display: block;
      max-width: 80%;
      margin: 0 auto;
      padding: 60px 35px 35px;
      z-index: 2;
      position: relative; } }
  @media only screen and (max-width: 620px) {
    .homePage-bottomGrid__grid-5 {
      max-width: unset;
      margin: 0 20px; } }
  @media only screen and (max-width: 1300px) {
    .homePage-bottomGrid__grid-5 {
      grid-template-columns: auto minmax(auto, 530px); } }

.homePage-bottomGrid__grid-6 {
  grid-column: 3 /4;
  position: relative; }
  @media only screen and (max-width: 991px) {
    .homePage-bottomGrid__grid-6 {
      display: none; } }
  .homePage-bottomGrid__grid-6 img {
    width: 100%;
    max-height: 260px;
    object-position: center;
    object-fit: cover; }

.homePage-bottomGrid__country {
  position: absolute;
  bottom: 30px;
  left: 30px;
  font-family: "Roboto", sans-serif;
  font-weight: 600;
  font-size: 22px;
  color: #fff; }
  .homePage-bottomGrid__gallery-wrapper .homePage-bottomGrid__country {
    bottom: 50px;
    right: 40px;
    left: auto; }

.homePage-bottomGrid__gallery > div:nth-child(4) {
  display: none; }
  @media only screen and (max-width: 991px) {
    .homePage-bottomGrid__gallery > div:nth-child(4) {
      display: block; } }

.homePage-bottomGrid__gallery {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr; }
  @media only screen and (max-width: 991px) {
    .homePage-bottomGrid__gallery {
      grid-template-columns: 1fr 1fr;
      margin-top: -35px; } }
  @media only screen and (max-width: 480px) {
    .homePage-bottomGrid__gallery {
      display: none; } }
  .homePage-bottomGrid__gallery img {
    width: 100%;
    max-height: 260px;
    object-position: center;
    object-fit: cover;
    vertical-align: middle;
    height: 100%; }
  .homePage-bottomGrid__gallery .homePage-bottomGrid__gallery-wrapper {
    position: relative; }

.slick-slide {
  /* ... */
  position: relative; }

.home-mobile-slider {
  overflow: hidden;
  display: none;
  position: relative;
  top: -20px; }
  .home-mobile-slider .homePage-bottomGrid__gallery-wrapper1 img {
    width: 100%;
    border-left: 8px solid white;
    border-right: 8px solid white;
    box-sizing: border-box; }
  .home-mobile-slider .homePage-bottomGrid__gallery-wrapper1 .homePage-bottomGrid__country1 {
    position: absolute;
    bottom: 40px;
    right: 30px;
    font-family: "Roboto", sans-serif;
    font-weight: 600;
    font-size: 18px;
    color: #fff; }
    @media all and (max-width: 380px) {
      .home-mobile-slider .homePage-bottomGrid__gallery-wrapper1 .homePage-bottomGrid__country1 {
        bottom: 20px;
        font-size: 15px; } }
  @media screen and (max-width: 480px) {
    .home-mobile-slider {
      display: block; } }
  @media screen and (max-width: 405px) {
    .home-mobile-slider {
      top: -10px; } }

.news-feed__grid {
  display: grid;
  grid-template-columns: 5fr 7fr; }
  @media only screen and (max-width: 991px) {
    .news-feed__grid {
      display: block; } }

@media only screen and (max-width: 450px) {
  .news-feed__grid-dropdown p {
    padding: 6px 15px;
    display: block;
    float: none; } }

.news-feed__grid-left {
  background-color: #F7F7F7;
  display: grid;
  grid-template-columns: auto minmax(auto, 450px);
  padding: 80px 0 90px; }
  .drink-donate__news-feed .news-feed__grid-left {
    background-color: #fff;
    grid-template-columns: auto minmax(auto, 590px); }
  @media only screen and (max-width: 3600px) and (min-width: 3401px) {
    .news-feed__grid-left {
      grid-template-columns: auto minmax(auto, 305px) !important; } }
  @media only screen and (max-width: 3400px) and (min-width: 3101px) {
    .news-feed__grid-left {
      grid-template-columns: auto minmax(auto, 335px) !important; } }
  @media only screen and (max-width: 3100px) and (min-width: 2901px) {
    .news-feed__grid-left {
      grid-template-columns: auto minmax(auto, 355px) !important; } }
  @media only screen and (max-width: 2900px) and (min-width: 2701px) {
    .news-feed__grid-left {
      grid-template-columns: auto minmax(auto, 355px) !important; } }
  @media only screen and (max-width: 2700px) and (min-width: 2401px) {
    .news-feed__grid-left {
      grid-template-columns: auto minmax(auto, 385px) !important; } }
  @media only screen and (max-width: 2400px) and (min-width: 2201px) {
    .news-feed__grid-left {
      grid-template-columns: auto minmax(auto, 405px) !important; } }
  @media only screen and (max-width: 2200px) and (min-width: 2001px) {
    .news-feed__grid-left {
      grid-template-columns: auto minmax(auto, 425px) !important; } }
  @media only screen and (max-width: 2000px) and (min-width: 1951px) {
    .news-feed__grid-left {
      grid-template-columns: auto minmax(auto, 435px) !important; } }
  @media only screen and (max-width: 1950px) and (min-width: 1901px) {
    .news-feed__grid-left {
      grid-template-columns: auto minmax(auto, 440px) !important; } }
  @media only screen and (max-width: 1900px) and (min-width: 1851px) {
    .news-feed__grid-left {
      grid-template-columns: auto minmax(auto, 445px) !important; } }
  @media only screen and (max-width: 1850px) and (min-width: 1801px) {
    .news-feed__grid-left {
      grid-template-columns: auto minmax(auto, 450px) !important; } }
  @media only screen and (max-width: 1800px) and (min-width: 1751px) {
    .news-feed__grid-left {
      grid-template-columns: auto minmax(auto, 450px) !important; } }
  @media only screen and (max-width: 1750px) and (min-width: 1701px) {
    .news-feed__grid-left {
      grid-template-columns: auto minmax(auto, 450px) !important; } }
  @media only screen and (max-width: 1700px) and (min-width: 1651px) {
    .news-feed__grid-left {
      grid-template-columns: auto minmax(auto, 465px) !important; } }
  @media only screen and (max-width: 1650px) and (min-width: 1601px) {
    .news-feed__grid-left {
      grid-template-columns: auto minmax(auto, 465px) !important; } }
  @media only screen and (max-width: 1600px) and (min-width: 1551px) {
    .news-feed__grid-left {
      grid-template-columns: auto minmax(auto, 465px) !important; } }
  @media only screen and (max-width: 1550px) and (min-width: 1501px) {
    .news-feed__grid-left {
      grid-template-columns: auto minmax(auto, 465px) !important; } }
  @media only screen and (max-width: 1500px) and (min-width: 1400px) {
    .news-feed__grid-left {
      grid-template-columns: auto minmax(auto, 480px) !important; } }
  @media only screen and (max-width: 1400px) and (min-width: 1300px) {
    .news-feed__grid-left {
      grid-template-columns: auto minmax(auto, 480px) !important; } }
  @media only screen and (max-width: 991px) {
    .news-feed__grid-left {
      display: block;
      padding: 70px 0 35px;
      padding-left: 50px;
      background-color: #fff; } }
  @media only screen and (max-width: 450px) {
    .news-feed__grid-left {
      padding: 40px 0 15px;
      padding-left: 20px; } }
  @media only screen and (min-width: 1300px) {
    .news-feed__grid-left {
      grid-template-columns: auto minmax(auto, 500px); } }
  .about-outer-container .news-feed__grid-left {
    background: #fff; }
    @media all and (max-width: 767px) {
      .about-outer-container .news-feed__grid-left {
        padding-left: 20px;
        padding-top: 20px; } }
  @media only screen and (max-width: 450px) {
    .drink-donate__news-feed .news-feed__grid-left {
      padding-top: 0; } }

.news-feed__grid-right {
  background-image: url("../images/news.png");
  min-width: 0;
  min-height: 0;
  background-size: 400px;
  background-repeat: no-repeat;
  background-position: 50% 77%; }
  @media only screen and (max-width: 991px) {
    .news-feed__grid-right {
      padding-bottom: 55px;
      background-position: 50% 92%; } }
  @media only screen and (max-width: 400px) {
    .news-feed__grid-right {
      background-size: 300px; } }
  .news-feed__grid-right .slick-prev::before, .news-feed__grid-right .slick-next::before {
    content: " "; }
  .news-feed__grid-right .slick-prev {
    left: 30px; }
  .news-feed__grid-right .slick-next {
    right: 65px; }
  .news-feed__grid-right .slick-next, .news-feed__grid-right .slick-prev {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    -webkit-box-shadow: 0px 5px 35px rgba(1, 1, 1, 0.09);
    /* Safari 3-4, iOS 4.0.2 - 4.2, Android 2.3+ */
    -moz-box-shadow: 0px 5px 35px rgba(1, 1, 1, 0.09);
    /* Firefox 3.5 - 3.6 */
    box-shadow: 0px 5px 35px rgba(1, 1, 1, 0.09);
    padding: 20px;
    background-size: 22px;
    background-position: center;
    background-repeat: no-repeat;
    background-color: #fff; }
  .news-feed__grid-right .slick-prev {
    background-image: url("../images/long-arrow-pointing-to-left.svg"); }
    @media only screen and (max-width: 991px) {
      .news-feed__grid-right .slick-prev {
        display: none !important; } }
  .news-feed__grid-right .slick-next {
    background-image: url("../images/long-arrow-pointing-to-the-right.svg"); }
    @media only screen and (max-width: 991px) {
      .news-feed__grid-right .slick-next {
        display: none !important; } }
  .news-feed__grid-right .slick-slider {
    padding: 80px 0px 50px 80px; }
    @media only screen and (max-width: 991px) {
      .news-feed__grid-right .slick-slider {
        padding: 0px 0px 50px 50px; } }
    @media only screen and (max-width: 450px) {
      .news-feed__grid-right .slick-slider {
        padding: 0px 0px 50px 20px; } }
    @media all and (max-width: 767px) {
      .about-outer-container .news-feed__grid-right .slick-slider {
        padding-left: 20px; } }
  .news-feed__grid-right .slick-list {
    position: relative; }
    .news-feed__grid-right .slick-list:after {
      content: "";
      background: #FAFAFA;
      position: absolute;
      top: 0;
      height: 100%;
      width: 40%;
      z-index: 100; }
    .news-feed__grid-right .slick-list:after {
      right: -2px;
      background: linear-gradient(to right, rgba(255, 255, 255, 0) 0%, white 100%); }

.news-single__wrapper:focus {
  outline: none; }

.news-single__wrapper .news-single__content {
  margin: 8px;
  background: white;
  margin-bottom: 13px;
  -webkit-box-shadow: 0px 5px 19px rgba(0, 0, 0, 0.05);
  -moz-box-shadow: 0px 5px 19px rgba(0, 0, 0, 0.05);
  box-shadow: 0px 5px 19px rgba(0, 0, 0, 0.05); }
  .news-single__wrapper .news-single__content .details {
    padding: 15px;
    min-height: 125px; }

.news-single__wrapper img {
  width: 100%;
  height: 180px;
  object-fit: cover;
  object-position: center; }

.blue-text {
  color: #08abe6; }

#donate_form .select-content-outer {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-auto-rows: 45px;
  grid-gap: 20px;
  max-width: 500px; }

#donate_form .input-text-outer {
  margin: 0; }
  #donate_form .input-text-outer input {
    height: 100%;
    box-shadow: none; }

.payment {
  padding-top: 160px;
  background-size: 100%;
  background-position: -2px;
  background-image: url("../images/bg2.jpg");
  font-family: "Roboto", sans-serif; }
  .payment h1 {
    margin-top: 0; }
  @media all and (max-width: 991px) {
    .payment {
      padding-top: 125px; } }

.payment__Left {
  padding: 50px;
  padding-top: 40px;
  background: white;
  margin-bottom: 50px; }
  @media all and (max-width: 1060px) {
    .payment__Left {
      padding: 25px; } }
  @media all and (max-width: 991px) {
    .payment__Left {
      margin-bottom: 15px; } }

.reciept-msg {
  display: grid;
  grid-template-columns: 1fr 4fr;
  margin-top: 10px; }
  @media all and (max-width: 479px) {
    .reciept-msg {
      display: block;
      margin-top: 5px; } }

.your-info-container {
  padding-top: 30px; }
  @media all and (max-width: 479px) {
    .your-info-container {
      clear: both;
      overflow-y: auto;
      overflow-x: visible; } }

.payment-details-container {
  margin-top: 60px; }

.card-icons-outer {
  display: grid;
  grid-template-columns: 1fr 4fr;
  margin-top: 10px; }
  @media all and (max-width: 479px) {
    .card-icons-outer {
      display: block;
      margin-top: 5px; } }
  .card-icons-outer .icon-outer {
    width: 140px; }
    @media all and (max-width: 479px) {
      .card-icons-outer .icon-outer {
        width: 130px; }
        .card-icons-outer .icon-outer img {
          width: 100%; } }

.State-zip-code .input-text-outer {
  width: 200px; }

.agree-terms p {
  margin-top: 40px;
  font-size: 14px;
  color: #a2a2a2; }
  .agree-terms p a {
    text-decoration: underline;
    color: #a2a2a2; }

@media all and (max-width: 479px) {
  .amount-submit-container {
    margin-top: 30px; } }

.amount-submit-container .label, .amount-submit-container .price {
  line-height: 35px;
  display: inline-block; }
  @media all and (max-width: 479px) {
    .amount-submit-container .label, .amount-submit-container .price {
      float: left; } }

.amount-submit-container .label {
  padding-right: 10px; }
  @media all and (max-width: 479px) {
    .amount-submit-container .label {
      padding-right: 5px;
      float: left; } }

.amount-submit-container .price {
  color: #08abe6;
  font-size: 25px; }

.questions_container {
  background: white;
  width: 100%;
  overflow: auto;
  padding: 0px 50px;
  box-sizing: border-box;
  padding-bottom: 30px;
  padding-top: 10px; }
  @media all and (max-width: 1060px) {
    .questions_container {
      padding: 0px 25px;
      padding-bottom: 30px; } }

.StripeElement {
  height: max-content; }

@media all and (max-width: 479px) {
  .StripeCvv .StripeElement {
    float: left;
    width: 82%; } }

.payment-content {
  display: grid;
  grid-template-columns: 2fr 1fr;
  grid-column-gap: 50px; }
  @media all and (max-width: 1060px) {
    .payment-content {
      grid-column-gap: 20px; } }
  @media all and (max-width: 991px) {
    .payment-content {
      display: block; } }

@media all and (max-width: 991px) {
  .payment__Right {
    margin-bottom: 25px; } }

.label-input-pair-row {
  display: grid;
  margin-top: 25px; }
  @media all and (max-width: 479px) {
    .label-input-pair-row {
      display: block;
      margin-top: 5px; } }
  .label-input-pair-row .label {
    line-height: 35px; }
  .label-input-pair-row.State-zip-code .label {
    width: 16%; }
    @media all and (max-width: 479px) {
      .label-input-pair-row.State-zip-code .label {
        width: 100%; } }
  .label-input-pair-row.column-3 {
    grid-template-columns: 1fr 2fr 2fr; }
  .label-input-pair-row.column-2 {
    grid-template-columns: 1fr 4fr; }
  .label-input-pair-row.StripeElement-card-outer {
    grid-template-columns: 1.3fr 5fr; }
  .label-input-pair-row.StripeExpiryDate {
    grid-template-columns: 0.2fr 0.3fr; }
  .label-input-pair-row.StripeCvv {
    grid-template-columns: 0.82fr 1.3fr 2fr; }
  .label-input-pair-row.State-zip-code {
    display: flex; }
    @media all and (max-width: 479px) {
      .label-input-pair-row.State-zip-code {
        display: block; } }

.label-input-pair {
  display: grid;
  grid-template-columns: 0.2fr 0.3fr;
  margin-top: 25px; }
  @media all and (max-width: 479px) {
    .label-input-pair {
      display: block;
      margin-top: 5px; } }
  .label-input-pair .StripeElement {
    width: 200px; }
    .label-input-pair .StripeElement iframe {
      height: 30px !important; }
      .label-input-pair .StripeElement iframe input[type="tel"] {
        height: 30px !important;
        padding: 10px; }
    @media all and (max-width: 479px) {
      .StripeCvv .label-input-pair .StripeElement {
        float: left;
        width: 83%; } }

.label-selectbox-pair {
  display: grid;
  grid-template-columns: 1fr 4fr;
  margin-top: 25px; }
  @media all and (max-width: 479px) {
    .label-selectbox-pair {
      display: block;
      margin-top: 5px; } }
  .label-selectbox-pair.birthday-outer {
    display: block;
    overflow: auto; }
    @media all and (max-width: 479px) {
      .label-selectbox-pair.birthday-outer {
        float: left;
        width: 100%;
        margin-top: 5px; } }
    .label-selectbox-pair.birthday-outer .label {
      float: left;
      width: 20%; }
  .label-selectbox-pair .label {
    line-height: 45px;
    color: #000;
    font-size: 14px; }
    @media all and (max-width: 479px) {
      .label-selectbox-pair .label {
        float: left;
        width: 100% !important; } }
  .label-selectbox-pair.column-4 {
    grid-template-columns: 0.2fr 0.2fr 0.2fr 0.2fr; }

.StripeElement {
  display: block;
  margin: 0;
  max-width: 500px;
  padding: 10px 14px;
  font-size: 1em;
  font-family: 'Source Code Pro', monospace;
  border: solid 1px #afa9a9;
  outline: 0;
  border-radius: 4px;
  background: white; }

.StripeElement--focus {
  -webkit-transition: all 150ms ease;
  transition: all 150ms ease; }

.StripeElement.IdealBankElement,
.StripeElement.PaymentRequestButton {
  padding: 0; }

.StripeElement {
  display: block;
  margin: 0;
  max-width: 500px;
  padding: 10px 14px;
  font-size: 1em;
  font-family: 'Source Code Pro', monospace;
  border: solid 1px #afa9a9;
  outline: 0;
  border-radius: 4px;
  background: white; }

.StripeElement--focus {
  -webkit-transition: all 150ms ease;
  transition: all 150ms ease; }

.StripeElement.IdealBankElement,
.StripeElement.PaymentRequestButton {
  padding: 0; }

.news-modal-content, .faq__top-banner-wrap {
  text-align: center; }
  .news-modal-content h2, .faq__top-banner-wrap h2 {
    font-weight: 400;
    font-size: 45px;
    font-family: "Roboto", sans-serif; }
    .news-modal-content h2 span, .faq__top-banner-wrap h2 span {
      font-weight: 700; }
  .news-modal-content p, .faq__top-banner-wrap p {
    width: 60%;
    margin: 0 auto; }

.Modal {
  position: absolute;
  top: 50%;
  left: 50%;
  right: auto;
  bottom: auto;
  border: 1px solid #cccccc;
  background-image: url("../images/pop_up_bg.jpg");
  background-repeat: no-repeat;
  /* Do not repeat the image */
  background-size: cover;
  /* Resize the background image to cover the entire container */
  overflow: auto;
  border: none;
  border-radius: 0;
  box-shadow: 6px 5px 30px rgba(0, 0, 0, 0.361);
  outline: none;
  padding: 100px 20px;
  margin-right: -50%;
  transform: translate(-50%, -50%);
  z-index: 4; }
  @media all and (max-width: 991px) {
    .Modal {
      padding: 60px 20px;
      left: 50%;
      right: 10%; } }
  @media all and (max-width: 767px) {
    .Modal {
      padding: 40px 20px; } }
  .Modal .close-btn {
    position: absolute;
    top: 20px;
    right: 20px;
    font-size: 30px;
    font-weight: 500;
    color: whitesmoke;
    margin: 0;
    cursor: pointer; }
    .Modal .close-btn:focus {
      outline: none; }
  .Modal .news-modal-content {
    text-align: center; }
    .Modal .news-modal-content h2 {
      font-weight: 400;
      font-size: 45px;
      font-family: "Roboto", sans-serif; }
      @media all and (max-width: 767px) {
        .Modal .news-modal-content h2 {
          font-size: 30px; } }
      .Modal .news-modal-content h2 span {
        font-weight: 700; }
    .Modal .news-modal-content p {
      width: 60%;
      margin: 0 auto; }

.Overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 1;
  z-index: 9999;
  background-color: rgba(0, 0, 0, 0.81); }

.top-banner__wrap {
  background-image: url("../../assets/images/about-us-top-bg.jpg");
  padding: 170px 0px 140px 0px;
  text-align: center;
  color: white;
  background-size: cover;
  background-position: 0px 80%; }

.content_block2 {
  margin-top: -100px !important;
  overflow: auto; }
  .content_block2 .img-content {
    width: 270px;
    height: 320px;
    position: relative;
    text-align: center;
    float: right; }
    @media all and (max-width: 767px) {
      .content_block2 .img-content {
        float: none;
        margin: 0 auto; } }
    .content_block2 .img-content img {
      width: 100%;
      height: 100%;
      object-fit: cover; }
    .content_block2 .img-content .content-outer {
      position: absolute;
      bottom: 10px;
      width: 100%; }
      .content_block2 .img-content .content-outer p {
        font-size: 24px; }
  .content_block2 .text-content {
    float: left;
    width: 60%;
    padding-top: 150px; }
    @media all and (max-width: 767px) {
      .content_block2 .text-content {
        width: 100%;
        padding-top: 40px; } }
    .content_block2 .text-content p {
      color: #000; }

.made_a_diff-outer {
  margin-top: 60px;
  clear: both; }
  @media all and (max-width: 767px) {
    .made_a_diff-outer {
      margin-top: 50px; } }

.profiles-outer {
  text-align: left;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  padding-left: 5%;
  margin-top: 50px; }
  @media all and (max-width: 767px) {
    .profiles-outer {
      padding-left: 0;
      margin-top: 0; } }

.profile {
  gap: 30px; }
  .profile img {
    width: 200px;
    object-fit: cover;
    object-position: center;
    height: 210px; }
    @media all and (max-width: 1100px) {
      .profile img {
        width: 100%; } }
  @media all and (max-width: 1100px) {
    .profile {
      margin: 0 20px; } }
  @media all and (max-width: 900px) {
    .profile {
      margin: 0 10px; } }
  .profile .content-outer .name {
    font-size: 16px;
    color: #000;
    font-weight: 100;
    position: relative;
    margin: 25px 0px; }
    .profile .content-outer .name::after {
      content: '';
      width: 160px;
      height: 3px;
      background: #08abe6;
      position: absolute;
      top: -13px;
      border-radius: 10px;
      left: 0; }
  .profile .content-outer p {
    color: #000; }

.seamless-exp-outer {
  background: #ffffff;
  background: -moz-linear-gradient(left, #ffffff 0%, #ffffff 49%, #08abe6 49%);
  background: -webkit-linear-gradient(left, #ffffff 0%, #ffffff 49%, #08abe6 49%);
  background: linear-gradient(to right, #ffffff 0%, #ffffff 49%, #08abe6 49%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#08abe6',GradientType=1 ); }
  @media all and (max-width: 767px) {
    .seamless-exp-outer {
      background: #08abe6; } }
  .seamless-exp-outer .content-outer {
    padding-top: 10%;
    padding-left: 10%; }
    @media all and (max-width: 767px) {
      .seamless-exp-outer .content-outer {
        text-align: center;
        padding-left: 5%;
        padding-right: 5%; } }
    .seamless-exp-outer .content-outer p {
      color: #000; }

.top-banner_contact__wrap {
  background-image: url("../../assets/images/contact-bg-img.png");
  height: 350px;
  padding-top: 15%;
  text-align: center;
  background-size: cover;
  background-position-y: 65%; }
  @media all and (max-width: 479px) {
    .top-banner_contact__wrap {
      height: 200px; } }

@media all and (min-width: 320px) and (max-width: 400px) {
  .recaptcha {
    width: 100px !important; } }

.contact-options {
  background: #ffffffd1;
  width: 420px;
  margin: 0 auto;
  display: grid;
  grid-template-columns: 1fr 1fr;
  padding: 25px; }
  @media all and (max-width: 479px) {
    .contact-options {
      width: 80%;
      padding: 7% 2%; } }
  .contact-options .option h4 {
    color: #08abe6;
    font-size: 14px;
    font-weight: 600; }
  .contact-options .option label {
    font-size: 20px;
    font-weight: 100; }
    @media all and (max-width: 479px) {
      .contact-options .option label {
        font-size: 16px; } }
    .contact-options .option label a {
      color: #4e4e4e; }

.drop-us-outer {
  box-shadow: 2px 3px 12px 0px rgba(1, 1, 1, 0.09);
  -webkit-box-shadow: 2px 3px 12px 0px rgba(1, 1, 1, 0.2);
  -moz-box-shadow: 2px 3px 12px 0px rgba(1, 1, 1, 0.09);
  padding: 30px;
  padding-top: 40px;
  margin-top: 70px; }
  .drop-us-outer .column-3 {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-column-gap: 5%;
    margin-bottom: 20px; }
    @media all and (max-width: 479px) {
      .drop-us-outer .column-3 {
        display: block;
        margin-bottom: 0px; } }

.location-outer {
  position: relative; }
  .location-outer .address-wrap-outer {
    color: #000;
    width: 272px;
    position: absolute;
    background: #fff;
    top: 120px;
    left: 12px;
    padding-left: 40px;
    padding-right: 39px;
    padding-top: 15px;
    padding-bottom: 35px; }
    @media all and (max-width: 479px) {
      .location-outer .address-wrap-outer {
        width: 66%; } }
    .location-outer .address-wrap-outer .address-wrap h2 {
      color: black;
      font-size: 22px;
      font-weight: 500; }
    .location-outer .address-wrap-outer .address-wrap p {
      color: #000;
      font-style: italic; }

.drop-us__message p {
  background: #2ECC71;
  text-align: center;
  padding: 15px;
  border-radius: 4px;
  font-weight: 500;
  text-transform: capitalize; }

.map-outer {
  margin-top: 20px; }
  .map-outer iframe {
    width: 100%;
    height: 500px; }

.contact-outer form {
  margin-top: 50px; }

@media only screen and (max-width: 991px) {
  .contact-outer {
    padding: 0 0 20px 0; } }

.form-textbox-error-outer {
  position: relative; }
  @media only screen and (max-width: 768px) {
    .form-textbox-error-outer {
      margin-bottom: 60px; } }
  .form-textbox-error-outer .errorMsg {
    color: red;
    bottom: 0px;
    top: auto; }
    @media only screen and (max-width: 768px) {
      .form-textbox-error-outer .errorMsg {
        bottom: -25px; } }

.privacy-policy .privacy-policy__background, .privacy-policy .terms-conditions__background, .terms-conditions .privacy-policy__background, .terms-conditions .terms-conditions__background {
  position: relative;
  margin-top: 102px;
  height: 300px; }
  .privacy-policy .privacy-policy__background img, .privacy-policy .terms-conditions__background img, .terms-conditions .privacy-policy__background img, .terms-conditions .terms-conditions__background img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center; }

.privacy-policy .privacy-policy__container, .privacy-policy .terms-conditions__container, .terms-conditions .privacy-policy__container, .terms-conditions .terms-conditions__container {
  background: white;
  margin-top: -180px;
  margin-bottom: 60px;
  padding: 40px 15px;
  position: relative;
  text-align: center;
  z-index: 2;
  box-shadow: 0px 5px 19px rgba(0, 0, 0, 0.05);
  -webkit-box-shadow: 0px 5px 19px rgba(0, 0, 0, 0.05);
  -moz-box-shadow: 0px 5px 19px rgba(0, 0, 0, 0.05); }
  @media only screen and (min-width: 768px) {
    .privacy-policy .privacy-policy__container, .privacy-policy .terms-conditions__container, .terms-conditions .privacy-policy__container, .terms-conditions .terms-conditions__container {
      padding: 60px 30px; } }
  @media only screen and (max-width: 767px) {
    .privacy-policy .privacy-policy__container, .privacy-policy .terms-conditions__container, .terms-conditions .privacy-policy__container, .terms-conditions .terms-conditions__container {
      margin: -180px 15px 30px 15px; } }
  .privacy-policy .privacy-policy__container .privacy-policy__content, .privacy-policy .privacy-policy__container .terms-conditions__content, .privacy-policy .terms-conditions__container .privacy-policy__content, .privacy-policy .terms-conditions__container .terms-conditions__content, .terms-conditions .privacy-policy__container .privacy-policy__content, .terms-conditions .privacy-policy__container .terms-conditions__content, .terms-conditions .terms-conditions__container .privacy-policy__content, .terms-conditions .terms-conditions__container .terms-conditions__content {
    max-width: 800px;
    margin: 0 auto;
    text-align: center; }
    @media only screen and (max-width: 767px) {
      .privacy-policy .privacy-policy__container .privacy-policy__content, .privacy-policy .privacy-policy__container .terms-conditions__content, .privacy-policy .terms-conditions__container .privacy-policy__content, .privacy-policy .terms-conditions__container .terms-conditions__content, .terms-conditions .privacy-policy__container .privacy-policy__content, .terms-conditions .privacy-policy__container .terms-conditions__content, .terms-conditions .terms-conditions__container .privacy-policy__content, .terms-conditions .terms-conditions__container .terms-conditions__content {
        text-align: left; } }
    .privacy-policy .privacy-policy__container .privacy-policy__content .bold, .privacy-policy .privacy-policy__container .terms-conditions__content .bold, .privacy-policy .terms-conditions__container .privacy-policy__content .bold, .privacy-policy .terms-conditions__container .terms-conditions__content .bold, .terms-conditions .privacy-policy__container .privacy-policy__content .bold, .terms-conditions .privacy-policy__container .terms-conditions__content .bold, .terms-conditions .terms-conditions__container .privacy-policy__content .bold, .terms-conditions .terms-conditions__container .terms-conditions__content .bold {
      font-weight: 600; }

.drink-donate__top-banner-wrap {
  background-image: url("../images/drinkdonatebanner.jpg");
  padding: 250px 15px 100px 15px;
  text-align: center;
  color: white;
  background-size: 100%;
  background-size: cover; }
  @media screen and (max-width: 480px) and (min-width: 320px) {
    .drink-donate__top-banner-wrap {
      background-image: url("../images/drinkand donatemob.png");
      padding: 100px 15px 50px 15px !important;
      background-size: cover; } }
  @media all and (max-width: 450px) {
    .drink-donate__top-banner-wrap .heading-top-line {
      width: 235px;
      text-align: center; } }
  @media all and (max-width: 400px) {
    .drink-donate__top-banner-wrap .heading-top-line {
      width: 205px; } }
  @media all and (max-width: 541px) and (min-width: 451px) {
    .drink-donate__top-banner-wrap .heading-top-line {
      width: 275px;
      text-align: center; } }
  @media only screen and (max-width: 767px) {
    .drink-donate__top-banner-wrap {
      padding: 155px 15px 40px 15px; } }
  @media only screen and (max-width: 768px) {
    .drink-donate__top-banner-wrap p {
      display: none; } }
  @media only screen and (max-width: 768px) {
    .drink-donate__top-banner-wrap a {
      display: block; } }

.drink-donate__news-feed {
  display: grid;
  grid-template-columns: 5fr 0fr 5.6fr;
  grid-template-rows: auto 1fr auto;
  padding: 0 0 20px 0; }
  @media only screen and (max-width: 990px) {
    .drink-donate__news-feed {
      display: block;
      padding: 0; } }
  @media only screen and (max-width: 991px) {
    .drink-donate__news-feed .news-feed__grid-left {
      padding-top: 15px; } }
  .drink-donate__news-feed .join-community__right {
    background-color: #FFF; }
    @media only screen and (max-width: 620px) {
      .drink-donate__news-feed .join-community__right .join-community__right-content {
        padding: 0 10px; } }
    .drink-donate__news-feed .join-community__right .join-community__right-content .mockIphone img {
      margin-top: 0; }

.drink-donate__slider {
  padding: 70px 0 0 0;
  /*single image condition starts here*/ }
  @media only screen and (max-width: 991px) {
    .drink-donate__slider {
      padding: 50px 0 0 0; } }
  .drink-donate__slider p {
    margin-bottom: 35px; }
    @media only screen and (max-width: 768px) {
      .drink-donate__slider p {
        margin-bottom: 20px; } }
  .drink-donate__slider .drink-slider__wrapper {
    width: 100%;
    padding: 115px 0;
    overflow: hidden; }
    @media only screen and (max-width: 991px) {
      .drink-donate__slider .drink-slider__wrapper {
        padding: 103px 0 35px 0;
        margin: 23px 0; } }
    @media only screen and (max-width: 768px) {
      .drink-donate__slider .drink-slider__wrapper {
        padding: 42px 0px 35px 0px; } }
    .drink-donate__slider .drink-slider__wrapper .slick-next, .drink-donate__slider .drink-slider__wrapper .slick-prev {
      width: 80px;
      height: 80px;
      border-radius: 50%;
      z-index: 1000;
      -webkit-box-shadow: 0px 5px 35px rgba(1, 1, 1, 0.09);
      /* Safari 3-4, iOS 4.0.2 - 4.2, Android 2.3+ */
      -moz-box-shadow: 0px 5px 35px rgba(1, 1, 1, 0.09);
      /* Firefox 3.5 - 3.6 */
      box-shadow: 0px 5px 35px rgba(1, 1, 1, 0.09);
      padding: 20px;
      background-size: 45px;
      background-position: center;
      background-repeat: no-repeat;
      background-color: #fff; }
    .drink-donate__slider .drink-slider__wrapper .slick-prev {
      background-image: url("../images/long-arrow-pointing-to-left.svg"); }
      @media only screen and (min-width: 1025px) {
        .drink-donate__slider .drink-slider__wrapper .slick-prev {
          left: 10%; } }
      @media only screen and (max-width: 768px) {
        .drink-donate__slider .drink-slider__wrapper .slick-prev {
          display: none !important; } }
    .drink-donate__slider .drink-slider__wrapper .slick-next {
      background-image: url("../images/long-arrow-pointing-to-the-right.svg"); }
      @media only screen and (min-width: 1025px) {
        .drink-donate__slider .drink-slider__wrapper .slick-next {
          right: 10%; } }
      @media only screen and (max-width: 768px) {
        .drink-donate__slider .drink-slider__wrapper .slick-next {
          display: none !important; } }
    .drink-donate__slider .drink-slider__wrapper .slick-prev::before, .drink-donate__slider .drink-slider__wrapper .slick-next::before {
      content: " "; }
    .drink-donate__slider .drink-slider__wrapper .slick-list {
      overflow: inherit; }
      .drink-donate__slider .drink-slider__wrapper .slick-list:after {
        right: -2px;
        background: linear-gradient(to right, rgba(255, 255, 255, 0) 0%, white 100%); }
      .drink-donate__slider .drink-slider__wrapper .slick-list .drink-slider {
        opacity: .8;
        transition: all .3s ease; }
        .drink-donate__slider .drink-slider__wrapper .slick-list .drink-slider .drink-slider__container {
          margin: 0 10px;
          position: relative; }
          @media only screen and (min-width: 769px) {
            .drink-donate__slider .drink-slider__wrapper .slick-list .drink-slider .drink-slider__container {
              transform: scale(0.8); } }
          @media only screen and (max-width: 990px) {
            .drink-donate__slider .drink-slider__wrapper .slick-list .drink-slider .drink-slider__container {
              margin: 0 5px; } }
          @media only screen and (max-width: 768px) {
            .drink-donate__slider .drink-slider__wrapper .slick-list .drink-slider .drink-slider__container {
              margin: 0 7px; } }
          @media only screen and (max-width: 550px) {
            .drink-donate__slider .drink-slider__wrapper .slick-list .drink-slider .drink-slider__container {
              margin: 0 5px; } }
          .drink-donate__slider .drink-slider__wrapper .slick-list .drink-slider .drink-slider__container .drink-slider__banner-image {
            width: 100%;
            height: 400px;
            object-fit: cover;
            object-position: center bottom;
            -webkit-filter: brightness(50%);
            filter: brightness(30%);
            position: relative;
            z-index: -1; }
          .drink-donate__slider .drink-slider__wrapper .slick-list .drink-slider .drink-slider__container .drink-slider__content-image {
            position: absolute;
            top: -40px;
            left: 80px;
            max-width: 100%;
            width: 80px;
            height: 80px;
            border-radius: 50%;
            object-fit: cover;
            object-position: center; }
            @media only screen and (min-width: 991px) {
              .drink-donate__slider .drink-slider__wrapper .slick-list .drink-slider .drink-slider__container .drink-slider__content-image {
                width: 100px;
                height: 100px;
                top: -50px; } }
          .drink-donate__slider .drink-slider__wrapper .slick-list .drink-slider .drink-slider__container .drink-slider__donate-content {
            position: absolute;
            z-index: 100;
            text-align: left;
            top: 50px;
            max-height: 300px;
            overflow: hidden;
            padding: 20px 30px 0; }
            @media only screen and (max-width: 468px) {
              .drink-donate__slider .drink-slider__wrapper .slick-list .drink-slider .drink-slider__container .drink-slider__donate-content {
                padding: 0 15px; } }
            @media only screen and (min-width: 991px) {
              .drink-donate__slider .drink-slider__wrapper .slick-list .drink-slider .drink-slider__container .drink-slider__donate-content {
                padding: 0 80px; } }
      .drink-donate__slider .drink-slider__wrapper .slick-list .slick-center .drink-slider {
        opacity: 1; }
        @media only screen and (min-width: 769px) {
          .drink-donate__slider .drink-slider__wrapper .slick-list .slick-center .drink-slider .drink-slider__container {
            transform: scale(1.21); } }
  .drink-donate__slider .drink-slider__wrapper1 {
    width: 100%;
    overflow: hidden; }
    @media only screen and (max-width: 991px) {
      .drink-donate__slider .drink-slider__wrapper1 {
        padding: 103px 0 35px 0;
        margin: 23px 0; } }
    @media only screen and (max-width: 768px) {
      .drink-donate__slider .drink-slider__wrapper1 {
        padding: 42px 0px 35px 0px; } }
    .drink-donate__slider .drink-slider__wrapper1 .drink-slider {
      opacity: .8;
      transition: all .3s ease; }
      .drink-donate__slider .drink-slider__wrapper1 .drink-slider .drink-slider__container {
        margin: 0 10px;
        position: relative; }
        @media only screen and (min-width: 769px) {
          .drink-donate__slider .drink-slider__wrapper1 .drink-slider .drink-slider__container {
            transform: scale(0.8); } }
        @media only screen and (max-width: 990px) {
          .drink-donate__slider .drink-slider__wrapper1 .drink-slider .drink-slider__container {
            margin: 0 5px; } }
        @media only screen and (max-width: 768px) {
          .drink-donate__slider .drink-slider__wrapper1 .drink-slider .drink-slider__container {
            margin: 0 7px; } }
        @media only screen and (max-width: 550px) {
          .drink-donate__slider .drink-slider__wrapper1 .drink-slider .drink-slider__container {
            margin: 0 auto;
            width: 90%; } }
        .drink-donate__slider .drink-slider__wrapper1 .drink-slider .drink-slider__container .drink-slider__banner-image {
          width: 100%;
          height: 600px;
          object-fit: cover;
          object-position: center bottom;
          -webkit-filter: brightness(50%);
          filter: brightness(30%);
          position: relative;
          z-index: -1; }
        .drink-donate__slider .drink-slider__wrapper1 .drink-slider .drink-slider__container .drink-slider__content-image {
          position: absolute;
          top: -40px;
          left: 80px;
          max-width: 100%;
          width: 80px;
          height: 80px;
          border-radius: 50%;
          object-fit: cover;
          object-position: center; }
          @media only screen and (min-width: 991px) {
            .drink-donate__slider .drink-slider__wrapper1 .drink-slider .drink-slider__container .drink-slider__content-image {
              width: 100px;
              height: 100px;
              top: -50px; } }
        .drink-donate__slider .drink-slider__wrapper1 .drink-slider .drink-slider__container .drink-slider__donate-content {
          position: absolute;
          z-index: 100;
          text-align: left;
          top: 50px;
          max-height: 450px;
          overflow: hidden;
          padding: 20px 30px 0; }
          .drink-donate__slider .drink-slider__wrapper1 .drink-slider .drink-slider__container .drink-slider__donate-content h1 {
            font-size: 32px;
            color: white !important; }
          .drink-donate__slider .drink-slider__wrapper1 .drink-slider .drink-slider__container .drink-slider__donate-content p {
            color: white !important;
            font-size: 18px !important; }
            .drink-donate__slider .drink-slider__wrapper1 .drink-slider .drink-slider__container .drink-slider__donate-content p p span {
              font-size: 18px !important;
              color: white !important; }
            @media only screen and (max-width: 478px) {
              .drink-donate__slider .drink-slider__wrapper1 .drink-slider .drink-slider__container .drink-slider__donate-content p p {
                margin: 0 auto;
                max-width: 270px !important;
                font-size: 14px; }
                .drink-donate__slider .drink-slider__wrapper1 .drink-slider .drink-slider__container .drink-slider__donate-content p p span {
                  font-size: 14px; } }
          @media only screen and (max-width: 468px) {
            .drink-donate__slider .drink-slider__wrapper1 .drink-slider .drink-slider__container .drink-slider__donate-content {
              top: 35px !important;
              padding: 0 15px; } }
          @media only screen and (min-width: 991px) {
            .drink-donate__slider .drink-slider__wrapper1 .drink-slider .drink-slider__container .drink-slider__donate-content {
              padding: 0 80px; } }
          @media only screen and (max-width: 360px) {
            .drink-donate__slider .drink-slider__wrapper1 .drink-slider .drink-slider__container .drink-slider__donate-content {
              top: 50%; } }

/*single image condition ends here*/
.g-savetodrive__wrapper {
  position: relative;
  background: white;
  padding: 8px; }

#___savetodrive_0 {
  width: 100% !important;
  height: 100% !important;
  position: absolute !important;
  left: 0px !important;
  top: 0px !important;
  opacity: 0.001 !important; }

.cloud-widget-drive {
  cursor: pointer; }

.Functionality-not-available {
  position: relative;
  opacity: .3;
  cursor: not-allowed; }
  @media only screen and (min-width: 991px) {
    .Functionality-not-available {
      opacity: .6; } }

body {
  margin: 0;
  padding: 0;
  background-color: #fff; }

a {
  text-decoration: none;
  font-family: "Roboto", sans-serif; }
  a:hover {
    text-decoration: none; }

ul {
  list-style: none;
  padding: 0; }

img {
  vertical-align: middle; }

.main-container {
  margin: 0 auto;
  max-width: 1200px;
  padding: 0 20px;
  overflow: auto; }
  @media only screen and (min-width: 1300px) {
    .main-container {
      max-width: 1200px; } }
  .seamless-exp-outer .main-container {
    display: grid;
    grid-template-columns: 7fr 5fr;
    padding-top: 80px;
    padding-bottom: 80px; }
    @media all and (max-width: 767px) {
      .seamless-exp-outer .main-container {
        display: block;
        padding-top: 10px; } }
  .about-outer-container .main-container {
    overflow: visible; }
  @media only screen and (min-width: 1300px) {
    .privacy-policy .main-container, .terms-conditions .main-container {
      max-width: 900px; } }
  @media all and (max-width: 767px) {
    .main-container.footer-content-outer {
      display: block; } }
  @media all and (max-width: 479px) {
    .main-container.footer-content-outer {
      position: relative; } }
  .cde-component-outer .main-container {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    text-align: center;
    padding-top: 70px;
    padding-bottom: 70px; }
    @media all and (max-width: 767px) {
      .cde-component-outer .main-container {
        grid-column-gap: 1.5%; } }
    @media all and (max-width: 479px) {
      .cde-component-outer .main-container {
        display: block; } }
  .BottomBanner-outer .main-container {
    text-align: right; }

img {
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  -khtml-user-select: none;
  /* Konqueror HTML */
  -moz-user-select: none;
  /* Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently
                                  supported by Chrome and Opera */ }

.display-block {
  display: block; }

a:focus {
  text-decoration: none; }

@media only screen and (max-width: 991px) {
  .donation-wall__left .Theme-btn__navi {
    display: block;
    text-align: center; } }

.page-404 {
  text-align: center;
  padding: 180px 0 60px;
  background: #08abe6; }
  .page-404 img {
    max-width: 200px; }

.merge-button {
  background-color: #fff;
  /* Green */
  border: none;
  color: #000;
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  cursor: pointer; }

.subscription-error-message {
  color: red;
  margin-top: 4px; }

.home-slider-nav-link {
  color: #fff; }

.cloud-widget-dropbox {
  cursor: pointer; }

.slick-slide > div:focus {
  outline: none; }

.slick-slide > div > div:focus {
  outline: none; }
