.donation-wall {
  display: grid;
  grid-template-columns: 5fr 7.6fr;
  grid-template-rows: minmax(auto, 500px) 100px auto;

  @media only screen and (max-width: 991px) {
    display: block;
  }

  /* width */
  ::-webkit-scrollbar {
      width: 8px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
      background: rgba(0, 0, 0, 0.14);
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
      background: $btn-bg;
      border-radius: 10px;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
      background: #555;
      background-color: #b33600;
  }


}

.donation-wall__left {
  grid-row: 2 / 4;
  grid-column: 1 / 2;
}

.donation-search__result-block--img {
  position: relative;
}

.donation-wall__right {
  grid-row: 3 / 4;
  grid-column: 2 /3;

  @media only screen and (max-width: 991px) {
    margin-top: -15px;
    padding-bottom: 100px;
  }

  a {
    text-align: center;
    display: block;
    margin-top: 30px;
  }
}
.donation__right {
  .donation-wall__right & {
    margin-top: 0;
    grid-template-columns: repeat(auto-fit, minmax(165px, 1fr));
    grid-auto-rows: minmax(auto , 160px);
    max-height: 960px;
    overflow-y: scroll;

    @media only screen and (max-width: 991px) {
        max-height: 600px;
     }

     @media only screen and (max-width: 767px) {
       grid-auto-rows: minmax(auto , 160px)!important;
     }

     @media only screen and (max-width: 449px) {
         max-height: 400px;
         grid-auto-rows: minmax(auto , 100px)!important;
      }

  }
}
.donation__left {
  .donation-wall__left & {
    padding-top: 0;
    grid-template-columns: auto minmax(auto, 450px);

    div + div {
      background: white;
      z-index: 1;
      padding: 50px;
      position: relative;
      box-shadow: 0px 1px 13px rgba(0,0,0,0.09);
      padding-bottom: 30px;

      @media only screen and (max-width: 991px) {
        padding: 0;
        box-shadow: none;
        padding-bottom: 0;
      }
    }

    @media only screen and (max-width: 991px) {
      margin: 0 auto;
      margin-top: -50px;
      padding: 50px;
    }

    @media only screen and (max-width: 620px) {
      margin: 0 20px;
      margin-top: -50px;
    }

    @media only screen and (max-width: 449px) {
      padding: 40px 20px;
    }
  }
}

.donation-wall__banner {
  position: relative;
  grid-column: 1 / 3;
  grid-row: 1 / 3;
  overflow: hidden;

  .donation-search & {
    height: 500px;
  }

  @media only screen and (max-width: 991px) {
    height: 272px;
  }

  img{
    vertical-align: middle;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
  }
}

.donation-wall__search-bar {
  position: absolute;
  max-width: 700px;
  height: 45px;
  overflow: auto;
  margin: auto;
  top: 50px;
  left: 0;
  bottom: 0;
  right: 0;
  border-radius: 30px;

  .faq__top-banner &{
    position: static;
  }

  @media only screen and (max-width: 767px) {
    max-width: 600px;
  }

  @media only screen and (max-width: 620px) {
    max-width: 100%;
    margin: auto 20px;
  }

  input{
    width: 100%;
    box-sizing: border-box;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.29);
    border: none;
    padding: 0px 45px;
    font-size: 17px;
    color: $white;
    font-weight: 300;
    &:focus {
      outline: none;
    }
  }

  .search-btn {
    background-image: url('../images/search-icon.png');
    background-repeat: no-repeat;
    background-position: center;
    position: absolute;
    height: 45px;
    width: 45px;
    margin: 0;
    top: 0;
    right: 25px;
    cursor: pointer;

    &:focus{
      outline: none;
    }

    .donation-search & {
      cursor: auto;
    }
  }

  ::-webkit-input-placeholder { /* Chrome/Opera/Safari */
    color: $white;
    font-family: $font-family;
    font-weight: 400;
  }
  ::-moz-placeholder { /* Firefox 19+ */
    color: $white;
    font-family: $font-family;
    font-weight: 400;
  }
  :-ms-input-placeholder { /* IE 10+ */
    color: $white;
    font-family: $font-family;
    font-weight: 400;
  }
  :-moz-placeholder { /* Firefox 18- */
    color: $white;
    font-family: $font-family;
    font-weight: 400;
  }
}

.donation-wall__search-bar--content {
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;
}

.img-loader{
  background-image: url('../images/img-loader.gif');
  background-repeat: no-repeat;
  background-position: 50% 52%;
  background-size: 100px;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;

  .news-single__wrapper & {
    background-position: 50% 25%;
  }

  .donation-wall__right & {
    background-position: 50% 50%;
  }
}

.img-loader--error {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  background-image: url('../images/donation-wall.jpg');

  .news-single__wrapper & {
    background-image: url('../images/news-image.jpg');
    position: relative;
    height: 180px;
    width: 100%;
  }
}

.donation-search__result-block {
  padding: 50px 0;
}

.donation-search__result-block-container {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-column-gap: 30px;
  grid-row-gap: 30px;
  margin-bottom: 20px;

  @media only screen and (max-width: $wide-breakpoint) {
    grid-template-columns: 1fr 1fr;
  }

  @media only screen and (max-width: $small-breakpoint) {
    grid-template-columns: 1fr;
  }
}

.donation-search__result-block--single{
  border-radius: 5px;
  overflow: hidden;
  -webkit-box-shadow: 0px 1px 13px rgba(0, 0, 0, 0.09);
    -moz-box-shadow: 0px 1px 13px rgba(0, 0, 0, 0.09);
  box-shadow: 0px 1px 13px rgba(0, 0, 0, 0.09);
  display: grid;
  grid-template-columns: 1fr 2fr;
  grid-auto-rows: 140px;
  &:hover {
     box-shadow: 0 8px 16px rgba(0,0,0,.18);
 }
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
    -webkit-filter: grayscale(100%);
    filter: grayscale(100%);
  }

  .price, .name{
    font-weight: 600;
  }

  .date {
    color: #afafaf;
    font-size: 14px;
    font-size: 14px;
  }
}


.donation-search__not-found {
      margin-bottom: 70px;
      text-align: center;
  h1 {
    margin: 15px 0;
    font-size: 30px;
    color: $black;
  }
  p{
    color: $black;
    max-width: 500px;
    margin: 0 auto;
    font-size: 17px;
  }

  img {
    max-width: 80px;
  }
}
