.new-stories-featured__single {
  transition: box-shadow .2s linear;
  &:hover {
    box-shadow: 0 8px 16px rgba(0,0,0,.18);
  }
}
.new-stories , .editors-picks_card {
  transition: box-shadow .2s linear;
  &:hover {
    box-shadow: 0 8px 16px rgba(0,0,0,.18);
  }
}


.news-single__content {
  p {
    margin: 10px 0;
    font-size: 13px;
    color: #aaa;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    max-height: 45px;
  }
}

.donation-wall__search-bar {
  .news__banner & {
    position: static;
  }
}

.news-category__slider-single-details {
  h6 {
    overflow: hidden;
    text-overflow: ellipsis;
    display: block;
    -webkit-line-clamp: unset;
    max-height: unset;
  }
}

.news-detail__wrapper {
  padding: 50px 0;
}

.news-category-single__wrapper {
  .news-single__content {
    display: grid;
    padding: 0 10px;
    grid-template-rows: 100px auto 100px;
    height: 400px;
    overflow: hidden;

    @media all and (max-width: 991px)
    {
      height: 400px;
    }


    .img-wrapper{
      grid-row: 1 / 4;
      grid-column: 1 / 3;

      img{
        width:100%;
        max-height: 400px;
        height: 100%;
        object-fit: cover;
        object-position: center;
        filter: brightness(50%);
        position: relative;
        z-index: -1;
      }
    }

    .news-single__details-wrapper {
      grid-row: 2 / 3;
      grid-column: 1 / 3;
      text-align: center;
    }
  }
}

.news-search__result-block-container {
  .img-wrapper {
    img {
      width: 100%;
      height: 160px;
      object-fit: cover;
      object-position: center;
    }
  }
}
.news-detail {
  margin-top: 104px;

  img {
    position: relative;
    z-index: 1;
    width:100%;
    height: 400px;
    object-fit: cover;
    object-position: center;
  }
}
.news-detail__head {
  padding: 20px 0;
  box-shadow: 1px 4px 10px rgba(0, 0, 0, 0.28);
  z-index: 2;
  position: relative;
}

.news-detail__category-wrapper {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(165px, 200px));
    grid-gap:0px;
}

.social-share {
display: grid;
grid-template-columns: 1fr 1fr 1fr 1fr;
grid-gap: 15px;

.SocialMediaShareButton {
  &:focus{
    outline: none;
  }
}

.thank-you__share-icons & {
  grid-auto-rows: 60px;
  grid-gap: 0;
}

div {
  cursor: pointer;
}
}

.news-detail__share-wrapper {
  width: 200px;
  margin-top: 35px;

  img {
    height: 45px;
    width: 45px;
  }
}

.news-detail__head-wrapper {
  display: grid;
  grid-template-columns: 70% 30%;
  align-items: center;
  @media only screen and (max-width: 1024px) {
    grid-template-columns: 100%;
  }
  p{
    font-size: 15px;
    margin: 10px 0;
    color: #ca1515;
  }
  h1 {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    max-height: 28px;
    margin: 0;
    color: #9a9a9a;
    font-weight: 500;
    font-size: 25px;
  }
  a{
    text-align: right;
  }
  .donate__drop{
    @media only screen and (max-width: 1024px) {
      display: none;
    }
  }
}

.news__banner {
  background-image: url('../images/news-banner.png');
  background-size: cover;
  background-repeat: no-repeat;
  background-position-y: 99%;
  padding: 160px 20px 40px;
}

.news__banner-content {
  max-width: 600px;
  padding: 50px 20px 0;
  text-align: center;
  margin: 0 auto;
}

.newspage-single-news {
  position: relative;
  box-shadow: 0px 5px 19px rgba(0, 0, 0, 0.05);

  .img-wrapper {
    height: 150px;
    position: relative;
  }

  .img-loader--error {
    background-size: 300px;
    background-image: url('../images/news-image.jpg');
  }
  .details {
    padding: 15px;
    min-height: 130px;

    h6 {
      font-size: 18px;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      max-height: 45px;
    }

    p{
      margin: 10px 0;
    font-size: 13px;
    color: #aaa;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    max-height: 47px;
    }
  }
}

.news-search__result-block-container {
  display: grid;
  grid-template-columns: repeat(4 , 1fr);
  grid-gap: 20px;
  margin: 20px 0px;

  @media all and (max-width: 991px)
  {
    grid-template-columns: repeat(3 , 1fr);
  }

  @media all and (max-width: 767px)
  {
    grid-template-columns: repeat(2 , 1fr);
  }

  @media all and (max-width: 550px)
  {
    grid-template-columns: repeat(1 , 1fr);
  }



}

.news-search__result-block {
  h3 {
    span{
      color: $theme-blue;
    }
  }
}

.news__view-more{
  color: #ef4800;
  font-family: "Roboto", sans-serif;
  font-size: 14px;
  font-weight: 600;
  margin: 0;
  position: absolute;
  right: 0;
  top: 4px;
  cursor: pointer;
}
.new-stories-featured__image {
  img {
    height: 200px!important;
  }
}
.news__landing-block {
  position: relative;
  margin: 40px 0px 30px;
  h1 {
    color: $theme-blue;
    font-size: 20px;
    font-weight: 500;
  }

  img {
    width: 100%;
    height: 160px;
    object-fit: cover;
    object-position: center;
  }
}

.new-stories-featured__single {
  display: grid;
  grid-template-columns: 1fr 1fr;
  -webkit-box-shadow: 0px 5px 19px rgba(0, 0, 0, 0.05);
    -moz-box-shadow: 0px 5px 19px rgba(0, 0, 0, 0.05);
    box-shadow: 0px 5px 19px rgba(0, 0, 0, 0.05);
    background-color: $white;
}

.new-stories-featured__content {
  padding: 15px;
}

.new-stories {
  -webkit-box-shadow: 0px 5px 19px rgba(0, 0, 0, 0.05);
    -moz-box-shadow: 0px 5px 19px rgba(0, 0, 0, 0.05);
    box-shadow: 0px 5px 19px rgba(0, 0, 0, 0.05);
    background-color: $white;
}

.new-stories__content {
  padding: 15px;
}

.news__listing {
  display: grid;
  grid-template-columns:repeat(4 , 1fr);
  grid-gap: 20px;
  background-image: url('../images/new_stories.png');
  padding-bottom: 80px;
  background-size: 70%;
  background-repeat: no-repeat;
  background-position: 50% 100%;

  .new-stories-featured__single:nth-of-type(1){
    grid-column: 1 / 3;
  }
  .new-stories-featured__single:nth-of-type(2){
    grid-column: 3 / 5;
  }
  @media all and (max-width: 991px)
  {
    display: none;
  }
}
.news__listing--mob{
  display: none; background-size: 70%;
  background-repeat: no-repeat;  padding-bottom: 40px;
  background-position: 50% 100%;
  background-image: url('../images/new_stories.png');
  @media all and (max-width: 991px)
  {
    display: block;
  }
  @media all and (max-width: 478px){
    padding-bottom: 20px;
    background-size: 90%;
  }
}

.editors-picks {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 20px;
  grid-auto-rows: 300px;
  background-image: url('../images/editors_picks.png');
  padding-bottom: 100px;
  background-size: 70%;
  background-repeat: no-repeat;
  background-position: 50% 100%;
  @media all and (max-width: 991px)
  {
    display: none;
  }

  img {
    height: 300px;
  }
}
.editors-picks--mob {
  display: none;
  background-image: url('../images/editors_picks.png');
  padding-bottom: 70px;
  background-size: 80%;
  background-repeat: no-repeat;
  background-position: 50% 100%;
  @media all and (max-width: 991px)
  {
    display: block;
  }
  @media all and (max-width: 478px)
  {
    padding-bottom: 35px;
    background-size: 90%;
  }
}

.BottomBanner-outer {
  .news &{
    background-image: url('../images/new_landingbanner.jpg');
    text-align: center;
    margin-bottom: 50px;
    padding: 80px 20px;
  }
}

.BottomBanner-outer .heading-bold {
  .news &{
    margin: 0;
  }
}

.editors-picks_card {
  position: relative;
  -webkit-box-shadow: 0px 5px 19px rgba(0, 0, 0, 0.05);
    -moz-box-shadow: 0px 5px 19px rgba(0, 0, 0, 0.05);
    box-shadow: 0px 5px 19px rgba(0, 0, 0, 0.05);
}

.read-more__btn {
  .editors-picks_content & {
    color: white;
    &:hover {
      color: #9e9e9e;
    }
  }
}
.editors-picks_content {
  position: absolute;
  bottom: 30px;
  left: 30px;
  z-index: 2;
}

.editors-picks_overlay {
  z-index: 1;
  position: absolute;
  background: rgba(0,0,0,0.78);
background: -moz-linear-gradient(45deg, rgba(0,0,0,0.78) 0%, rgba(28,28,28,0.33) 41%, rgba(82,82,82,0.18) 55%, rgba(255,255,255,0.18) 100%);
background: -webkit-gradient(left bottom, right top, color-stop(0%, rgba(0,0,0,0.78)), color-stop(41%, rgba(28,28,28,0.33)), color-stop(55%, rgba(82,82,82,0.18)), color-stop(100%, rgba(255,255,255,0.18)));
background: -webkit-linear-gradient(45deg, rgba(0,0,0,0.78) 0%, rgba(28,28,28,0.33) 41%, rgba(82,82,82,0.18) 55%, rgba(255,255,255,0.18) 100%);
background: -o-linear-gradient(45deg, rgba(0,0,0,0.78) 0%, rgba(28,28,28,0.33) 41%, rgba(82,82,82,0.18) 55%, rgba(255,255,255,0.18) 100%);
background: -ms-linear-gradient(45deg, rgba(0,0,0,0.78) 0%, rgba(28,28,28,0.33) 41%, rgba(82,82,82,0.18) 55%, rgba(255,255,255,0.18) 100%);
background: linear-gradient(45deg, rgba(0,0,0,0.78) 0%, rgba(28,28,28,0.33) 41%, rgba(82,82,82,0.18) 55%, rgba(255,255,255,0.18) 100%);
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}


.top-stories {
  display: grid;
  grid-template-columns: repeat(4 , 1fr);
  grid-gap: 20px;
  background-image: url('../images/top_stories.png');
  padding-bottom: 105px;
  background-size: 70%;
  background-repeat: no-repeat;
  background-position: 50% 100%;

  .news & {
    @media all and (max-width: 767px)
    {
      display: none;
    }
  }

    @media all and (max-width: 991px)
    {
      grid-template-columns: repeat(3 , 1fr);
    }

    @media all and (max-width: 767px)
    {
      grid-template-columns: repeat(2 , 1fr);
    }

    @media all and (max-width: 550px)
    {
      grid-template-columns: repeat(1 , 1fr);
    }


  .news_category-landing & {
    background-image: none;
    padding-bottom: 60px;
  }
}
.top-stories--mob {
  display: none;
  background-image: url('../images/top_stories.png');
  padding-bottom: 55px;
  background-size: 70%;
  background-repeat: no-repeat;
  background-position: 50% 100%;
  @media all and (max-width: 767px)
  {
    display: block;
  }
  @media all and (max-width:482px){
    padding-bottom: 35px;
    background-size: 90%;
  }
}

.news__banner {
  .news_category-landing & {
    text-align: center;
    background-image: none;
    padding: 150px 20px 60px;
    background-color: $theme-blue;
    margin-bottom: 40px;
  }

  .news-category-Search-page & {
    text-align: center;
    background-image: none;
    padding: 150px 20px 60px;
    background-color: $theme-blue;
  }
}

.news_category-landing-modal {
  background-image: url('../images/news-popup-2.jpg')!important;
}


.news__landing-block {
  .slick-list {
  	///border: 1px solid;
  	position: relative;
  	&:after{
		content: "";
	//	background: #FAFAFA;
		position: absolute;
		top: 0;
		height: 100%;
		width: 40%;
		z-index: 100;
  }
    &:after {
		right: 0px;
	//	background: linear-gradient(to right, rgba(255,255,255,0) 0%,rgba(255,255,255,1) 100%);
	}
  }
}
