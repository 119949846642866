.news-modal-content, .faq__top-banner-wrap {
    text-align: center;
    h2 {
        font-weight:400;
        font-size: 45px;
        font-family:  $font-family;
        span {
            font-weight: 700;
        }
    }
    p{
        width:60%;
        margin: 0 auto;
    }
}
.Modal {
    position: absolute;
    top: 50%;
    left: 50%;
    right: auto;
    bottom: auto;
    border: 1px solid rgb(204, 204, 204);
    background-image: url('../images/pop_up_bg.jpg');
    background-repeat: no-repeat; /* Do not repeat the image */
    background-size: cover; /* Resize the background image to cover the entire container */
    overflow: auto;
    border: none;
    border-radius: 0;
    box-shadow: 6px 5px 30px rgba(0, 0, 0, 0.361);
    outline: none;
    padding: 100px 20px;
    margin-right: -50%;
    transform: translate(-50%, -50%);
    z-index: 4;

    @media all and (max-width: 991px)
    {
      padding: 60px 20px;
      left: 50%;
      right: 10%;
    }

    @media all and (max-width: 767px)
    {
      padding: 40px 20px;
    }


    .close-btn {
        position: absolute;
        top: 20px;
        right: 20px;
        font-size: 30px;
        font-weight: 500;
        color: whitesmoke;
        margin: 0;
        cursor: pointer;

        &:focus {
          outline: none;
        }
    }


    .news-modal-content {
        text-align: center;
        h2 {
            font-weight:400;
            font-size: 45px;
            font-family:  $font-family;

            @media all and (max-width: 767px)
            {
              font-size: 30px;
            }


            span {
                font-weight: 700;
            }
        }
        p{
            width:60%;
            margin: 0 auto;
        }
    }
}
.Overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    opacity: 1;
    z-index:9999;
    background-color: rgba(0, 0, 0, 0.81);
}
