.BottomBanner-outer{
  background-image: url('../images/syria-refugee-bg.jpg');
  background-size: cover;
  background-position: center;
  max-height:280px;
  padding: 45px 0;
  @media only screen and (max-width: 991px) {
    display: none;
  }
  .contact-outer & {
    background-image: url('../images/contact-bottombg.png');
    margin-top: 20px;
  }

  .news-detail & {

    text-align: center;
    padding: 110px 20px 100px;

    .heading-bold {
      margin: 0;
      br {
        display: none;
      }
    }
    p{
      br {
        display: none;
      }
    }

    .main-container {
      display: block;
    }
  }
}
