.checkbox{
  display: grid;
  grid-template-columns: 1fr 2fr 2fr;
  margin-top: 15px;
  @media all and (max-width: 530px)
  {
    display: block;
  }
  &.make-donation-outer{
    grid-template-columns: 0.78fr 1.1fr 2fr;
    @media all and (max-width: 530px)
    {
      width: 100%;
      overflow: auto;
    }
  }
  .checkbox_outer{
    @media all and (max-width: 530px)
    {
      float: left;
    }
  }
}


.checkbox input {
  padding: 0;
  height: initial;
  width: initial;
  margin-bottom: 0;
  display: none;
  cursor: pointer;
}

.checkbox label {
  position: relative;
  cursor: pointer;
  font-size: 12px;
  color: black;
}

.checkbox label:before {
  content:'';
  -webkit-appearance: none;
  background-color: transparent;
  border: 1px solid $input-grey-border;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05), inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05);
  padding: 7px;
  display: inline-block;
  position: relative;
  vertical-align: middle;
  cursor: pointer;
  margin-right: 5px;
  border-radius: 3px;
  margin-top: -3px;
}

.checkbox input:checked + label:after {
  content: '';
  display: block;
  position: absolute;
  top: -5px;
  left: 6px;
  width: 6px;
  height: 14px;
  border: solid #0079bf;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}
