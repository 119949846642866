.inner-page__file-download,
.inner-page__file-upload,
.inner-page__progress-bar {
  z-index: 2;
  position: relative;
  margin-top: -180px;
}

.inner-page__download-box {
    box-shadow: 0px 3px 7px rgba(0,0,0,0.22);
    margin-bottom: 10px;
    .inner-page__file-download-wrapper {
      @media all and (max-width: 991px)
      {
          border: solid white;
      }

    }
}

.inner-page__file-upload {
  z-index: 2;
  position: relative;
  margin-top: -180px;
}

.top-banner__ad {
    max-width: 700px;
    overflow: auto;
    margin: 50px auto;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;

    img {
      width: 100%!important;
      height: auto!important;
    }
}


.inner-page__top-banner {
  position: relative;
  margin-top: 102px;
  height: 400px;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
  }
}

.inner-page__all-functions {
    padding: 60px 0;

}

.all-functions__category {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  margin-top: 60px;
  @media all and (max-width: 991px)
  {
    display: none;
  }

  .all-functions__category-col {
    a{
      li{
        font-family: $font-family;
        color: $black;
        font-size: 16px;
        font-weight: 300;
        letter-spacing: 0;
        margin-bottom: 16px;
      }
    }
  }
}

.inner-page__process {
  background: $theme-blue;
  padding: 70px;
  border-radius: 5px;
  border: 5px solid $white;
  box-shadow: 0px 4px 11px rgba(0,0,0,0.1);
  margin-bottom: 10px;
  position: relative;
  @media all and (max-width: 479px)
  {
    padding: 3%;
  }
}


.file-upload__widget {
  margin: 0 auto;
  text-align: center;

  label {
    img{
      max-width: 50px;
      margin-bottom: 5px;
    }
  }
}

.inner-page__process {
  .file-upload__widget{
    .filepond--wrapper{
      max-width: 500px;
      margin: 0 auto;
      .filepond--root{
        margin: 1em;
        margin-top: 0;
        @media all and (max-width: 479px)
        {
          margin: 0;
        }
      }
    }
  }
  .filepond--drop-label  {
    label {
      font-family: $font-family;
      color: $white;
      font-size: 16px;
      line-height: 20px;
      cursor: pointer;
    }
    .bold-txt {
      font-weight: 600!important;
    }
    .underline-txt {
      padding-bottom: 1px;
      border-bottom: 2px solid $white;
    }
  }

  .filepond--drip {
    opacity: 1;
    background: $theme-blue;
  }
.filepond--file {
  @media all and (max-width: 479px)
  {
    display: block;
    background: #379763;
  }
}
  .filepond--file-info {
    font-family: $font-family;
    letter-spacing: 1px;
  }

  .filepond--list.filepond--list {
      margin-top: 25px;
      @media all and (max-width: 479px)
      {
        left: 0;
        right: 0;
        margin: 0;
      }
  }
}

.filepond--file-status .filepond--file-status-sub {
  max-width: 200px;
  text-overflow: ellipsis;
  overflow: hidden;
}

.file-download__top-row {
  display: grid;
  grid-template-columns: 2fr 1.5fr;
  @media all and (max-width: 991px)
  {
    grid-template-columns: 1fr;
  }
}

.file-download-top__details {
  padding: 40px 30px 20px 40px;
  background: $theme-blue;
  @media all and (max-width: 991px)
  {
    text-align: center;
  }
}

.file-download-top__right {
  text-align: center;
  background-image: url('../images/pdf-download.jpg');
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  padding: 25px 0px;
  @media all and (max-width: 991px)
  {
    display: none;
  }
}


.cloud-widget {
  max-width: 150px;
  text-align: center;
  .inner-page__process & {
    width: 150px;
    position: absolute;
    bottom: 16px;
    right: 50px;
    @media all and (max-width: 991px)
    {
      position: static;
      margin: 0 auto;
    }
    @media all and (max-width: 479px)
    {
      margin-top: 20px;
    }
  }

  .file-download-top__details &{
    margin-top: 50px;
  }

  p{
    font-size: 15px;
    font-weight: 500;
    margin-bottom: 8px;
  }
  &.desktop {
    display: block;
    @media all and (max-width: 991px)
    {
      display: none;
    }
  }
  &.mobile {
    display: none;
    @media all and (max-width: 991px)
    {
      display: block;
      max-width: 100%;
    }
  }
  .mobile {
    display: none;
    @media all and (max-width: 991px)
    {
      display: block;
    }
  }
  .g-savetodrive__wrapper {
    @media all and (max-width: 991px)
    {
      background: gainsboro;
      border-radius: 50px;
      padding: 15px 50px;
      font-size: 14px;
      margin-top: 10px;
      padding-left: 10%;
      padding-right: 12%;
    }
    #___savetodrive_1 {
      @media all and (max-width: 991px)
      {
        top: 0;
        height: 100% !important;
        position: absolute;
        left: 0;
        width: 100% !important;
      }
      iframe {
        @media all and (max-width: 991px)
        {
          position: absolute !important;
          width: 100% !important;
          height: 100% !important;
          opacity: 0.01;
          padding-top: 4%;
        }

      }

    }
    label.mobile{
      float: left;
      font-size: 14px;
      color: black;
      padding-top: 4px;
    }
    img {
      @media all and (max-width: 991px)
      {
        float: right;
      }
    }
  }
}

.file-download__sucess-txt {
  font-size: 17px;
  span{
    font-weight: 600;
  }
}

.cloud-widget__wrap {
    display: grid;
    grid-template-columns: 1fr 1fr;
    background: #ddd;
    grid-gap: 1px;
    border-radius: 50px;
    overflow: hidden;
    .inner-page__file-download & {
      @media all and (max-width: 991px)
      {
        background: none;
        border-radius: 0;
        grid-template-columns: 1fr;
      }
    }

    a{
      padding: 10px;
      background: $white;
      .inner-page__file-download & {
        @media all and (max-width: 991px)
        {
          background: gainsboro;
          border-radius: 50px;
          padding: 15px 50px;
          font-size: 14px;
          margin-top: 10px;
          padding-left: 10%;
          padding-right: 12%;
        }
        label {
          @media all and (max-width: 991px)
          {
            float: left;
            margin-top: 4px;
            font-size: 14px;
            color: black;
          }
        }
        img {
          @media all and (max-width: 991px)
          {
            float: right;
            right: 40px;
          }
        }
      }
    }

}

.file-download__bottom-row {
  display: grid;
  grid-template-columns: 2fr 1.5fr;
  @media all and (max-width: 991px)
  {
    grid-template-columns: 1fr;
        background: $theme-blue;
  }
}

.file-download__bottom-btn {
  display: grid;
  grid-template-columns: .3fr 2fr 1fr;
  padding: 30px 25px;
  align-items: center;
  @media all and (max-width: 991px)
  {
    grid-template-columns: 1fr;
    padding-top: 0;
  }
  img{
    max-width: 45px;
    @media all and (max-width: 991px)
    {
      margin: 0 auto;
    }
  }
}

.file-download__bottom-filename {
  h2 {
    color: $black;
    margin: 0;
    font-size: 18px;
    font-weight: 600;
    @media all and (max-width: 991px)
    {
      text-align: center;
      color: white;
      padding-top: 10px;
      padding-bottom: 10px;
    }
  }

  p{
    color: $black;
    margin: 0;
    font-size: 14px;
    @media all and (max-width: 991px)
    {
      text-align: center;
      color: white;
      padding-top: 10px;
      padding-bottom: 10px;
    }
  }
}

.download-btn {
  input[type=submit]{
    background-image: url('../images/download.png');
    width: 165px;
    text-align: left;
    border: none;
    display: block;
    color: black;
    background-repeat: no-repeat;
    background-position: 85% 48%;
    background-color: #dcdcdc;
    padding: 12px 20px;
    border-radius: 50px;
    margin: 0 auto;
    transition: .2s;
    font-family: $font-family;
    cursor: pointer;

    &:hover {
      transition: .2s;
      box-shadow: 0px 0px 25px -8px rgba(0, 0, 0, 0.6);
    }
    &:focus {
      outline: none;
    }
    @media all and (max-width: 991px)
    {
      width: 100%;
      padding: 20px 10%;
      font-size: 14px;
    }
  }
}

.file-download__bottom-options {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  text-align: center;
  @media all and (max-width: 991px)
  {
    grid-template-columns: 1fr 1fr;
  }

  a {
    border-left: 1px solid #dcdcdc;
    @media all and (max-width: 991px)
    {
      border: none;
    }
  }
  .single-col {
    @media all and (max-width: 991px)
    {
      background: $theme-blue;
    }
    &:hover{
      @media all and (max-width: 991px)
      {
        background: #05a4de;
      }
    }
  }
}


.split-pdf-wrapper {
  background: #08abe6;
  border-radius: 5px;
  border: 5px solid #fff;
  box-shadow: 0 4px 11px rgba(0,0,0,.1);
  margin-bottom: 10px;
  display: grid;
  grid-template-columns: 2fr 1fr;
  @media all and (max-width: 767px)
  {
    display: block;
  }
}
.split-pdf__info {
  text-align: center;
  display: grid;
  align-items: center;
  @media all and (max-width: 767px)
  {
    padding: 30px;
  }

  img{
    max-width: 60px;
  }

  h1 {
    margin: 0;
    color: white;
    max-width: 300px;
    margin: 8px auto;
    font-size: 20px;
    font-weight: 500;
  }
}

.split-pdf__opitions {
  padding: 30px;
  background: #008ec1;;

  .split-pdf__btn-set {
    text-align: center;
    margin-top: 20px;
    button {
      width: 115px;
      text-align: center;
      font-family: $font-family;
      color: white;
      font-size: 16px;
      cursor: pointer;
      margin: 0 auto;

      &:focus {
        outline: none;
      }
      &:hover{
        text-decoration: underline;
      }

    }
  }
  .middle {
    width: 100%;
    text-align: center;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 15px;
    @media all and (max-width: 500px)
    {
      display: block;
    }

    label {
      @media all and (max-width: 767px)
      {
        text-align: center;
      }
      .box {
        @media all and (max-width: 500px)
        {
          width: 100%;
          height: auto;
        }
        &.front-end {
          @media all and (max-width: 500px)
          {
            margin-bottom: 20px;
          }
        }
        .box__wrapper {
          @media all and (max-width: 500px)
          {
            position: static;
            transform: unset;
          }
        }
      }
    }

    input[type="radio"] {
      display: none;
      &:checked {
        + .box {
          background-color:$theme-blue;
          border-color: $theme-blue;
          .box__wrapper {
            color: white;
            transform: translateY(25px);
            @media all and (max-width: 767px)
            {
              transform: unset;
            }
          }
        }
      }
    }
    .box {
      width: 200px;
      height: 200px;
      background-color: transparent;
      transition: all 250ms ease;
      will-change: transition;
      display: inline-block;
      text-align: center;
      cursor: pointer;
      position: relative;
      font-weight: 900;
      border: #d7d7d7 2px solid;
      border-radius: 5px;

      color: black;
      background-color: #1d8ec1;
      box-sizing: border-box;
      &:active{
        transform: translateY(10px);
      }
      .box__wrapper {
        position: absolute;
        transform: translate(0, 45px);
        left: 0;
        right: 0;
        transition: all 300ms ease;
        font-size: 1.5em;
        user-select: none;
        color: white;
        padding: 20px;
        font-family: $font-family;
        font-weight: 200;
        font-size: 16px;
        &:before {
          font-size: 1.2em;
          font-family: FontAwesome;
          display: block;
          transform: translateY(-80px);
          opacity: 0;
          transition: all 300ms ease-in-out;
          font-weight: normal;
          color: white;
        }

        img{
          max-width: 110px;
          display: block;
          margin: 0 auto;
          margin-bottom: 15px;
        }
      }
      .back-end {
        img {
          max-width: 130px;
        }
      }
    }
  }
}
.split-pdf-container {
  margin-top: -180px;
  position: relative;
  text-align: center;
  button {
    margin: 0 auto;
    height: 40px;
    width: 115px;
    background: #008ec1;
    color: white;
    border-radius: 3px;
    font-weight: 600;
    cursor: pointer;
    margin-top: 20px;
  }
}
.react-pdf__Document {
  background: white;
  border-radius: 5px;
  .instruction {
    background: #ffffff;
    text-align: center;
    padding: 18px;
    color: #5f5a5a;
    font-family: "Roboto", sans-serif;
  }
  .page-preview-container {
    border-radius: 5px;
    padding: 1px 1px 30px 1px;
    border-bottom: none;
    height: 100%;
    overflow: hidden;
    margin-left: auto;
    margin-right: auto;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-line-pack: start;
    align-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    box-sizing: border-box;
    background: #f9f9f9;
    border: solid 1px #f5f5f5;
  }

  .react-pdf__Page {
    user-select: none;
    position: relative;
    width: 189px;
    height: auto;
    margin: 20px;
    padding: 10px;
    transition-property: color, background;
    transition-duration: 0.2s;
    border: solid 1px #ededee;
    background: white;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.05);
    &.active
    {
      background: #26abe7;
    }
    .react-pdf__Page__canvas {
      width: 100% !important;
      height: auto !important;
    }
  }
}

.loading-pdf-msg {
  li {
    color: #a5a3a3;
    font-weight: 200;
  }
}
.react-pdf__message {
  padding: 10px;
  font-family: $font-family;
}
.merge-button {
  border-radius: 4px;
  line-height: 8px;
  background-color: #ef4800 !important;
  color: white !important;
}
