.input-text-outer
{
  @media all and (max-width: 479px)
  {
    margin-bottom: 5px;
  }

  input[type="text"]
  {
    padding: 12px 15px 12px 18px;
    box-sizing: border-box;
    border-radius: 3px;
    border: solid 1px $input-grey-border;
    .select-content-outer & {
      min-width: 280px;
      @media all and (max-width: 479px)
      {
        min-width: auto;
        width: 100%;
      }
    }
    .label-input-pair-row & {
        width: 90%;
        @media all and (max-width: 479px)
        {
          width: 100%;
        }
    }
    .label-input-pair-row.column-2 & {
      width: 95%;
      @media all and (max-width: 479px)
      {
        width: 100%;
      }
      &.short-input
      {
        width: 40%;
        max-width: 255px;
        @media all and (max-width: 479px)
        {
          width: 100%;
          max-width: 100%;
        }
      }
    }
    .column-4 & {
      width: 100%;
    }
    &::placeholder
    {
      .column-4 & {
        text-align: center;
      }
    }
    .drop-us-outer & {
      width: 100%;
      border: none;
      border-bottom: 1px solid #e2e2e2;
      padding-left: 0;
      outline: none;
      @media all and (max-width: 479px)
      {
        margin-bottom: 10px;
      }
    }
  }
  .column-4 & {
    width: 80px;
  }
  .select-content-outer & {
    margin-top: 10px;
  }

  .State-zip-code &{
    margin-left: 10px;
    @media all and (max-width: 479px)
    {
      width: 100% !important;
      margin: 0;
      margin-top: 5px;
    }
  }
  .birthday-outer & {
        float: left;
    }
}
input:required
{
  box-shadow: none;
}
