.blue-text
{
  color: $theme-blue;
}

#donate_form {
  .select-content-outer {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-auto-rows: 45px;
    grid-gap: 20px;
    max-width: 500px;
  }

  .input-text-outer {
    margin: 0;

    input {
        height: 100%;
        box-shadow: none;
    }
  }
}

.payment
{
  padding-top: 160px;
  background-size: 100%;
  background-position: -2px;
  background-image: url('../images/bg2.jpg');
  font-family: $font-family;
  h1 {
    margin-top: 0;
  }
  @media all and (max-width: 991px)
  {
      padding-top: 125px;
  }
}
.payment__Left{
      padding: 50px;
      padding-top: 40px;
      background: white;
      margin-bottom: 50px;
      @media all and (max-width: 1060px)
      {
        padding: 25px;
      }
      @media all and (max-width: 991px)
      {
        margin-bottom: 15px;
      }
}
.reciept-msg
{
  display: grid;
  grid-template-columns: 1fr 4fr;
  margin-top: 10px;
  @media all and (max-width: 479px)
  {
    display: block;
    margin-top: 5px;
  }
}
.your-info-container{
  padding-top: 30px;
  @media all and (max-width: 479px)
  {
    clear: both;
    overflow-y: auto;
    overflow-x: visible;
  }
}
.payment-details-container{
  margin-top: 60px;
}
.card-icons-outer
{
  display: grid;
  grid-template-columns: 1fr 4fr;
  margin-top: 10px;
  @media all and (max-width: 479px)
  {
    display: block;
    margin-top: 5px;
  }
  .icon-outer {
    width: 140px;
    @media all and (max-width: 479px)
    {
      width:130px;
      img {
        width: 100%;
      }
    }
  }
}
.State-zip-code
{
  .input-text-outer
  {
    width: 200px;
  }
}
.agree-terms {
  p {
    margin-top: 40px;
    font-size: 14px;
    color: $label-color;
    a {
      text-decoration: underline;
      color: $label-color;
    }
  }
}
.amount-submit-container{

  @media all and (max-width: 479px)
  {
    margin-top: 30px;
  }
  .label,.price {
      line-height: 35px;
      display: inline-block;
      @media all and (max-width: 479px)
      {
        float: left;
      }
  }
  .label {
    padding-right: 10px;
    @media all and (max-width: 479px)
    {
      padding-right: 5px;
      float: left;
    }
  }
  .price {
    color: $theme-blue;
        font-size: 25px;
  }
}
.questions_container{
  background: white;
  width: 100%;
  overflow: auto;
  padding: 0px 50px;
  box-sizing: border-box;
  padding-bottom: 30px;
  padding-top: 10px;
  @media all and (max-width: 1060px)
  {
    padding: 0px 25px;
    padding-bottom: 30px;
  }
}
.StripeElement{
  height: max-content;
}

.StripeCvv
{
  .StripeElement {

    @media all and (max-width: 479px)
    {
      float: left;
      width: 82%;
    }
  }
}
.payment-content {
  display: grid;
  grid-template-columns: 2fr 1fr;
  grid-column-gap: 50px;
  @media all and (max-width: 1060px)
  {
    grid-column-gap: 20px;
  }
  @media all and (max-width: 991px)
  {
    display: block;
  }
}
.payment__Right {
  @media all and (max-width: 991px)
  {
    margin-bottom: 25px;
  }
}
