.home-banner-bg {
  display: grid;
  grid-template-columns: 5fr 7fr;
  z-index: 10;
  overflow: auto;

  @media only screen and (max-width: 991px) {
    display: none;
  }
}

.home-banner-bg__left {
  background-color: $theme-blue;
  min-width: 0;
  min-height: 0;

  img {
    object-fit: cover;
    overflow: hidden;
    width: 100%;
    max-height: 820px;
  }
}

.home-banner__vid {
  position: absolute;
  right: 0;
  bottom: 0;
  height: 100%;
}
.home-banner-bg__right {
  position: relative;
  overflow: hidden;
  min-height: 820px;
  @media only screen and (max-width: 991px) {
    display: none;
  }
  .vid-overlay {
    background: rgba(8, 172, 231, 0.57);
    position: absolute;
    right: 0;
    bottom: 0;
    min-width: 100%;
    min-height: 100%;
    z-index: 2;
  }
}
.home-banner-container {
  position: relative;
  z-index: 0;
  a{
    text-decoration: none;
  }
  @media only screen and (max-width: 991px) {
    z-index: 1;
    position: relative;
  }
}
.home-banner-content {
  display: grid;
  grid-template-columns: 7fr 5fr;
  margin-top: 105px;
  align-items: end;

  @media only screen and (max-width: 991px) {
    display: block;
  }
}

.home-banner-content-wrapper {
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 4;
  @media only screen and (max-width: 991px) {
    position: static;
    background: $theme-blue;
    padding-bottom: 150px;
  }
}

.home-banner-content__left-content {
  text-align: center;
  padding: 30px 40px;
  padding-bottom: 20px;
  background: white;
  box-shadow:2px 3px 12px 0px rgba(1, 1, 1, 0.09);
  -webkit-box-shadow:2px 3px 12px 0px rgba(1, 1, 1, 0.09); /* Safari 3-4, iOS 4.0.2 - 4.2, Android 2.3+ */
  -moz-box-shadow:   2px 3px 12px 0px rgba(1, 1, 1, 0.09);  /* Firefox 3.5 - 3.6 */
  @media only screen and (max-width: 1030px) and  (min-width: 992px) {
      padding: 30px 30px;
  }
  @media only screen and (max-width: 991px) {
    padding: 0;
    background: $theme-blue;
    box-shadow: none;
    -webkit-box-shadow: none; /* Safari 3-4, iOS 4.0.2 - 4.2, Android 2.3+ */
    -moz-box-shadow: none;  /* Firefox 3.5 - 3.6 */
   }
}
.home-banner-content__left-content > div > a:last-child {

  @media only screen and (max-width: 449px) {
    grid-column: 1 / 3;
  }

}

.single-col {
  background-color: $white;
  padding: $spacing-unit * 2 $spacing-unit;

  @media only screen and (max-width: 991px) {
      padding: 35px 10px;
   }
  img{
    max-width: 45px;
    margin-bottom: 8px;
    transform: scale(1);
    transition: all .2s ease-in-out;
  }

  &:hover img {
    transform: scale(1.1);
  }

  &:hover{
    background-color: #f9f9f9;
    transition: 0.3s;
  }
}

.home-banner-content__left-grid {
  display: grid;
  grid-template-columns: repeat(5,1fr);
  background-color: #ebebeb;
  margin-top: 20px;
  box-shadow: 0px 0px 15px rgba(1,1,1,0.09);
  grid-gap: 3px;
  .seamless-exp-outer & {
    text-align: center;
  }



  @media only screen and (max-width: 991px) {
    background-color: $theme-blue;
    box-shadow: none;
    grid-gap: 3px;
  }

  @media only screen and (max-width: 620px) {
    grid-template-columns: repeat(3,1fr);
  }

  @media only screen and (max-width: 449px) {
    grid-template-columns: repeat(2,1fr);
  }

  a{
    background-color: $white;
    transition: 0.3s;

    &:hover img {
      transform: scale(1.1);
    }

    &:hover{
      background-color: #f9f9f9;
      transition: 0.3s;
    }
  }

  @media only screen and (max-width: 1030px) and  (min-width: 992px) {
      grid-auto-rows: 130px;
  }
}
.home-banner-content__right {
  min-width: 0;
  min-height: 0;
}
.home-banner-content__right-content {
  padding-bottom: 5px;
  margin-left: 70px;
  overflow: hidden;
  margin-bottom: 65px;

  @media only screen and (max-width: 991px) {
    text-align: center;
    max-width: 500px;
    margin: 0 auto;
    margin-top: 50px;
  }

}

.slick-dots li {
  .home-banner-content__right-content & {
    margin: 0 1px;
  }
}

.slick-dots {
  .home-banner-content__right-content & {
    bottom: -10px;
    text-align: left;

      @media only screen and (max-width: 991px) {
        bottom: 8px;
        text-align: unset;
      }
  }
}
.home-banner-content__right-content {
  .slick-dots li button {
    font-size: 0px;
    line-height: 0;
    display: block;
    width: 20px;
    height: 20px;
    padding: 5px;
    cursor: pointer;
    color: transparent;
    border: 0;
    outline: 0;
    background: 0 0;
    margin: 0;
  }
  .slick-dots li button:before {
    font-size: 0px;
    line-height: 20px;
    position: absolute;
    top: 0;
    left: 0;
    width: 12px;
    height: 12px;
    content: '•';
    text-align: center;
    opacity: .5;
    color: #fff;
    background: white;
    border-radius: 71%;
  }
  .slick-dots li.slick-active button:before {
    opacity: 1;
  }
}
#nounderline{
 
  color: $black;
  font-family: $font-family;
  font-size: 16px;
  font-weight: normal;
  @media all and (max-width:991px){
    display: none;
  }

  a {
    color: $theme-blue;
    font-weight: 600;
  }

  .home-banner-content__left-content & {
      margin: 20px 0 0;

      #underline{
        text-decoration: underline;
        padding: 0 2px;
      }
  }
}
.home-banner-content__left-content {
  .sub-heading {
 
    @media only screen and (max-width: 991px) {
      display: none;
    }
  }
}
.download_chrome{
  display: inline-block;
  margin-top: 30px;
  background: white;
  border-radius: 56px;
  padding: 6px 25px;
  padding-right: 40px;
  transition: 0.3s;
  cursor: pointer;

  @media only screen and (max-width: 991px) {
    display: none;
  }

  a{
    display: flex;
    img{
      width: 25px;
      height: 25px;
    }
  }
&:hover {
  background: #e7e7e7;
  transition: 0.3s;
}
}
