@import './var';

  .login__box-forget-password {
    color: $label-color;
    font-size: 15px;
    font-family: $font-family;
  }

label{
  font-family: $font-family;
  text-align: left;
  color: #a2a2a2;
  font-size: 16px;
  font-weight: 300;
}

h1 {
  font-family: $font-family;
  color: $white;
  font-size: 30px;

  .functionality-heading & {
    margin: 0;
    color: $white;
    font-size: 30px;
    line-height: 36px;
    font-weight: 500;
  }
  .page-404 & {
    font-size: 45px;
    font-weight: 600;
    color: $black;
    margin-bottom: 12px;
  }

  .drink-slider__donate-content &{
    color: white;
    text-transform: lowercase;
    @media only screen and (min-width: 991px) {
      font-size: 30px;
    }
    @media only screen and (max-width: 767px) {
      font-size: 26px;

    }
  }

  .login__box-right-content & {
    color: $white;
    font-weight: 100;
    font-size: 36px;
  
    margin: 0;
    margin-bottom: 15px;
    span{
      // font-weight: 600;
    }

    @media only screen and (max-width: $extra-small-breakpoint) {
      font-size: 31px;
    }
  }

  .thank-you__content & {
    color: $white;
    margin: 0;
    font-size: 36px;
    font-weight: 600;
    span {
      font-weight: 300;
    }

    @media only screen and (max-width: $medium-breakpoint) {
      font-size: 32px;
    }
  }
  .payment & {
    span {
      color: $theme-blue;
    }
  }
}

h2 {
  font-family: $font-family;
  color: $white;
  font-size: 27px;

  .file-download-top__details &{
    font-size: 17px;
    font-weight: 300;
    span{
      font-weight: 600;
    }
  }

  .file-download-top__right & {
    font-weight: 300;
    margin-bottom: 0;

    span {
      font-weight: 600;
    }
  }

  .functionality-heading & {
    margin: 0px 0 30px;
    font-size: 21px;
    line-height: 30px;
    font-weight: 300;
   }

  .inner-page__all-functions & {
    color: $black;
    text-align: center;
    font-weight: 300;
    margin: 0;
    padding-bottom: 2px;
  }

  .page-404 & {
    color: $black;
    font-size: 21px;
    margin: 0;
    font-weight: 300;
  }

  .login__box-right-content & {
    font-size: 25px;
    font-weight: 300;
    margin: 0;

    @media only screen and (max-width: $extra-small-breakpoint) {
      font-size: 20px;
    }
  }

}

h3 {
  font-family: $font-family;
  color: $black;
  font-size: 27px;

  .news-search__result-block & {
    font-weight: 300;

    span{
      font-weight: 600;
    }
  }
  .payment__Left & {
    margin-top: 0;
  }
  .faq-block__right &, .faq-content &{

    font-weight: 500;
    -webkit-margin-before: 0;
    -webkit-margin-after: 0;
    width: 90%;
    @media only screen and (max-width: 468px) {
      font-size: 24px;
    }
  }
  .faq-block__right &{
    color: #868585;
  }

  .faq-content &{
    color: #08abe6;
  }
  .RRT__tab--selected &{
    color: #000;
    font-weight: 500;
  }
  .top-stories & {
    font-size: 18px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    max-height: 45px;
    margin: 0;
    font-weight: normal;
  }

  .editors-picks_content & {
    color: #fff;
    margin: 0;
    font-size: 20px;
    font-weight: 500;
    max-width: 400px;
  }

  .news__listing & {
    font-size: 18px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    max-height: 45px;
    margin: 0;
    font-weight: normal;
  }

  .all-functions__category-col & {
    color: $theme-blue;
    font-size: 17px;
    font-weight: 500;
    margin: 0;
    margin-bottom: 25px;
  }
  .drink-donate__top-banner-wrap &{
    color: white;
    .heading-topline-bold{
      color: white;
      text-shadow: 1px 0px #ffffff;
      font-weight: 900;
    }
    &::after{
      content: none ;
    }
  }
  .payment & {
    span {
      color: $theme-blue;
    }
    &.payment__Left {
      padding-top: 40px;
    }
  }
  .questions_container & {
    margin-bottom: 10px;
  }

  .donation-search & {
    font-weight: 300;
    margin: 0;
    margin-bottom: 45px;
    span {
      color: $theme-blue;
      font-weight: 600;
    }
  }

  .all-rights-reserved & {
    color: #989898;
    text-align: right;
    font-size: 12px;
    @media all and (max-width: 479px)
    {
      position: absolute;
      bottom: 10px;
      margin: 0 auto;
    }
  }

  .login__box & {
    padding-top: 4px;
    font-weight: normal;
    display: inline-block;
    position: relative;
    margin: 0;
    margin-bottom: 35px;

    &::after{
      content: '';
      width: 100%;
      height: 5px;
      background: $theme-blue;
      position: absolute;
      top: -2px;
      left: 0;
      border-radius: 10px;
    }
  }

}

.heading-top-line {
  font-family: $font-family;
  position: relative;
  display: inline-block;
  margin: 0;
  color: $black;
  font-size: 28px!important;
  font-weight: normal;
  padding: 18px;
  padding-left: 0;
  text-align: left;
  -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome and Opera */

  &::after{
    content: '';
    width: 60%;
    height: 8px;
    background: $theme-blue;
    position: absolute;
    top: 0px;
    border-radius: 10px;
    left: 0;
    @media only screen and (max-width: 450px) {
      height: 6px;
      top: 5px;
    }
    .home-banner-content__right-content &{
      left: 15%;
    }
  }

    @media only screen and (max-width: 450px) {
      font-size: 23px !important;
    }
  .news_category-landing & {
    color: white;
    font-weight: 600;

    &::after{
      width: 88%;
      background: $white;
    }
  }

  .news-category-Search-page &{
    color: white;
    font-weight: 600;

    &::after{
      width: 88%;
      background: $white;
    }
  }

  .news__banner-content & {
    color: $white;

    span{
      color: $white;
    }

    &::after{
      width: 30%;
      background: $white;
    }
  }

  .news-feed__grid-left & {
    padding-left: 0;
    font-size: 28px;
    &::after{
      left: 0;
    }
  }
  .drink-donate__top-banner-wrap &{
    padding-left: 18px;
  }
  .privacy-policy__container & {
    &::after{
     //left: 7%;
     width: 92%;
     //margin: 0 auto;
    }
  }
     .terms-conditions__container &{
    &::after{
     left: 7%;
     width: 80%;
     //margin: 0 auto;
    }
  }

  .homePage-bottomGrid__grid-5 & {
    color: $white;
    display: block;
    padding-left: 0;
    font-size: 27px;

    .heading-topline-span {
      color: $white;
      @media screen and (max-width:360px) {
        
        display: flex;
        
        
      }
    }
    &::after{
      width: 32%;
      left: 0;
      background: $white;
    }
  }
  .homePage-bottomGrid__grid-1 & {
    text-align: left;
    padding-left: 0;
    font-size: 30px;

    &::after{
      left: 0;
      width: 36%;
    }
  }

  .counter-data & {
    color: #004163;
    font-size: 30px;
    text-align: left;
    padding-left: 0;
    margin-bottom: 30px;

    &::after{
      width: 55%;
      left: 0;
      background: $white;
    }

    .heading-topline-span{
      color: #004163;
      text-transform: uppercase;
    }

  }

  .donation__left & {
    padding-left: 0;
    text-align: left;
    line-height: 30px;
    &::after{
      width: 45%;
      left: 0;
    }
  }
  .join-community__left-content & {
    text-align: left;
    color: $white;
    padding: 18px 0;
    display: block;
    &::after{
      width: 40%;
      left: 0;
      background: $white;
    }

    .heading-topline-span {
      color: $white;
    }
  }
  .home-banner-content__right-content & {
    padding-left: 0;
    color: $white;

    &::after{
      width: 75%drink-slider__donate-content;
      left: 0;
      background: $white;
    }
  }
  .home-banner-content__left-content & {

    .heading-topline-span {
        @media only screen and (max-width: 991px) {
            color: $white;
        }
    }

    &::after{
      right: 20px;
    }
  }

  .heading-topline-span {
    color: $theme-blue;
    font-weight: 600;

    .home-banner-content__right-content & {
      color: $white;
     }
  }
  .seamless-exp-outer & {
    @media all and (max-width: 767px)
    {
      text-align: center;
      padding-right: 0;
    }
    .heading-topline-span {
        color: $white;
    }
    &::after{
      background: #ffffff;
      @media all and (max-width: 767px)
      {
        left: 22%;
      }
    }
  }
}

h6 {
  font-family: $font-family;
  margin: 0;
  color: $black;
  font-size: 14px;
  font-weight: normal;

  .home-banner-content__left-grid & {
    @media all and (max-width: 620px)
    {
      font-size: 17px;
    }
  }

  .news-detail__category-wrapper & {
    color: $black;
    font-size: 16px;
    background: #efefef;
    text-align: center;
    padding: 6px 5px 7px;
    border-radius: 3px;
  }

  .news-category-single__wrapper & {
    font-size: 30px!important;
    max-width: 80%;
    margin: 0 auto;
    color: $white;
  }

  .news-single__content & {
    font-size: 18px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    max-height: 45px;
  }
}
button{
  .accordion-item &{
    color: #000;
    font-family: $font-family;
    font-size: 18px;
    font-weight: lighter;
    @media only screen and (max-width:  468px) {
      font-size: 14px;
    }
  }
}
p{
  color: $white;
  font-family: $font-family;
  margin: 0;
  font-size: 16px;
  font-weight: normal;

  .news-detail__wrapper & {
    color: $black;
    font-size: 20px;
    margin-bottom: 35px;
  }
  .faq-block__right &, .faq-content &{
    color: #868585;
  }
  .faq-block__right &{
    width: 90%;
  }
  .faq-content &{
    font-size: 22px;
    padding: 5px 0;
    @media only screen and (max-width: 468px) {
      font-size: 16px;
    }
  }
  .questions_container &{
    font-size: 14px;
    width: 90%;
    @media only screen and (max-width: 468px) {
      font-size: 12px ;
    }
  }
  .accordion-item &{
    font-size: 16px;
    width: 90%;
    @media only screen and (max-width: 468px) {
      font-size: 14px ;
    }
  }


  .RRT__tab--selected &{
    color: #000;
    font-weight: 400;
    width: 90%;
    @media only screen and (max-width: 468px) {
      font-size: 14px;
    }
  }
  .text-content-outer & {
    margin: 20px 0;
  }
  .drink-donate__top-banner-wrap &
  {
    padding-bottom: 5px;
  }

  .drink-donate__slider &{
  color: $black;
  line-height: 20px;
  font-size: 14px;
  @media only screen and (min-width: 1025px) {
    font-size: 16px;
  }
}

  .new-stories & {
    margin: 10px 0;
    font-size: 13px;
    color: #aaa;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    max-height: 47px;
  }

  .new-stories-featured__single & {
    margin: 10px 0;
    font-size: 13px;
    color: #aaa;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 9;
    -webkit-box-orient: vertical;
    max-height: 135px;
  }


  .inner-page__all-functions & {
      font-size: 20px;
      color: #a5a3a3;
      font-weight: 300;
      text-align: center;
   }
   .drink-slider__donate-content &{
     color: white;
     text-align: left;
   }
  .progress-bar & {
    font-weight: 600;
    margin-top: 20px;
    text-align: center;
  }

  .donation-search__result-block--single & {
    font-size: 17px;
    padding: 25px 15px;
    line-height: 22px;
    color: $black;
  }

  .thank-you__content & {
    margin: 20px 0 5px;
    font-size: 25px;

    @media only screen and (max-width: $extra-small-breakpoint) {
      font-size: 18px;
    }
  }

  .thank-you__share & {
    font-size: 25px;
    color: $black;

    @media only screen and (max-width: $extra-small-breakpoint) {
      font-size: 18px;
    }

    span {
      color: $theme-blue;
      font-weight: 600;
    }
  }

  .donation__right-img-details & {
    text-align: center;
    position: absolute;
    bottom: 20px;
    font-size: 16px!important;
    width: 100%;
    color: $white!important;
    margin: 0!important;
    -webkit-touch-callout: none; /* iOS Safari */
      -webkit-user-select: none; /* Safari */
       -khtml-user-select: none; /* Konqueror HTML */
         -moz-user-select: none; /* Firefox */
          -ms-user-select: none; /* Internet Explorer/Edge */
              user-select: none; /* Non-prefixed version, currently
                                    supported by Chrome and Opera */

    span {
      font-weight: 600;
    }
  }
  .payment & {
      color:#444;
      font-size: 18px;
  }
  .reciept-msg & {
    font-size: 12px;
  }

  .news-feed__grid-right & {
    margin: 10px 0;
    font-size: 13px;
    color: #aaa;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    max-height: 47px;
  }
.privacy-policy__container &, .terms-conditions__container &{
  color: #000;
  padding: 10px 0;
  @media only screen and (max-width: 767px) {
    font-size: 14px;
  }
}
  .news-feed__grid-left & {
    color: $black;
    margin-top: 10px;
    margin-bottom: 20px;
    padding-right: 30px;
  }

  .news-feed__grid-dropdown & {
    display: inline-block;
    float: left;
    color: $black;
    padding: 12px 0;
    font-size: 17px;
    margin: 0;
    margin-right: 15px;
  }

  .homePage-bottomGrid__grid-4 & {
    color: $black;
    padding-left: 60px;
    padding-top: 90px;
    padding-right: 80px;
    font-size: 18px;
    line-height: 25px;

    @media only screen and (max-width: 1200px) {
      padding-top: 50px;
      padding-left: 40px;
    }

  }

  .counter-data__single-col & {
    color: #004163;
    font-size: 18px;
    max-width: 140.5px;
    font-weight: 500;
  }

  .donation__left & {
    margin-top: 4px;
    margin-left: 13px;
    color: #afafaf;
    font-size: 14px;
  }

  .home-banner-content__right-content & {
    margin-bottom: $spacing-unit *2;
  }

  .join-community__right-content & {
    color: $black;
  }

}

.sub-heading {
  color: $black;
  font-family: $font-family;
  font-size: 16px;
  font-weight: normal;

  a {
    color: $theme-blue;
    font-weight: 600;
  }

  .home-banner-content__left-content & {
      margin: 20px 0 0;

      span {
        text-decoration: underline;
        padding: 0 2px;
      }
  }

  .download_chrome & {
    margin: 3px 10px;
    font-size: 15px;
  }
}

h4{
  margin: 0;
  color: $black;
  font-family: $font-family;
  font-size: 16px;
  font-weight: normal;

  .donation__left & {
    padding: 3px 0;
    padding-left: 10px;
    max-width: 270px;
    border-left: solid 3px black;
  }
}

h5 {
  font-family: $font-family;
  color: $white;
  font-size: 30px;
  margin: 0px 0 12px;
}
.heading-bold {
      font-weight: 300;
      font-size: 35px;
      .BottomBanner-outer & {
        color: $white;
      }
}
