.tool-tip{
  width: 20px;
  height: 20px;
  border-radius: 50%;
  text-align: center;
  background: $theme-blue;
  color: $white;
  line-height: 21px;
  font-weight: 600;

  .tooltiptext {
      visibility: hidden;
      width: 120px;
      font-size: 13px;
      background: white;
      color: #333;
      border: solid 1px;
      text-align: center;
      border-radius: 6px;
      padding: 5px 0;

      /* Position the tooltip */
      position: absolute;
      z-index: 1;
      margin-top: 31px;
      .StripeCvv & {
        @media all and (max-width: 479px)
        {
          right: 9%;
        }
      }
  }
  &:hover
  {
    .tooltiptext {
        visibility: visible;
    }
  }
  .StripeCvv & {
    margin-top: 10px;
    margin-left: 10px;
    @media all and (max-width: 479px)
    {
      float: left;
      width: 20px;
      height: 20px;
      margin-left: 5px;
    }
  }

  .make-donation-outer & {
    @media all and (max-width: 530px)
    {
      float: left;
      margin-left: 5px;
    }
  }
}
