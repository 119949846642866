$font-family:                                 'Roboto', sans-serif !default;
$max-width-content:                            1200px !default;
$spacing-unit:                                 10px !default;
$black:                                        #000 !default;
$white:                                        #fff !default;
$theme-blue:                                   #08abe6 !default;
$btn-bg:                                       #ef4800 !default;
$footer-bg-grey:                               #eeeeef !default;
$input-grey-border:                           #afa9a9 !default;
$place-holder-grey:                            #828282 !default;



$wide-breakpoint:                               991px !default;
$medium-breakpoint:                             767px !default;
$small-breakpoint:                              620px !default;
$extra-small-breakpoint:                        450px !default;



$label-color:                                  #a2a2a2 !default;
