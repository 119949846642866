.validation-error {
  display: none;
  opacity: 0;
}

.login,.sign-up {
  position: relative;
  z-index: 2;
}

.validation-error-active {}
  .validation__required {
    text-align: left;
    max-width: 350px;
    margin: 0 auto;
    display: grid;
    grid-template-columns: 1fr 6fr;
    align-items: center;
    background: #ff000012;
    padding: 10px;
    border-radius: 5px;

    ul {
      margin: 0;
      list-style: unset;
      margin-left: 20px;
      align-self: center;

      li {
        font-family: $font-family;
        color: #ec0606;
        font-weight: normal;
        margin: 0;
        font-size: 16px;
        letter-spacing: 0;
        margin-bottom: 5px;
      }
    }

    .sign-up & {
      align-items: unset;

    }
    img {
      width: 25px;
      text-align: center;
      margin: 0 auto;
    }

    p{
      color: #ec0606;
      font-weight: normal;
    }
  }

.login__box {
    -webkit-box-shadow: 0px 1px 13px rgba(0,0,0,0.09);
    -moz-box-shadow:0px 1px 13px rgba(0,0,0,0.09);
    box-shadow: 0px 1px 13px rgba(0,0,0,0.09);
    display: grid;
    grid-template-columns: 1fr 1fr;
    margin: 155px 0 50px;

    @media only screen and (max-width: $wide-breakpoint) {
      display: block;
      margin: 155px 25px 50px;
    }

    @media only screen and (max-width: $extra-small-breakpoint) {
      margin: 135px 0px 40px;
    }

}

.login__box-right {
  background-image: url('../images/login.jpg');
  position: relative;
  background-size: cover;
  background-position: center;
}


.login__box-right-content {
  display: inline-block;
  position: absolute;
  bottom: 100px;
  left: 50%;
  margin-left: -150px;
  text-align: center;
  max-width: 300px;

  @media only screen and (max-width: $wide-breakpoint) {
    position: static;
    margin: 0;
    display: block;
    max-width: 100%;
    padding: 50px 20px 30px;
  }
}

.login__box-left {
    text-align: center;
    padding: 80px 30px;

      @media only screen and (max-width: $extra-small-breakpoint) {
          padding: 50px 10px;
       }
}

.login__box-or-txt {
    font-family: $font-family;
    color: #929292;
    font-size: 20px;
    margin: 35px 0 20px;
}

.login__box-btn-set {
  img {
    width: 25px;
    //margin-top: -4px;
    margin:-4px auto 0px;


    @media only screen and (max-width: $extra-small-breakpoint) {
        margin-right: 15px;
    }

  }
  a{
    padding: 12px;
    color: $white;
    background: #3b5998;
    cursor: pointer;
    max-width: 320px;
    border-radius: 40px;
    margin: 0 auto;
    display: grid;
    grid-template-columns: 1fr 2fr;
    text-align: -webkit-center;
    transition: .2s;

    @media only screen and (max-width: $extra-small-breakpoint) {
          display: block;
    }

    p{
      display: inline-block!important;
      text-align: left;
    }

    &:hover {
      transition: .2s;
      box-shadow:1px 5px 30px rgba(0, 0, 0, 0.14);
    }
  }
  a + a {
    box-shadow: 0px 1px 13px rgba(0,0,0,0.14);
    margin-top: 15px;
      p{
        color: $black;

      }
    background: $white;
  }
}




.form-styles{
  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus
  textarea:-webkit-autofill,
  textarea:-webkit-autofill:hover
  textarea:-webkit-autofill:focus,
  select:-webkit-autofill,
  select:-webkit-autofill:hover,
  select:-webkit-autofill:focus {
  -webkit-text-fill-color: $black;
  -webkit-box-shadow: 0 0 0px 1000px $white inset;
  transition: background-color 5000s ease-in-out 0s;
  }
.group 			  {
  position:relative;
  max-width: 350px;
  margin: 0 auto;
  margin-bottom: 35px;

  .contact-outer & {
    max-width: unset;
    width: 100%;
  }
}

.inputMaterial 				{
  font-size: 16px;
  color: $black;
  padding:10px 10px 10px 5px;
  display:block;
  width:100%;
  box-sizing: border-box;
  border:none;
  font-family: $font-family;
  border-bottom: 1px solid $label-color;
  border-radius: 0px;
}

.inputMaterial:focus 		{ outline:none;}

/* LABEL ======================================= */

label 				 {
  color: $label-color;
  font-size:14px;
  font-weight:normal;
  position:absolute;
  pointer-events:none;
  left:5px;
  top:10px;
  letter-spacing: .5px;
  font-family: $font-family;
  transition:0.2s ease all;
  -moz-transition:0.2s ease all;
  -webkit-transition:0.2s ease all;
}

/* active state */
.inputMaterial:focus ~ label, .inputMaterial:valid ~ label 		{
  top:-20px;
  font-size:14px;
  color: $theme-blue;
}

/* BOTTOM BARS ================================= */
.bar 	{ position:relative; display:block; width:100%; }
.bar:before, .bar:after 	{
  content:'';
  height:1px;
  width:0;
  bottom:1px;
  position:absolute;
  background: $theme-blue;
  transition:0.2s ease all;
  -moz-transition:0.2s ease all;
  -webkit-transition:0.2s ease all;
}
.bar:before {
  left:50%;
}
.bar:after {
  right:50%;
}

/* active state */
.inputMaterial:focus ~ .bar:before, .inputMaterial:focus ~ .bar:after {
  width:50%;
}


/* active state */
.inputMaterial:focus ~ .highlight {
  -webkit-animation:inputHighlighter 0.3s ease;
  -moz-animation:inputHighlighter 0.3s ease;
  animation:inputHighlighter 0.3s ease;
}

/* ANIMATIONS ================ */
@-webkit-keyframes inputHighlighter {
	from { background:#5264AE; }
  to 	{ width:0; background:transparent; }
}
@-moz-keyframes inputHighlighter {
	from { background:#5264AE; }
  to 	{ width:0; background:transparent; }
}
@keyframes inputHighlighter {
	from { background:#5264AE; }
  to 	{ width:0; background:transparent; }
}

.login & {
  margin-bottom: 20px;
  margin-top: 45px;
}
.sign-up & {
  margin-bottom: 20px;
  margin-top: 15px;
}
}


.forget-password__wrapper {
  -webkit-box-shadow: 0px 1px 13px rgba(0, 0, 0, 0.09);
    -moz-box-shadow: 0px 1px 13px rgba(0, 0, 0, 0.09);
    box-shadow: 0px 1px 13px rgba(0, 0, 0, 0.09);
    display: grid;
    grid-template-columns: 1fr 1fr;
    margin: 185px 0 50px;

    @media only screen and (max-width: $wide-breakpoint) {
          display: block;
    }

    .forget-password--change & {
      margin: 150px 0 50px;
    }


    .forget-password__left {
          text-align: center;
      h1 {
        padding-top: 4px;
        font-weight: normal;
        display: inline-block;
        position: relative;
        margin: 0;
        margin-bottom:60px;
        color: $black;

        &:after {
          content: '';
          width: 100%;
          height: 5px;
          background: #08abe6;
          position: absolute;
          top: -2px;
          left: 0;
          border-radius: 10px;
        }
      }
    }
}

.forget-password--change .form-styles .group {
    margin-bottom: 35px!important;
}
.forget-password--change {
  form {
    p{
      margin-top: 30px;
    }
  }
}

.forget-password--change {
  .theme-btn {
    border: none;
  }
}

.form-styles .group {
  .forget-password & {
    margin-bottom: 15px;
  }
}

.forget-password__left {
  .forget-password & {
    padding: 80px 20px;
    p{
      color: red;
    }
  }
}
