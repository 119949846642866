.homePage-bottomGrid__grid {
  display: grid;
  grid-template-columns: 6fr 1fr 7.4fr;
  grid-template-rows: auto auto 100px auto;

  @media only screen and (max-width: 991px) {
    display: block;
  }

  img {
    vertical-align: middle;
  }


}




.heading-top-line{
 
  @media all and (max-width:400px){
    font-size:21px!important;
  }


}
.homePage-bottomGrid__grid-1 {
  grid-column: 1 / 2;
  display: grid;
  grid-template-columns: auto minmax(auto, 450px);
  background-color: #F7F7F7;
  align-items: center;
  padding-left: 20px;

  @media only screen and (max-width: 991px) {
    display: block;
    background-color: $white;
    max-width: 80%;
    margin: 0 auto;
    padding: 65px 0px 40px;
  }

  @media only screen and (max-width: 620px) {
    max-width: unset;
    margin: 0 20px;
  }

  p{
    color: $black;
    font-size: 18px;
    line-height: 25px;
    display: none;

    @media only screen and (max-width: 991px) {
      display: block;
    }
  }

  @media only screen and (min-width: 1300px) {
    grid-template-columns: auto minmax(auto, 500px);
  }
}

.homePage-bottomGrid__grid-2 {
  grid-column: 2 / 4;

  .homePage-bottomGrid__img-wrapper > div {
    overflow: hidden;

      img {
        -webkit-filter: grayscale(100%);
        filter: grayscale(100%);
        -webkit-transform: scale(1);
        transform: scale(1);
        -webkit-transition: all .3s ease-in-out;
        transition: all .3s ease-in-out;
      }

      &:hover img{
        -webkit-transform: scale(1.1);
        transform: scale(1.1);
      }
  }

  @media only screen and (max-width: 991px) {
    margin-bottom: -50px;
    z-index: 1;
    position: relative;
  }
}

.homePage-bottomGrid__grid-3 {
  grid-column: 1 / 2;
  display: grid;
  grid-template-columns: auto minmax(auto, 450px);
  background-color: #F7F7F7;

  .homePage-bottomGrid__img-wrapper > div {
    overflow: hidden;
      img {
        -webkit-filter: grayscale(100%);
        filter: grayscale(100%);
        -webkit-transform: scale(1);
        transform: scale(1);
        -webkit-transition: all .3s ease-in-out;
        transition: all .3s ease-in-out;
      }
      &:hover img{
        -webkit-transform: scale(1.1);
        transform: scale(1.1);
      }
    }
  @media only screen and (max-width: 991px) {
    display: none;
  }

  @media only screen and (min-width: 1300px) {
    grid-template-columns: auto minmax(auto, 500px);
  }
}

.homePage-bottomGrid__grid-6 {
  overflow: hidden;
  img {
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-transition: all .3s ease-in-out;
    transition: all .3s ease-in-out;
  }
  &:hover img{
    -webkit-transform: scale(1.05);
    transform: scale(1.05);
  }
}

.homePage-bottomGrid__gallery-wrapper {
  overflow: hidden;
  img {
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-transition: all .3s ease-in-out;
    transition: all .3s ease-in-out;
  }
  &:hover img{
    -webkit-transform: scale(1.05);
    transform: scale(1.05);
  }
}

.homePage-bottomGrid__grid-4 {
  background-color: #F7F7F7;
  grid-column: 2 / 4;
  grid-row: 2 / 4;
  display: grid;
  grid-template-columns: minmax(auto, 820px) auto;

  @media only screen and (max-width: 991px) {
    display: none;
  }
}

.homePage-bottomGrid__img-wrapper {
  display: grid;

  img {
    width: 100%;
    object-fit: cover;
    object-position: center;

      @media only screen and (max-width: 991px) {
        height: 250px;
      }
  }

  .homePage-bottomGrid__grid-3 & {
    grid-template-columns: 1fr 1fr;
  }

  .homePage-bottomGrid__grid-2 & {
    grid-template-columns: 1fr 1fr 1fr 1fr;

    @media only screen and (max-width: 1300px) {
      grid-template-columns: 1fr 1fr 1fr;
    }

    div:nth-child(4) {

      @media only screen and (max-width: 1300px) {
        display: none;
      }
    }
  }
}

.homePage-bottomGrid__grid-5 {
    grid-column: 1 / 3;
    background: $theme-blue;
    display: grid;
    grid-template-columns: auto minmax(auto, 630px);
    grid-row: 3 / 5;
    align-items: center;
    padding-left: 20px;

    @media only screen and (max-width: 991px) {
      display: block;
      max-width: 80%;
      margin: 0 auto;
      padding: 60px 35px 35px;
      z-index: 2;
      position: relative;
    }

    @media only screen and (max-width: 620px) {
      max-width: unset;
      margin: 0 20px;
    }

    @media only screen and (max-width: 1300px) {
      grid-template-columns: auto minmax(auto, 530px);
    }
}
.homePage-bottomGrid__grid-6 {
    grid-column: 3 /4;
    position: relative;

    @media only screen and (max-width: 991px) {
        display: none;
     }

    img {
      width: 100%;
      max-height: 260px;
      object-position: center;
      object-fit: cover;
    }
}


.homePage-bottomGrid__country {
  position: absolute;
  bottom: 30px;
  left: 30px;
  font-family: $font-family;
  font-weight: 600;
  font-size: 22px;
  color: $white;

  .homePage-bottomGrid__gallery-wrapper & {
    bottom: 50px;
    right: 40px;
    left: auto;
  }

}
.homePage-bottomGrid__gallery > div:nth-child(4) {
  display: none;

    @media only screen and (max-width: 991px) {
          display: block;
     }
}
.homePage-bottomGrid__gallery {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;

  @media only screen and (max-width: 991px) {
    grid-template-columns: 1fr 1fr;
    margin-top: -35px;
  }

  @media only screen and (max-width: 480px) {
    // grid-template-columns: 1fr;
    // grid-auto-rows: 150px;
    display: none;
  }


  img {
    width: 100%;
    max-height: 260px;
    object-position: center;
    object-fit: cover;
    vertical-align: middle;
    height: 100%;
  }

  .homePage-bottomGrid__gallery-wrapper {
    position: relative;
  }
}

.slick-slide {
  /* ... */
  position: relative;
}
.home-mobile-slider{
  // background: rgba(233, 233, 233, 0.768);
overflow: hidden;
  display: none;
  position: relative;
  top:-20px;

  // width:87%;
  // margin:0 auto;
  // padding: 0px;
  .homePage-bottomGrid__gallery-wrapper1{

    // margin-right:100px;
   img{
    //  margin:10px;
     width:100%;
     border-left: 8px solid white;
     border-right: 8px solid white;
     box-sizing:border-box;
   }
   .homePage-bottomGrid__country1 {
    position: absolute;
    bottom: 40px;
    right: 30px;
    font-family: $font-family;
    font-weight: 600;
    font-size: 18px;
    color: $white;
    @media all and (max-width:380px){
      bottom: 20px; font-size: 15px;
    }
  }
  }
  @media screen and (max-width:480px){
    display: block;

  }
  @media screen and (max-width:405px){
    top:-10px;
  }
}