.accordion
{
  .questions_container &{
    border: none;
  }
  .inner-page__all-functions & {
    border: none;
    display: none;
    margin-top: 50px;

    @media all and (max-width: 991px)
    {
      display: block;
    }
  }
  .title
  {
    margin: 0;
    font-family: $font-family;
    background: none !important;
    .questions_container &{
      padding-left: 0px !important;
        padding-right: 0px !important;
    }

    .inner-page__all-functions & {
        color: $theme-blue!important;
        font-size: 18px;
        font-weight: 500;
    }
  }
  .panel {
    padding: 0px 20px;
    .questions_container &{
      padding-left: 0px;

    }

    ul {
      li {
        .inner-page__all-functions & {
          font-family: "Roboto", sans-serif;
          color: #000!important;
          font-size: 16px!important;
          font-weight: 300;
          letter-spacing: 0;
          margin-bottom: 16px;
        }
      }
    }
  }
  .accordion-item {
    .inner-page__all-functions & {
      border-bottom: 1px solid #ededee;
    }
    ul
    {
      .inner-page__all-functions & {
            margin-top: 0;
      }
      li {
        .inner-page__all-functions & {
          font-size: 12px;
          color: black;
        }
      }
    }
  }

}
