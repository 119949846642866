@import './var';


.home-page {
  position: relative;
  z-index: 2;
  
}

.donation-container {
  display: grid;
  grid-template-columns: 5fr 7fr;

  @media only screen and (max-width: $wide-breakpoint) {
    display: block;
  }

}

.donation__left {
    display: grid;
    grid-template-columns: auto minmax(auto, 450px);
    background: white;
    padding: 80px 0 60px;
    padding-left: 20px;

    @media only screen and (max-width: 991px) {
      display: block;
      max-width: 70%;
      margin: 0 auto;
      margin-top: -130px;
      z-index: 2;
      position: relative;
      padding: 40px 50px;
      -webkit-box-shadow: 0px 1px 13px rgba(0, 0, 0, 0.09);
    -moz-box-shadow: 0px 1px 13px rgba(0, 0, 0, 0.09);
    box-shadow: 0px 1px 13px rgba(0, 0, 0, 0.09);
    }

    @media only screen and (max-width: 620px) {
      margin: 0 20px;
      max-width: unset;
      margin-top: -130px;
    }

    @media only screen and (max-width: 449px) {
      margin: 0 10px;
      padding: 30px 25px;
      margin-top: -130px;
    }

    @media only screen and (min-width: 1300px) {
      grid-template-columns: auto minmax(auto, 500px);
    }

  .price-tag{
    color: $btn-bg;
    font-weight: 600;
  }

  ul{
    li{
      margin-bottom: 20px;
      letter-spacing: 0;
    }
  }
}
.donation__right {
  min-width: 0;
  min-height: 0;
  display: grid;
  grid-template-columns: repeat( auto-fit, minmax(250px, 1fr) );
  margin-top: -100px;
  z-index: 20;
  grid-auto-rows: 270px;

  @media only screen and (max-width: 991px) {
    margin: 0;
    z-index: 1;
    position: relative;
    margin-top: -50px;
    grid-template-columns: repeat( 4 , 1fr)!important;
    grid-auto-rows: 200px!important;
  }

  @media only screen and (max-width: $small-breakpoint) {
    margin-top: 20px;
    grid-template-columns: repeat( 3 , 1fr)!important;
  }

    @media only screen and (max-width: 450px) {
      grid-auto-rows: 120px!important;
    }

  img {
      vertical-align: middle;
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;
      -webkit-filter: grayscale(100%);
      filter: grayscale(100%);
      transform: scale(1);
      transition: all .5s ease-in-out;
  }

.donation_right--single-col:nth-of-type(12),.donation_right--single-col:nth-of-type(11),.donation_right--single-col:nth-of-type(10) {
  @media only screen and (max-width: 1371px) and  (min-width: 992px) {
    display: none;
  }
}
  @media only screen and (max-width: 1099px) and  (min-width: 992px) {
    grid-template-columns: repeat(auto-fit, minmax(190px, 1fr))!important;
    grid-auto-rows: 255px!important;
    margin-top: -75px;
  }
  @media only screen and (max-width: 1714px) {
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    grid-auto-rows: 263px;
  }

  .donation__right-img {
    height: 100%;
    overflow: hidden;
  }
}
.donation_right--single-col {
  position: relative;
  .donation__right-img-details {
    position: absolute;
    right: 0;
    bottom: 0;
    min-width: 100%;
    min-height: 100%;
    background: rgba(8, 172, 231, 0.57);
    transition: opacity .4s ease-out;
    opacity: 0;
    height: 0;
    overflow: hidden;
  }

  & :hover .donation__right-img-details {
    opacity: 1;
    height: auto;
  }
  &:hover .donation__right-img img {
    transform: scale(1.1);
  }
}
