/*
	- Document: PDFFLY FAQ Theme
	- Author:   sangeetha@terrificminds.com
*/
/*FAQ Banner section*/
.faq__top-banner-wrap {
  background-image: url('../images/faq-banner.png');
  padding: 250px 15px 240px 15px;
  text-align: center;
  color: white;
  background-size: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  @media only screen and (max-width: 767px) {
    padding: 155px 15px 40px 15px;
    background-position: -180px 0px;
    text-align: left !important;
  }
}
.faq{
  .donation-search__result-block--single{
    display: block !important;
    padding: 15px;
     p{
       padding:15px 0;
       &.name{
         border-bottom: 1px solid #ddd;
       }
    }
  }
}
/* FAQ tab section */
.faq__tab-content {
  margin: 60px 0;
  @media only screen and (min-width: 840px) {
    margin: 30px 0;
  }
  .RRT__container{
    margin: 15px 0;
    @media only screen and (min-width: 840px) {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-column-gap: 30px;
      margin: 40px 0;
    }
    .RRT__tabs{
      @media only screen and (min-width: 840px) {
        display: grid;
        grid-template-columns: 1fr;
        grid-gap: 20px;
        grid-auto-rows: 150px;
      }
      .RRT__tab {
        padding: 0;
        background: #fff;
        border: none;
        white-space: unset;
      }
      .RRT__tab--selected {
        border: none;
        .faq-block__right{
          position: relative;
          &:after {
            position: absolute;
            top: 25%;
            right: 0;
            content: "";
            display: block;
            background: url("../images/arrow.png") no-repeat;
            width: 35px;
            height: 35px;
            @media only screen and (max-width: 839px) {
              width: 17px;
              height: 31px;
              -webkit-transform: rotate(90deg);
              -moz-transform: rotate(90deg);
              -o-transform: rotate(90deg);
              -ms-transform: rotate(90deg);
              transform: rotate(90deg);
            }
          }
        }
      }
      .faq-block{
        -webkit-box-shadow: 0px 10px 46px rgba(0,0,0,0.05);
        -moz-box-shadow:0px 10px 46px rgba(0,0,0,0.05);
        box-shadow: 0px 10px 46px rgba(0,0,0,0.05);
        display: grid;
        grid-template-columns: 1fr 6fr;
        padding: 15px;
        align-items: center;
        @media only screen and (max-width: 767px) {
          padding: 5px;
        }
        &:hover {
           box-shadow: 0 8px 16px rgba(0,0,0,.18);
       }
        .faq-block__left, .faq-block__right{
          margin: 10px;
          vertical-align: middle;
          @media only screen and (max-width: 468px) {
            margin: 5px;
          }
          img{
            max-width: 80px;
            transform: scale(1);
            transition: all .2s ease-in-out;
            vertical-align: middle;
            @media only screen and (max-width: 468px) {
              max-width: 55px;
            }
          }
        }
      }
    }
    .RRT__panel {
      -webkit-box-shadow: 0px 10px 46px rgba(0,0,0,0.05);
      -moz-box-shadow:0px 10px 46px rgba(0,0,0,0.05);
      box-shadow: 0px 10px 46px rgba(0,0,0,0.05);
      border: none;
      display: grid;
      grid-template-columns: 1fr;
      align-items: center;
      padding: 2em;
      @media only screen and (max-width: 839px) {
        padding:0 5px;
        background: #eeeeef5e;
      }
      &:hover {
         box-shadow: 0 8px 16px rgba(0,0,0,.18);
     }
      .faq-content{
        .faq-display{
          @media only screen and (max-width: 839px) {
            display: none;
          }
        }
        .accordion {
          border: none;
          margin: 30px 0px;
          @media only screen and (max-width: 468px) {
            margin: 20px 0px;
          }
          .accordion-item{
            .title {
              padding: 18px 0;
              font-weight: 500;
              color: #868585;
              font-size: 18px;
              border-bottom: 1px solid #868585;
              @media only screen and (max-width: 768px) {
                font-size: 16px;
              }
              @media only screen and (max-width: 468px) {
                font-size: 14px;
              }
              &::after{
                font-size: 16px;
                border: 1px solid #777;
                border-radius: 50%;
                padding: 3px 5px;
              }
            }
            &:last-child{
              .title{
                border-bottom: transparent;
              }
            }
          }
          .panel {
            padding: 0px;
          }
        }
      }
    }
  }
}
