.DnD-outer{
      padding: 60px 0px;
    .DnD-left{
      padding-top: 80px;
      text-align: left;
      float: left;
      width: 50%;
      @media all and (max-width: 767px)
      {
        width: 100%;
      }
      p {
        color: $black;
        margin-top: 20px;
      }
    }
    .DnD-right{
      float: right;
      width: 50%;
      text-align: right;
      @media all and (max-width: 767px)
      {
        width: 100%;
        text-align: center;
      }
      img {
        @media all and (max-width: 479px)
        {
          width: 80%;
        }
      }
    }
}
