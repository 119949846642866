.news-feed__grid {
  display: grid;
  grid-template-columns: 5fr 7fr;

  @media only screen and (max-width: 991px) {
      display: block;
  }
}
.news-feed__grid-dropdown {
  p{
    @media only screen and (max-width: 450px) {
        padding: 6px 15px;
        display: block;
        float: none;
    }
  }
}

.news-feed__grid-left {
    background-color: #F7F7F7;
    display: grid;
    grid-template-columns: auto minmax(auto, 450px);
    padding: 80px 0 90px ;
 //   margin-left:15%;
  //  padding-left: 50px!important;
    .drink-donate__news-feed &{
      background-color: #fff;
      grid-template-columns: auto minmax(auto, 590px);
    }
    @media only screen and (max-width:3600px) and (min-width:3401px){
      grid-template-columns: auto minmax(auto, 305px)!important;
    }
    @media only screen and (max-width:3400px) and (min-width:3101px){
      grid-template-columns: auto minmax(auto, 335px)!important;
    }
    @media only screen and (max-width:3100px) and (min-width:2901px){
      grid-template-columns: auto minmax(auto, 355px)!important;
    }
    @media only screen and (max-width:2900px) and (min-width:2701px){
      grid-template-columns: auto minmax(auto, 355px)!important;
    }
    @media only screen and (max-width:2700px) and (min-width:2401px){
      grid-template-columns: auto minmax(auto, 385px)!important;
    }
    @media only screen and (max-width:2400px) and (min-width:2201px){
      grid-template-columns: auto minmax(auto, 405px)!important;
    }
    @media only screen and (max-width:2200px) and (min-width:2001px){
      grid-template-columns: auto minmax(auto, 425px)!important;
    }
    @media only screen and (max-width:2000px) and (min-width:1951px){
      grid-template-columns: auto minmax(auto, 435px)!important;
    }
    @media only screen and (max-width:1950px) and (min-width:1901px){
      grid-template-columns: auto minmax(auto, 440px)!important;
    }
    @media only screen and (max-width:1900px) and (min-width:1851px){
      grid-template-columns: auto minmax(auto, 445px)!important;
    }

    @media only screen and (max-width:1850px) and (min-width:1801px){
      grid-template-columns: auto minmax(auto, 450px)!important;
    }

    @media only screen and (max-width:1800px) and (min-width:1751px){
      grid-template-columns: auto minmax(auto, 450px)!important;
    }

    @media only screen and (max-width:1750px) and (min-width:1701px){
      grid-template-columns: auto minmax(auto, 450px)!important;
    }
    @media only screen and (max-width:1700px) and (min-width:1651px){
      grid-template-columns: auto minmax(auto, 465px)!important;
    }

    @media only screen and (max-width:1650px) and (min-width:1601px){
      grid-template-columns: auto minmax(auto, 465px)!important;
    }
    @media only screen and (max-width:1600px) and (min-width:1551px){
      grid-template-columns: auto minmax(auto, 465px)!important;
    }  
    @media only screen and (max-width:1550px) and (min-width:1501px){
      grid-template-columns: auto minmax(auto, 465px)!important;
    }    
@media only screen and (max-width:1500px) and (min-width:1400px){
      grid-template-columns: auto minmax(auto, 480px)!important;
    }
@media only screen and (max-width:1400px) and (min-width:1300px){
  grid-template-columns: auto minmax(auto, 480px)!important;
}
    @media only screen and (max-width: 991px) {
        display: block;
        padding: 70px 0 35px;
        padding-left: 50px;
        background-color: $white;
    }

    @media only screen and (max-width: 450px) {
      padding: 40px 0 15px;
      padding-left: 20px;
     }


    @media only screen and (min-width: 1300px) {
      grid-template-columns: auto minmax(auto, 500px);
    }
    .about-outer-container & {
      background: $white;
      @media all and (max-width: 767px)
      {
        padding-left: 20px;
        padding-top: 20px;
      }
    }
    .drink-donate__news-feed &{
      @media only screen and (max-width: 450px) {
        padding-top: 0;
       }
    }
}

.news-feed__grid-right {
    background-image: url('../images/news.png');
  min-width: 0;
  min-height: 0;
  background-size: 400px;
  background-repeat: no-repeat;
  background-position: 50% 77%;

 

  @media only screen and (max-width: 991px) {
      padding-bottom: 55px;
      background-position: 50% 92%
   }
   @media only screen and (max-width:400px){
    //  width:80%;
    //  height:auto;
    background-size: 300px;
   // padding:10px;
   }

  .slick-prev::before,.slick-next::before {
    content: " ";
  }

  .slick-prev {
    left: 30px;
}

  .slick-next {
    right: 65px;
}
  .slick-next, .slick-prev {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    -webkit-box-shadow:0px 5px 35px rgba(1, 1, 1, 0.09); /* Safari 3-4, iOS 4.0.2 - 4.2, Android 2.3+ */
    -moz-box-shadow:   0px 5px 35px rgba(1, 1, 1, 0.09);  /* Firefox 3.5 - 3.6 */
    box-shadow: 0px 5px 35px rgba(1, 1, 1, 0.09);
    padding: 20px;
    background-size: 22px;
    background-position: center;
    background-repeat: no-repeat;
    background-color: $white;
  }
  .slick-prev {
    background-image: url('../images/long-arrow-pointing-to-left.svg');

    @media only screen and (max-width: 991px) {
        display: none!important;
    }
  }
  .slick-next {
    background-image: url('../images/long-arrow-pointing-to-the-right.svg');

    @media only screen and (max-width: 991px) {
        display: none!important;
    }
  }
  .slick-slider {
    padding: 80px 0px 50px 80px;

    @media only screen and (max-width: 991px) {
        padding: 0px 0px 50px 50px;
    }

    @media only screen and (max-width: 450px) {
        padding: 0px 0px 50px 20px;
    }
    .about-outer-container & {
      @media all and (max-width: 767px)
      {
        padding-left: 20px;
      }
    }
  }

  .slick-list {
  	///border: 1px solid;
  	position: relative;
  	&:after{
		content: "";
		background: #FAFAFA;
		position: absolute;
		top: 0;
		height: 100%;
		width: 40%;
		z-index: 100;
  }
    &:after {
		right: -2px;
		background: linear-gradient(to right, rgba(255,255,255,0) 0%,rgba(255,255,255,1) 100%);
	}
  }


}


.news-single__wrapper{
  &:focus {
    outline: none;
  }
  .news-single__content {
    margin: 8px;
    background: white;
    margin-bottom: 13px;
    -webkit-box-shadow:0px 5px 19px rgba(0,0,0,0.05);
    -moz-box-shadow: 0px 5px 19px rgba(0,0,0,0.05);
    box-shadow: 0px 5px 19px rgba(0,0,0,0.05);

    .details {
      padding: 15px;
      min-height: 125px;
    }
  }
  img {
    width: 100%;
    height: 180px;
    object-fit: cover;
    object-position: center;
  }
}
