.select-box--box {
  min-width: 280px;
  display: inline-block;
  background: white;
  border-radius: 30px;
  border: solid 1px;
  position: relative;
  box-sizing: border-box;
  cursor: pointer;
  -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome and Opera */

  .top-nav__menu-list & {
    background: transparent;
    min-width: auto;
    border: none;
  }
  .payment & {
        border-radius: 3px;
        color: $input-grey-border;
        &.your-info-container
        {
          @media all and (max-width: 1060px)
          {
                min-width: auto;
          }
        }
        @media all and (max-width: 479px)
        {
          min-width: auto;
          width: 100%;
        }
  }
  .select-box--container{
    .State-zip-code & {
      display: flex;
    }
  }
  .label-selectbox-pair & {
        width: 200px;
        @media all and (max-width: 479px)
        {
            width: 100%;
              min-width: auto;
        }
  }
  .State-zip-code &{
      height: 40px;
      min-width: auto;
      margin-left: 4%;
      @media all and (max-width: 479px)
      {
          width: 100%;
          margin-left: 0;
      }

  }



  .select-box--options div {
    font-family: $font-family;
    color: #3d3d3d;
    font-weight: 300;
    font-size: 15px;
    padding: 10px 5px;
    padding-left: 30px;
  }

  .select-box--options--lang div {
    font-family: $font-family;
    color: #3d3d3d;
    font-weight: 300;
    font-size: 15px;
    padding: 10px 5px;
    padding-left: 30px;
  }



  .select-box--options--lang {
    position: absolute;
    background: white;
    top: 50px;
    -webkit-box-shadow: 0px 5px 20px 3px rgba(0, 0, 0, 0.05);
    -moz-box-shadow: 0px 5px 20px 3px rgba(0, 0, 0, 0.05);
    box-shadow: 0px 5px 20px 3px rgba(0, 0, 0, 0.05);
    left: 0;
    width: 100%;
    border-radius: 3px;
    padding: 10px;
    box-sizing: border-box;
    z-index: 10;

    .top-nav__menu-list & {
      left: unset;
      right: 0;
      min-width: 170px;
    }
    div {
      &.selected {
        background-size: 20px;
        background-position: 0px 8px;
        background-repeat: no-repeat;
        font-weight: 600;
      }
      &:first-of-type {
        &.selected {
          background-image: url('../images/uk.png');
        }
      }
      &:nth-child(2) {
        &.selected {
          background-image: url('../images/france.png');
        }
      }
      &:nth-child(3) {
        &.selected {
          background-image: url('../images/german.png');
        }
      }
    }


  }

  .select-box--options {
    position: absolute;
    background: white;
    top: 50px;
    -webkit-box-shadow: 0px 5px 20px 3px rgba(0, 0, 0, 0.05);
    -moz-box-shadow: 0px 5px 20px 3px rgba(0, 0, 0, 0.05);
    box-shadow: 0px 5px 20px 3px rgba(0, 0, 0, 0.05);
    left: 0;
    width: 100%;
    border-radius: 3px;
    padding: 10px;
    box-sizing: border-box;
    z-index: 10;

    .top-nav__menu-list & {
      left: unset;
      right: 0;
      min-width: 170px;
    }
    div {
      &.selected {
        background-size: 20px;
        background-position: 0px 8px;
        background-repeat: no-repeat;
        font-weight: 600;
      }
      // &:first-of-type {
      //   &.selected {
      //     background-image: url('../images/uk.png');
      //   }
      // }
      // &:nth-child(2) {
      //   &.selected {
      //     background-image: url('../images/france.png');
      //   }
      // }
      // &:nth-child(3) {
      //   &.selected {
      //     background-image: url('../images/german.png');
      //   }
      // }
    }


  }

  .select-box--selected-item {
    display: inline-block;
    float: left;
    padding: 12px 15px 12px 18px;
    font-family: $font-family;
    color: $theme-blue;
    font-weight: 600;
    font-size: 16px;

  .top-nav__menu-list & {
    color: $white;
  }
    .payment & {
      color: $place-holder-grey;
      font-weight: 100;
    }
  }
  .select-box--arrow {
    width: 30px;
    height: 30px;
    margin: 0;
    padding: 0;
    display: inline-block;
    background: transparent;
    position: relative;
    float: right;
  }

  .select-box--arrow-down {
    position: absolute;
    top: 18px;
    left: 0px;
    width: 0;
    height: 0;
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    border-top: 9px solid #aeaeae;

    .top-nav__menu-list & {
      border-top: 9px solid $white;
    }
  }
  .select-box--arrow-up {
    position: absolute;
    top: 16px;
    left: 0px;
    width: 0;
    height: 0;
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    border-bottom: 9px solid #aeaeae;

    .top-nav__menu-list & {
      border-bottom: 9px solid $white;
    }
  }



}
