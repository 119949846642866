.top-banner__wrap {
  background-image: url('../../assets/images/about-us-top-bg.jpg');
  padding: 170px 0px 140px 0px;
  text-align: center;
  color: white;
  background-size: cover;
  background-position: 0px 80%;
}

.content_block2 {
  margin-top: -100px !important;
  overflow: auto;
  .img-content {
    width: 270px;
    height: 320px;
    position: relative;
    text-align: center;
    float: right;
    @media all and (max-width: 767px)
    {
      float: none;
      margin: 0 auto;
    }
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
    .content-outer {
      position: absolute;
      bottom: 10px;
      width: 100%;
      p {
        font-size: 24px;
      }
    }
  }
  .text-content {
    float: left;
    width: 60%;
    padding-top: 150px;
    @media all and (max-width: 767px)
    {
      width: 100%;
      padding-top: 40px;
    }
    p {
      color: $black;
    }
  }
}

.made_a_diff-outer {
  margin-top: 60px;
  clear: both;
  @media all and (max-width: 767px)
  {
    margin-top: 50px;
  }
}

.profiles-outer {
  text-align: left;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  padding-left: 5%;
  margin-top: 50px;
  @media all and (max-width: 767px)
  {
    padding-left: 0;
    margin-top: 0;
  }
}
.profile {
      gap: 30px;
  img {
    width: 200px;
    object-fit: cover;
    object-position: center;
    height: 210px;

    @media all and (max-width: 1100px)
    {
      width: 100%;
    }
  }

  @media all and (max-width: 1100px)
  {
    margin: 0 20px;
  }

  @media all and (max-width: 900px)
  {
    margin: 0 10px;
  }
  .content-outer {
    .name {
      font-size: 16px;
      color: $black;
      font-weight: 100;
      position: relative;
      margin: 25px 0px;
      &::after{
        content: '';
        width: 160px;
        height: 3px;
        background: #08abe6;
        position: absolute;
        top: -13px;
        border-radius: 10px;
        left: 0;

      }
    }
    p {
      color: $black;
    }
  }
}
.seamless-exp-outer {

    background: #ffffff;
    background: -moz-linear-gradient(left, #ffffff 0%, #ffffff 49%, #08abe6 49%);
    background: -webkit-linear-gradient(left, #ffffff 0%,#ffffff 49%,#08abe6 49%);
    background: linear-gradient(to right, #ffffff 0%,#ffffff 49%,#08abe6 49%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#08abe6',GradientType=1 );
    @media all and (max-width: 767px)
    {
      background: #08abe6;
    }
  .content-outer {
    padding-top: 10%;
    padding-left: 10%;
    @media all and (max-width: 767px)
    {
      text-align: center;
      padding-left: 5%;
    padding-right: 5%;
    }
    p {
      color: $black;
    }
  }
}
