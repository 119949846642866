.login__box-right-content {
  #bold{
    font-weight: 600;
 }

  .sign-up & {
      max-width: 400px;
      margin-left: -200px;
      
      @media only screen and (max-width: $wide-breakpoint) {
          max-width: 100%;
          margin: 0;
          padding: 70px 20px;
      }
  }
}
.login__box-or-txt {
  .sign-up & {
    margin: 15px 0;
  }
}
.login__box-right {
  .sign-up & {
    background-image: url('../images/signup.jpg');
    background-position: 80% 20%;
    @media only screen and (max-width: $wide-breakpoint) {
        background-position: 50% 10%;
    }
  }
}

.sign-up__img-upload {
  text-align: center;
  margin-bottom: 35px;

  .icon {
    max-width: 110px;
    margin: 0 auto;
    margin-bottom: 10px;
    img{
      width: 100%;
      object-fit: cover;
      height: 110px;
      vertical-align: middle;
      object-position: center;
      border-radius: 80px;
    }

    .icon_null {
      cursor: pointer;
    }

    .img-preview {
      position: relative;
      .icon_null{
        position: absolute!important;
        top: 0;
        left: 0;
        opacity: 0;
      }
    }
  }
}

.login__box-forget-password {
  .sign-up__img-upload & {
    font-size: 17px;
  }
}

.login__box-left {
  .sign-up & {
    padding: 60px 0 30px;

    @media only screen and (max-width: $wide-breakpoint) {
        padding: 60px 10px 30px;
    }

  }
}

.icon_null {
  max-width: 110px;

  input {
      max-width: 110px;
  }
}
