.top-banner_contact__wrap {
  background-image: url('../../assets/images/contact-bg-img.png');
  height: 350px;
  padding-top: 15%;
  text-align: center;
  background-size: cover;
background-position-y: 65%;
  @media all and (max-width: 479px)
  {
    height: 200px;
  }

}
.recaptcha{
  @media all and (min-width:320px) and (max-width:400px){
   
    width:100px!important;
  }
}
.contact-options {
  background: #ffffffd1;
  width: 420px;
  margin: 0 auto;
  display: grid;
  grid-template-columns: 1fr 1fr;
  padding: 25px;
  @media all and (max-width: 479px)
  {
    width: 80%;
    padding: 7% 2%;
  }
  .option {
    h4 {
      color: $theme-blue;
      font-size: 14px;
      font-weight: 600;
    }
    label{
      font-size: 20px;
      font-weight: 100;
      @media all and (max-width: 479px)
      {
        font-size: 16px;
      }
      a {
        color: #4e4e4e;
      }
    }
  }
}
.drop-us-outer {
  box-shadow: 2px 3px 12px 0px rgba(1, 1, 1, 0.09);
  -webkit-box-shadow: 2px 3px 12px 0px rgba(1, 1, 1, 0.2);
  -moz-box-shadow: 2px 3px 12px 0px rgba(1, 1, 1, 0.09);
  padding: 30px;
  padding-top: 40px;
  margin-top: 70px;
  .column-3{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-column-gap: 5%;
    margin-bottom: 20px;
    @media all and (max-width: 479px)
    {
      display: block;
      margin-bottom: 0px;
    }
  }
}
.location-outer {
  position: relative;
  .address-wrap-outer {
    color: $black;
    width: 272px;
    position: absolute;
    background: $white;
    top: 120px;
    left: 12px;
    padding-left: 40px;
    padding-right: 39px;
    padding-top: 15px;
    padding-bottom: 35px;
    @media all and (max-width: 479px)
    {
      width: 66%;
    }
    .address-wrap {
      h2{
        color: black;
        font-size: 22px;
        font-weight: 500;
      }
      p {
        color: $black;
        font-style: italic;
      }
    }
  }
}

.drop-us__message {
    p {
      background: #2ECC71;
      text-align: center;
      padding: 15px;
      border-radius: 4px;
      font-weight: 500;
      text-transform: capitalize;
    }
}

.map-outer {
  margin-top: 20px;
  iframe {
    width: 100%;
    height: 500px;
  }
}
.contact-outer{

  form {
    margin-top: 50px;
  }
  @media only screen and (max-width: 991px) {
    padding: 0 0 20px 0;
  }
}
.form-textbox-error-outer {
  position: relative;
  @media only screen and (max-width: 768px)
  {
    margin-bottom: 60px;
  }
  .errorMsg {
    color: red;
    bottom: 0px;
    top: auto;
    @media only screen and (max-width: 768px)
    {
      bottom: -25px;
    }
  }
}
