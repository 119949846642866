@import './var';

$duration: .2s;
$distance: 8px;
$easeOutBack: cubic-bezier(0.175, 0.885, 0.320, 1.275);
$top-nav-login-btn-border:                       solid 2px $white !default;


div.top-nav__menu > ul > li:last-child{
  padding: 0;
  height: 210px;
  .colorHeader & {
    height: auto;
  }
}
.colorHeader {
  background: $theme-blue;
}

.borderlogin1{
 border:2px solid white!important;
 padding:5px 20px!important;
 min-width:120px;
 text-align: center;
 
 border-radius:25px;
 &:hover{
   
   background: white;
  
 }

@media screen and (max-width:825px){
  padding:auto 20px!important;
  border:none!important;
}
}

.top-nav__login-btn{
  border:2px solid white!important;
  padding:5px 30px!important;
  min-width:120px;border-radius:25px;

  &:hover,&:focus {
    &::after {
      content: none;
    }
  }
  .active {
    background:white;
    &::after {
      content: none;

    }
  }

  &:hover{
    background: white;
    color:$theme-blue!important;
  }

}

@media only screen and (max-width:  825px) {
  .top-nav__menu-list .select-box--box .select-box--options {
    left: -30px!important;
    right: unset!important;
    text-align: left;
  }
}



.top-nav {
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  padding: 10px 0;
  box-sizing: border-box;
}

.top-nav__logo {
  display: inline-block;
  float: left;
  z-index: 1001;
  position: relative;
}

.top-nav__logo-text {
  color: $black;
  font-size: 32px;
  margin: 23px 0;

  span {
    color: $white;
  }
}



.top-nav__menu {
  display: inline-block;
  float: right;
  position: relative;
  z-index: 30;
  .colorHeader & {
    @media all and (min-width: 768px)
    {
      position: absolute;
      right: 0;
    }
  }
}


.top-nav__menu-list {
  margin: ($spacing-unit * 2) 0;
  margin-right: 0;
    @media only screen and (max-width:  825px) {
      margin: 0;
    }

  li {
    display: inline-block;
    float: left;
    padding: 8px 0;
    margin-bottom: 0;
    margin: 0 20px;
    font-family: $font-family;
    font-weight: 300;
    color: $white;
    letter-spacing: 0;
    box-sizing: border-box;

    &.borderlogin {
      .top-nav__login-btn {
        &::before, &::after, &.active::after, &.active:hover::after {
          content: none !important;
        }
        &.active{
          background: white;
          color:$theme-blue;
        }
      }
    }

 
    .active {
      color: $white;
      
      &:after {
        position:absolute;
        width: 100%;
        height: 2px;
        background: $white;
        content: "";
        transform: none;
        opacity: 1;
        bottom: 2px;
        
      }

      &:hover,
      &:focus {
       &:before,
       &:after {
         position:absolute;
         width: 100%;
         height: 2px;
         background: $white;
         content: "";
         transform: none;
         opacity: 1;
         bottom: 2px;
       }
       &:before {
         display: none;
       }
       &:after {
         position:absolute;
         width: 100%;
         height: 2px;
         background: white;
         content: "";
         transform: none;
         opacity: 1;
         bottom: 2px;
       }
       &:nth-child(5)::after{
        height:0px;

       }
      }
    }

    @media only screen and (max-width: $wide-breakpoint) {
      padding: 8px 0;
      margin: 0 10px;
    }
    a{
      font-family: $font-family;
      font-weight: 300;
      color: $white;
      font-size: 17px;
      position: relative;
      letter-spacing: 0;
      padding: 0px 4px 8px;
      box-sizing: border-box;

      // &:hover {
      //   text-shadow: 0 0 .1px $white, 0 0 .1px $white;
      // }

      &:before,
&:after {
 content: '';
 position: absolute;
 bottom: 2px;
 left: 0; right: 0;
 height: 2px;
 background-color: $white;
}
&:before {
 opacity: 0;
 transform: translateY(- $distance);
 transition: transform 0s $easeOutBack, opacity 0s;
}
&:after {
 opacity: 0;
 transform: translateY($distance/2);
 transition: transform $duration $easeOutBack, opacity $duration;
}

&:hover,
&:focus  {
 &:before,
 &:after  {
   opacity: 1;
   transform: translateY(0);
 }
 &:before  {
   transition: transform $duration $easeOutBack, opacity $duration;
 }
 &:after  {
   transition: transform 0s $duration $easeOutBack, opacity 0s $duration;
 }
}
    }
    &.top-nav__login-btn {
      border: $top-nav-login-btn-border!important;
      border-radius: 20px;
      padding: ($spacing-unit - 2px) ($spacing-unit * 4) !important;
      transition: 0.3s;
      margin-right: 0px;

      @media only screen and (max-width:  825px) {
        text-align: center;
        display: block;
        
        margin: 20px auto!important;
        font-weight: 600!important;
      }

      a{
        font-size: 15px!important;
        transition: 0.3s;
      }

      &:hover{
        background: $white;
        transition: 0.3s;
        color: $theme-blue;
        transition: 0.3s;
      }

    }
  }
}



@media screen and (max-width:  825px) {




  .top-nav__login-btn {
    font-size: 20px;
   // max-width: 260px;
    border-radius: 60px;
    display: flex;
    justify-content: center;
    max-width:200px;
    margin: 0 auto!important;
    border: $top-nav-login-btn-border;
    // padding: 12px 20px!important;
    p{
     text-align: center;
    // border-radius: 20px;
     // padding:10px 50px;
      // display: block;

    }
    &:hover{
      border:none;

    }
  }




  .nav-open {
    position: absolute;
    right: 20px;
    top: 25px;
    display: block;
    width: 48px;
    height: 48px;
    cursor: pointer;
    z-index: 9999;
    border-radius: 50%;
  }






  .nav-open i {
    display: block;
    width: 32px;
    height: 3px;
    background: $white;
    border-radius: 2px;
    margin-left: 14px;
  }



  .nav-open i:nth-child(1) {
    margin-top: 16px;
  }




  .nav-open i:nth-child(2) {
    margin-top: 4px;
    opacity: 1;
  }



  .nav-open i:nth-child(3) {
    margin-top: 4px;
  }




  .top-nav__menu ul li:nth-child(1) a {
    transition-delay: 0.2s;
  }



  .top-nav__menu ul li:nth-child(2) a {
    transition-delay: 0.3s;
  }



  .top-nav__menu ul li:nth-child(3) a {
    transition-delay: 0.4s;
  }




  .top-nav__menu ul li:nth-child(4) a {
    transition-delay: 0.5s;
  }



  .top-nav__menu ul a:nth-child(5){
    transition-delay: 0.6s;
  }



/*syntax cleared till here*/



.top-nav__menu {
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: -1;
    background: $theme-blue;
    opacity: 0;
    transition: all 0.2s ease;

    ul {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      width: 100%;
      overflow: hidden;

      li{
        display: block;
       // float: none;
        width: 100%;
      
        text-align: center;
        margin-bottom: 10px;

        a {
          padding: 10px 15px;
          opacity: 0;
          color: #fff;
          font-size: 24px;
          font-weight: 600;
          transform: translateY(-20px);
          transition: all 0.2s ease;
        }
      }
    }
  }

 }





/* syntax okay in down */

 #nav:checked + .nav-open {
   transform: rotate(45deg);
   position: fixed;
  z-index: 100000;
 }
 #nav:checked + .nav-open i {
   background: #fff;
   transition: transform 0.2s ease;
 }
 #nav:checked + .nav-open i:nth-child(1) {
   transform: translateY(6px) rotate(180deg);
 }
 #nav:checked + .nav-open i:nth-child(2) {
   opacity: 0;
 }
 #nav:checked + .nav-open i:nth-child(3) {
   transform: translateY(-8px) rotate(90deg);
 }
 #nav:checked ~ .top-nav__menu {
   z-index: 10000 ;
   opacity: 1;
 }
 #nav:checked ~ .top-nav__menu ul li a {
   opacity: 1;
   transform: translateY(0);
 }
 .hidden {
   display: none;
 }
