.label-input-pair-row {
      display: grid;
      margin-top: 25px;
      @media all and (max-width: 479px)
      {
        display: block;
        margin-top: 5px;
      }
      .label
      {
        line-height: 35px;
      }
      &.State-zip-code  {
        .label
        {
            width: 16%;
            @media all and (max-width: 479px)
            {
              width: 100%;
            }
        }
      }
      &.column-3{
        grid-template-columns: 1fr 2fr 2fr;
      }
      &.column-2{
        grid-template-columns: 1fr 4fr;
      }
      &.StripeElement-card-outer
      {
        grid-template-columns: 1.3fr 5fr;
      }
      &.StripeExpiryDate
      {
        grid-template-columns: 0.2fr 0.3fr;
      }
      &.StripeCvv {
        grid-template-columns: 0.82fr 1.3fr 2fr;
      }
      &.State-zip-code {
          display: flex;
          @media all and (max-width: 479px)
          {
            display: block;
          }
      }
}
.label-input-pair {
  display: grid;
  grid-template-columns: 0.2fr 0.3fr;
  margin-top: 25px;
  @media all and (max-width: 479px)
  {
    display: block;
    margin-top: 5px;
  }
  .StripeElement
  {
    width: 200px;
    iframe
    {
      height: 30px !important;
      input[type="tel"]
      {
        height: 30px !important;
        padding: 10px;
      }
    }
    .StripeCvv & {
      @media all and (max-width: 479px)
      {
        float: left;
        width: 83%;
      }
    }
  }
}
.label-selectbox-pair {
  display: grid;
  grid-template-columns: 1fr 4fr;
  margin-top: 25px;
  @media all and (max-width: 479px)
  {
    display: block;
    margin-top: 5px;
  }
  &.birthday-outer{
    display: block;
    overflow: auto;
    @media all and (max-width: 479px)
    {
      float: left;
      width: 100%;
      margin-top: 5px;
    }
    .label
    {
      float: left;
      width: 20%;
    }
  }
  .label
  {
    line-height: 45px;
    color:$black;
    font-size: 14px;
    @media all and (max-width: 479px)
    {
      float: left;
      width: 100% !important;
    }
  }
  &.column-4
  {
    grid-template-columns: 0.2fr 0.2fr 0.2fr 0.2fr;
  }
}
