.cde-component-outer
{
  background: $footer-bg-grey;
  .component-grid {
    @media all and (max-width: 479px)
    {
        margin-bottom: 30px;
    }
    h2,p{
      color: $black;
    }
      h2 {
        font-size: 17px;
        margin-top: 30px;
        @media all and (max-width: 479px)
        {
          font-size: 17px;
        }
      }
      p {
        max-width: 250px;
        margin: 20px auto 0;
        font-size: 16px;
        @media all and (max-width: 479px)
        {
          font-size: 14px;
        }
      }
    .img-outer {
      img {
        @media all and (max-width: 767px)
        {
          width: 40px;
        }
      }
    }
  }
}
