.subscribe__form-wrapper {
    display: grid;
    grid-template-columns: 7fr 2fr;
    grid-gap: 10px;

    input,button {
      &:focus {
        outline: none;
      }
    }

    button {
      background: #c4c4c4;
      color: white;
      margin: 0;
      height: 100%;
      font-family: $font-family;
      font-weight: 500;
      font-size: 15px;
      border-radius: 5px;
      min-width: 100px;
      cursor: pointer;
    }
}


.footer-outer
{
  background-color: $footer-bg-grey;
  .footer-content-outer
  {
    display: grid;
    grid-template-columns: 5fr 1fr;
    font-family: "Roboto", sans-serif;
    padding-top: 30px;
    padding-bottom: 30px;
    @media all and (max-width: 767px)
    {
      padding-top: 30px;
    }
    .footer-links-outer
    {
      padding-bottom: 20px;
      background-image: url('../images/footerlogo.png');
      background-repeat: no-repeat;
      background-position: 87% 1%;
      @media all and (max-width: 991px)
      {
        background-size: 60%;
      }
      @media all and (max-width: 800px)
      {
        background-size: 50%;
      }
      @media all and (max-width: 767px)
      {
        background-position: 97% 10%;
      }
      @media all and (max-width: 479px)
      {
        font-size: 12px;
      }
      a {
        color: $theme-blue;
      }

    }
    .subscribe-outer
    {
      h2
      {
        font-size: 16px;
        letter-spacing: 3px;
        color: $theme-blue;
      }
      p
      {
        color: #989898;
        font-size: 14px;
        margin-bottom: 15px;
      }
      input
      {
        border: none;
        padding: 10px 20px;
        background: #c4c4c4;
        border-radius: 5px;
        width: 300px;
        height: 30px;
        font-size: 16px;
        font-weight: 500;
        background-image: url('../images/envelope.png');
        background-repeat: no-repeat;
        background-position: 95%;
        background-size: 22px;
        &::placeholder
        {
          color:white;
        }
        @media all and (max-width: 767px)
        {
          max-width: 100%;
          width: 100%;
          box-sizing: border-box;
          height: 50px;
        }
      }
    }
    .footer-bottom-links
    {
      display: grid;
      grid-template-columns: .9fr 1.2fr 5fr;
      margin-top: 10px;
      grid-gap: 1%;
      a{
        font-size: 14px;
        color: #989898;

        @media all and (max-width: 479px)
        {
          margin-right: 15px;
        }
      }
      @media all and (max-width: 767px)
      {
        display: block;
        float: left;
      }

      @media all and (max-width: 479px)
      {
        display: inline-block;
      }
      div {
        @media all and (max-width: 479px)
        {
          display: inline-block;
        }
        &:first-child {
          @media all and (max-width: 479px)
          {
            margin: 15px 10px 15px 0px;
          }
        }
      }
    }
    .all-rights-reserved
    {
      color: #989898;
      text-align: right;
      font-size: 12px;
    }
    .social-icons,.payment-icons
    {
      display: grid;
      padding-top: 20px;
      grid-column-gap: $spacing-unit;
    }
    .social-icons
    {
      width: 100px;
      @media all and (max-width: 767px)
      {
        clear: both;
        float: left;
      }
      @media all and (max-width: 479px)
      {
        display: inline-block;
        width: auto;
      }
      .social-share {
        @media all and (max-width: 479px)
        {
          grid-gap: 12px;
        }
      }
      div {
        @media all and (max-width: 479px)
        {
          float: left;
        }
      }
    }
    .payment-icons
    {
      grid-template-columns:1fr;
      text-align: right;
      @media all and (max-width: 767px)
      {
        grid-template-columns: 1fr;
        float: right;
      }
      @media all and (max-width: 479px)
      {
        right: 10px;
        position: absolute;
        bottom: 42px;
      }
      img {
        max-width: 60px;
        margin-top: 8px;
      }
    }
  }
}
