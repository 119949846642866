.thank-you {
    margin: 135px 0 70px;
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome and Opera */

      @media only screen and (max-width: $wide-breakpoint) {
        margin: 108px 0 80px;
      }

      @media only screen and (max-width: $medium-breakpoint) {
        margin: 112px 0 80px;
      }

      .main-container {
        @media only screen and (max-width: $medium-breakpoint) {
          padding: 0;
        }
      }
}

.thank-you__content {
  background-image: url('../images/thankyou.jpg');
  position: relative;
  background-size: cover;
  background-position: center;
  padding: 180px 90px 90px;
  margin: 20px 0;
  box-shadow: 0px 3px 16px rgba(0,0,0,0.43);

  @media only screen and (max-width: $medium-breakpoint) {
    margin: 0;
    box-shadow: none;
    text-align: center;
  }

  @media only screen and (max-width: $extra-small-breakpoint) {
    padding: 180px 20px 90px;
  }
}

.thank-you__share {
  margin-top: 70px;
  text-align: center;

  @media only screen and (max-width: $wide-breakpoint) {
    padding: 0 60px;
  }

  @media only screen and (max-width: $small-breakpoint) {
    margin: 80px 0 20px;
  }

  @media only screen and (max-width: $extra-small-breakpoint) {
    padding: 0 20px;
  }
}



.thank-you__share-icons {
      margin: 35px auto 0;
      max-width: 400px;

      .SocialMediaShareButton > div {
        width: 55px!important;
        height: 55px!important;
        margin: 0 auto;
      }

      .social-share {
        svg {
          width: 55px;
          height: 55px;
        }
      }

    a{
      display: inline-block;
      margin: 0 25px;

      @media only screen and (max-width: 767px) {
        margin: 0 20px;
      }

      @media only screen and (max-width: $extra-small-breakpoint) {
        margin: 0 10px;
      }

      img {
        max-width: 60px;

        @media only screen and (max-width: $extra-small-breakpoint) {
          max-width: 35px;
        }

        &:hover {
          -webkit-filter: grayscale(100%);
          filter: grayscale(100%);
          transition: all .2s ease-in-out;
        }
      }
    }
}
