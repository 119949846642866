@import './var';

$join-community-right-bg:              #F7F7F7 !default;


.join-community {
display: grid;
grid-template-columns: 5fr 1fr 8.4fr;
grid-template-rows: auto 1fr auto;

  @media only screen and (max-width: 991px) {
    display: block;
  }
}

.join-community__left-content {
  h3 {
    font-weight: 100;
    @media only screen and (max-width: 991px) {
      text-align: center!important;
      max-width: 390px;
      margin: 0 auto;
    }

    &:after {
      @media only screen and (max-width: 991px) {
        width: 60%;
        margin-left: 30%;
      }
    }
  }
}
.join-community__left {
  display: grid;
  grid-template-columns: auto minmax(auto, 450px);
  background-color: #08abe6;
  grid-column: 1 / span 2;
  grid-row: 1 / 2;
  padding: 150px 20px;
  padding-right: 0;

  @media only screen and (max-width: 991px) {
    display: block;
    text-align: center;
    padding: 60px 20px 115px;
  }

  @media only screen and (min-width: 1300px) {
    grid-template-columns: auto minmax(auto, 500px);
  }
}

.join-community__right {
  display: grid;

  @media only screen and (max-width: 991px) {
    display: block;

   }

  grid-template-columns: minmax(auto,820px) auto;
  background-color: #F7F7F7;
  grid-column: 3 / 4;
  grid-row: 1 / 3;
 .payment__Right & {
   grid-column: auto;
   grid-row: auto;
   background: white;
   height: auto;
   margin-top: 160px;
   padding-bottom: 40px;


@media only screen and (max-width: 991px) {
  display: block;

 }
}
}
.join-community__right-content{
  text-align: center;
  padding: 0 100px;

  .join-community & {
    @media only screen and (max-width: $small-breakpoint) {
      padding: 0 10px;
    }
  }
  .payment__Right & {
    padding: 0 30px;


  @media only screen and (max-width: 620px) {
      padding: 0 40px;
  }

  @media only screen and (max-width: 449px) {
      padding: 0 20px;

  }
}
}
.counter-img {
  background-image: url('../images/Home.png');
grid-column: 1 / 3;
grid-row: 2 / 4;
z-index: 1;
background-size: cover;
background-position: center;

@media only screen and (max-width: 991px) {
  width: 100%;
  height: 500px;
}
}
.counter-data {
  grid-column: 2 / 4;
  grid-row: 3 / 4;
  background: $theme-blue;
  min-height: 32px;
  z-index: 2;
  display: grid;
  grid-template-columns: minmax(auto,900px) auto;
  padding: 55px 55px 65px;

@media only screen and (max-width: 991px) {
  display: block;
  max-width: 80%;
  margin: 0 auto;
  margin-top: -200px;
}

@media only screen and (max-width: 620px) {
  max-width: unset;
  margin: 0 20px;
  margin-top: -200px;
 }

}
.mockIphone {
  img{
    width:320px;
    margin-top: -100px;

    @media only screen and (max-width: $small-breakpoint) {
        width:auto;
    }

    .payment__Right & {
      width: 100%;
      max-width: 200px;


    @media only screen and (max-width: $small-breakpoint) {
        width:auto;
    }
  }
}
}

.store-icon {
  margin: 20px 20px 50px;
  display:inline-block;

  .join-community &, .drink-donate__news-feed & {
    @media only screen and (max-width: $extra-small-breakpoint) {
      margin: 20px 10px 30px;
      max-width: 120px;
    }
  }


  .payment__Right &{
    width: 100%;
    max-width: 130px;
    margin: 0 auto;
    margin-top: 20px;
  }
}
.store-icons-container{
  display: grid;
  grid-template-columns: 1fr 1fr;

  .payment__Right & {
    @media only screen and (max-width: $extra-small-breakpoint) {
        margin: 20px 0px 0;
    }
  }

}

.counter-data__container {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;

  @media only screen and (max-width: 991px) {
    grid-template-columns: 1fr 1fr;
    grid-row-gap: 40px;
   }

   @media only screen and (max-width: 449px) {
     grid-template-columns: 1fr;
     grid-row-gap: 40px;
    }
}
